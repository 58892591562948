import type { UpdateConditionRequest } from "apis/proto/canary_cloud/customer/v1/api_pb";
import type {
  ConditionCustomerBukkenOptionCategory,
  ConditionLayout,
} from "apis/proto/canary_cloud/customer/v1/condition_pb";
import type {
  Chomei,
  City,
  Prefecture,
  Station,
} from "apis/proto/canary_cloud/customer/v1/mapping_pb";
import { ReactComponent as Location } from "assets/icons/Location.svg";
import { ReactComponent as Train } from "assets/icons/Train.svg";
import classNames from "classnames";
import { Button } from "components/button/Button";
import { Checkbox } from "components/form/Checkbox";
import { Chip } from "components/form/Chip";
import { FormField } from "components/form/FormField";
import { Input } from "components/form/Input";
import { Select } from "components/form/Select";
import { Textarea } from "components/form/Textarea";
import { useOpenModalKey } from "customHooks/useOpenModalKey";
import { WatchingFormValues } from "libs/react-hook-form/components/WatchigFormValues";
import { useFormContext } from "react-hook-form";
import type { SearchCitiesResponse } from "usecase/city/city";
import type { SearchStationsResponse } from "usecase/station/station";

import type { ConditionFormSchema } from "../CustomerConditionForm";
import { CheckButton } from "./CheckButton";
import styles from "./ConditionForm.module.scss";
import {
  RENT_MAX_CHOICES,
  RENT_MIN_CHOICES,
  SQUARE_MAX_UPPER_VALUE,
  SQUARE_MIN_LOWER_VALUE,
  SQUARE_RANGE_CHOICES,
} from "./constant";
import type { CitiesAndCityIdsFormSchema } from "./SelectCityModal";
import { SelectCityModal } from "./SelectCityModal";
import type { SquareRangeValues } from "./SelectSquareModal";
import { SelectSquareModal } from "./SelectSquareModal";
import type { StationIdAndLineIdsFormSchema } from "./SelectStationModal";
import { SelectStationModal } from "./SelectStationModal";
import {
  cityToDisplayText,
  squareToDisplayText,
  stationToDisplayText,
} from "./toDisplayText";

type ConditionFormProps = {
  allChomeis: Chomei.AsObject[];
  allCities: City.AsObject[];
  allStations: Station.AsObject[];
  bukkenShubetsuOptions?: ConditionCustomerBukkenOptionCategory.AsObject;
  citiesByPrefecture: SearchCitiesResponse[];
  layoutOptions: ConditionLayout.AsObject[];
  onClickDisplayedCheck: (values: ConditionFormSchema) => void;
  otherOptions: ConditionCustomerBukkenOptionCategory.AsObject[];
  prefecturesList: Prefecture.AsObject[];
  stationsByPrefecture: SearchStationsResponse[];
};

export const ConditionForm = (props: ConditionFormProps) => {
  const {
    onClickDisplayedCheck,
    bukkenShubetsuOptions,
    otherOptions,
    layoutOptions,
    prefecturesList,
    stationsByPrefecture,
    allStations,
    allChomeis: _,
    allCities,
    citiesByPrefecture,
  } = props;

  const { isOpenModal, onOpenModal, onCloseModal } = useOpenModalKey([
    "selectStation",
    "selectCity",
    "selectSquare",
  ]);

  const {
    register,
    control,
    setValue,
    getValues,
    trigger,
    formState: { isValid, isSubmitting, errors },
    handleSubmit,
  } = useFormContext<ConditionFormSchema>();

  const onSubmitSelectCityModal = async (
    values?: CitiesAndCityIdsFormSchema
  ) => {
    if (values) {
      const citiesList = values.cityIdsList.reduce<
        UpdateConditionRequest.City.AsObject[]
      >((acc, cityId) => {
        const city = allCities.find((city) => city.cityId === cityId);
        if (city) {
          return [
            ...acc,
            {
              id: city.cityId,
              chomeiIdsList: city.chomeisList.reduce<string[]>(
                (acc, chomei) =>
                  values.chomeiIdsList.includes(chomei.chomeiId)
                    ? [...acc, chomei.chomeiId]
                    : acc,
                []
              ),
            },
          ];
        }
        return acc;
      }, []);
      setValue("citiesList", citiesList);
      setValue("chomeiIdsList", values.chomeiIdsList);
      const cityIdsListByChomei = citiesList.map((city) => city.id);
      setValue(
        "cityIdsList",
        Array.from(new Set([...values.cityIdsList, ...cityIdsListByChomei]))
      );
      await trigger([
        "cityIdsList",
        "citiesList",
        "chomeiIdsList",
        "stationIdAndLineIdsList",
      ]);
    }
    onCloseModal();
  };

  const onSubmitSelectStationModal = async (
    values?: StationIdAndLineIdsFormSchema
  ) => {
    if (values) {
      setValue("stationIdAndLineIdsList", values.stationIdAndLineIdsList);
      await trigger([
        "cityIdsList",
        "citiesList",
        "chomeiIdsList",
        "stationIdAndLineIdsList",
      ]);
    }
    onCloseModal();
  };

  const onSubmitSelectSquareModal = async (values?: SquareRangeValues) => {
    if (values) {
      setValue("squareMin", values.squareMin);
      setValue("squareMax", values.squareMax);
      await trigger(["squareMin", "squareMax"]);
    }
    onCloseModal();
  };

  const isCheckedSquareRangeButton = (
    type: "single" | "couple" | "family",
    currentSquareMax = SQUARE_MAX_UPPER_VALUE,
    currentSquareMin = SQUARE_MIN_LOWER_VALUE
  ) => {
    if (type === "single") {
      return (
        currentSquareMax === SQUARE_RANGE_CHOICES["single"].squareMax &&
        currentSquareMin === SQUARE_RANGE_CHOICES["single"].squareMin
      );
    }
    if (type === "couple") {
      return (
        currentSquareMax === SQUARE_RANGE_CHOICES["couple"].squareMax &&
        currentSquareMin === SQUARE_RANGE_CHOICES["couple"].squareMin
      );
    }
    return (
      currentSquareMax === SQUARE_RANGE_CHOICES["family"].squareMax &&
      currentSquareMin === SQUARE_RANGE_CHOICES["family"].squareMin
    );
  };

  const onClickSquareRangeButton = async (
    type: "single" | "couple" | "family"
  ) => {
    setValue("squareMin", SQUARE_RANGE_CHOICES[type].squareMin);
    setValue("squareMax", SQUARE_RANGE_CHOICES[type].squareMax);
    await trigger(["squareMin", "squareMax"]);
  };

  return (
    <>
      <SelectStationModal
        isOpen={isOpenModal("selectStation")}
        prefecturesList={prefecturesList}
        stationsByPrefecture={stationsByPrefecture}
        defaultValues={{
          stationIdAndLineIdsList: getValues("stationIdAndLineIdsList"),
        }}
        onClose={onCloseModal}
        onSubmit={onSubmitSelectStationModal}
      />
      <SelectCityModal
        citiesByPrefecture={citiesByPrefecture}
        isOpen={isOpenModal("selectCity")}
        prefecturesList={prefecturesList}
        defaultValues={{
          cityIdsList: getValues("cityIdsList"),
          chomeiIdsList: getValues("chomeiIdsList"),
        }}
        onClose={onCloseModal}
        onSubmit={onSubmitSelectCityModal}
      />
      <SelectSquareModal
        isOpen={isOpenModal("selectSquare")}
        defaultValues={{
          squareMax: getValues("squareMax"),
          squareMin: getValues("squareMin"),
        }}
        onClose={onCloseModal}
        onSubmit={onSubmitSelectSquareModal}
      />
      <form onSubmit={handleSubmit(onClickDisplayedCheck)}>
        <div className={styles.formContainer}>
          <FormField isRequired id="moveInTiming" label="入居時期">
            <Input
              id="moveInTiming"
              {...register("moveInTiming")}
              placeholder="例：3ヵ月以内、3月まで"
            />
            <FormField.Error>
              {errors.moveInTiming?.message || ""}
            </FormField.Error>
          </FormField>

          <FormField isRequired label="賃料">
            <WatchingFormValues control={control}>
              {({ rentMax, rentMin }) => (
                <div className={classNames(styles.flexRow)}>
                  <Select
                    id="rentMin"
                    style={{ flex: 1 }}
                    choices={RENT_MIN_CHOICES.map((choice) => ({
                      value: choice.value,
                      label: choice.label,
                      disabled: rentMax ? choice.value > rentMax : false,
                    }))}
                    {...register("rentMin", {
                      valueAsNumber: true,
                    })}
                  />
                  <span>~</span>
                  <Select
                    id="rentMax"
                    style={{ flex: 1 }}
                    choices={RENT_MAX_CHOICES.map((choice) => ({
                      value: choice.value,
                      label: choice.label,
                      disabled: rentMin ? choice.value < rentMin : false,
                    }))}
                    {...register("rentMax", {
                      valueAsNumber: true,
                      onChange: () => trigger("rentMin"),
                    })}
                  />
                </div>
              )}
            </WatchingFormValues>

            <FormField.Error>{errors.rentMin?.message || ""}</FormField.Error>
          </FormField>

          <FormField isRequired label="エリア">
            <WatchingFormValues control={control}>
              {({ stationIdAndLineIdsList, cityIdsList, chomeiIdsList }) => {
                const isSelectedStation =
                  (stationIdAndLineIdsList || [])?.length > 0;
                const isSelectedCity =
                  (cityIdsList || [])?.length > 0 ||
                  (chomeiIdsList || [])?.length > 0;
                return (
                  <>
                    <div className={classNames(styles.flexRow)}>
                      <CheckButton
                        icon={<Train />}
                        isChecked={isSelectedStation}
                        onClick={() => onOpenModal("selectStation")}
                      >
                        路線・駅から
                      </CheckButton>
                      <CheckButton
                        icon={<Location />}
                        isChecked={isSelectedCity}
                        onClick={() => onOpenModal("selectCity")}
                      >
                        市区町村から
                      </CheckButton>
                    </div>

                    {isSelectedStation && (
                      <FormField.Info>
                        {stationToDisplayText(
                          allStations,
                          stationIdAndLineIdsList
                        )}
                      </FormField.Info>
                    )}
                    {isSelectedCity && (
                      <FormField.Info>
                        {cityToDisplayText(
                          allCities,
                          cityIdsList,
                          chomeiIdsList
                        )}
                      </FormField.Info>
                    )}

                    <FormField.Error>
                      {errors.stationIdAndLineIdsList?.message || ""}
                    </FormField.Error>
                    <FormField.Error>
                      {errors.cityIdsList?.message || ""}
                    </FormField.Error>
                    <FormField.Error>
                      {errors.chomeiIdsList?.message || ""}
                    </FormField.Error>
                  </>
                );
              }}
            </WatchingFormValues>
          </FormField>

          <FormField isRequired label="お部屋の面積">
            <div className={classNames(styles.squareContainer)}>
              <WatchingFormValues control={control}>
                {({ squareMin, squareMax }) => (
                  <div className={styles.squareButtonContainer}>
                    <CheckButton
                      isChecked={isCheckedSquareRangeButton(
                        "single",
                        squareMax,
                        squareMin
                      )}
                      onClick={() => onClickSquareRangeButton("single")}
                    >
                      一人暮らし
                    </CheckButton>
                    <CheckButton
                      isChecked={isCheckedSquareRangeButton(
                        "couple",
                        squareMax,
                        squareMin
                      )}
                      onClick={() => onClickSquareRangeButton("couple")}
                    >
                      二人暮らし
                    </CheckButton>
                    <CheckButton
                      isChecked={isCheckedSquareRangeButton(
                        "family",
                        squareMax,
                        squareMin
                      )}
                      onClick={() => onClickSquareRangeButton("family")}
                    >
                      ファミリー
                    </CheckButton>
                  </div>
                )}
              </WatchingFormValues>

              <Button
                className={styles.selectSquareButton}
                color="primary"
                type="button"
                variant="subtle"
                onClick={() => onOpenModal("selectSquare")}
              >
                手動で入力
              </Button>
            </div>

            <WatchingFormValues control={control}>
              {({ squareMin, squareMax }) => (
                <FormField.Info>
                  {squareToDisplayText(squareMin, squareMax)}
                </FormField.Info>
              )}
            </WatchingFormValues>

            <FormField.Error>{errors.squareMin?.message || ""}</FormField.Error>
          </FormField>

          <FormField label="間取り">
            <div className={styles.layoutContainer}>
              {layoutOptions.map((layout) => (
                <Checkbox
                  key={layout.id}
                  id={layout.id}
                  label={layout.name}
                  value={layout.id}
                  {...register("layoutIdsList")}
                />
              ))}
            </div>
          </FormField>

          <FormField id="walkDuringMax" label="駅徒歩">
            <div className={classNames(styles.walkDuringMaxContainer)}>
              <Input
                className={styles.walkDuringMaxInput}
                id="walkDuringMax"
                placeholder="例：5"
                type="tel"
                {...register("walkDuringMax")}
              />
              <span className={styles.unitLabel}>分以内</span>
            </div>

            <FormField.Error>
              {errors.walkDuringMax?.message || ""}
            </FormField.Error>
          </FormField>

          <FormField id="oldMax" label="築年数">
            <div className={classNames(styles.oldMaxContainer)}>
              <Input
                className={styles.oldMaxInput}
                id="oldMax"
                placeholder="例：10"
                type="tel"
                {...register("oldMax")}
              />
              <span className={styles.unitLabel}>年以内</span>
            </div>

            <FormField.Error>{errors.oldMax?.message || ""}</FormField.Error>
          </FormField>

          <FormField label="物件種別">
            <WatchingFormValues control={control}>
              {({ customerBukkenOptionIdsList }) => (
                <div className={styles.optionContainer}>
                  {bukkenShubetsuOptions?.optionsList.map((option) => (
                    <Chip
                      key={option.id}
                      checked={customerBukkenOptionIdsList?.includes(option.id)}
                      id={option.id}
                      label={option.name}
                      value={option.id}
                      {...register("customerBukkenOptionIdsList")}
                    />
                  ))}
                </div>
              )}
            </WatchingFormValues>
          </FormField>

          <FormField label="こだわり条件">
            <WatchingFormValues control={control}>
              {({ customerBukkenOptionIdsList }) => {
                return otherOptions?.map((category) => (
                  <div key={category.id} className={styles.categoryContainer}>
                    <p className={styles.categoryLabel}>{category.name}</p>
                    <div className={styles.optionContainer}>
                      {category.optionsList.map((option) => (
                        <Chip
                          key={option.id}
                          id={option.id}
                          label={option.name}
                          value={option.id}
                          checked={customerBukkenOptionIdsList?.includes(
                            option.id
                          )}
                          {...register("customerBukkenOptionIdsList")}
                        />
                      ))}
                    </div>
                  </div>
                ));
              }}
            </WatchingFormValues>
          </FormField>

          <FormField
            id="other"
            label="その他ご希望やこだわりがあれば教えてください"
          >
            <Textarea
              id="other"
              placeholder="例：オートロック"
              {...register("other")}
            />
          </FormField>

          <FormField id="phoneNumber" label="電話番号">
            <Input
              id="phoneNumber"
              placeholder="例：090-1234-5678"
              type="text"
              {...register("phoneNumber")}
            />
            <FormField.Error>
              {errors.phoneNumber?.message || ""}
            </FormField.Error>
          </FormField>

          <div className={styles.submitButtonContainer}>
            <Button
              disabled={!isValid}
              isSubmitting={isSubmitting}
              type="submit"
            >
              内容を確認する
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
