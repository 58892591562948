import type { SelectChoice } from "components/form/Select";

import type { ConditionFormSchema } from "../CustomerConditionForm";

// 賃料の選択肢を生成する

/**
 * 賃料の単位（万円）
 */
export const RENT_UNIT = 10_000;
/**
 * 賃料下限なしの値
 */
export const RENT_MIN_LOWER_VALUE = 0;
/**
 * 賃料上限なしの値
 */
export const RENT_MAX_UPPER_VALUE = 9_990_000;

const generateRentMaster = (): number[] => {
  const RENT_MIN_LIST: number[] = [];
  for (let i = RENT_UNIT * 4; i <= 100 * RENT_UNIT; i += RENT_UNIT) {
    if (i <= 30 * RENT_UNIT) {
      RENT_MIN_LIST.push(i);
    } else if (i <= 50 * RENT_UNIT && i % (5 * RENT_UNIT) === 0) {
      RENT_MIN_LIST.push(i);
    } else if (i === 100 * RENT_UNIT) {
      RENT_MIN_LIST.push(i);
    }
  }
  return RENT_MIN_LIST;
};

/**
 * 賃料下限の選択肢
 */
export const RENT_MIN_CHOICES: SelectChoice[] = [
  { value: RENT_MIN_LOWER_VALUE, label: "下限なし" },
  ...generateRentMaster().map((rentMin) => ({
    value: rentMin,
    label: `${rentMin / RENT_UNIT}万以上`,
  })),
];

/**
 * 賃料上限の選択肢
 */
export const RENT_MAX_CHOICES: SelectChoice[] = [
  { value: RENT_MAX_UPPER_VALUE, label: "上限なし" },
  ...generateRentMaster()
    .slice(1)
    .map((rentMax) => ({
      value: rentMax,
      label: `${rentMax / RENT_UNIT}万以下`,
    })),
];

/**
 * 仲介側で入力された賃料を下限の選択肢に変換する
 */
export const convertRentMin = (rent: number) => {
  if (rent < 40_000) {
    return RENT_MIN_LOWER_VALUE;
  }
  if (rent < 300_000) {
    return Math.floor(rent / RENT_UNIT) * RENT_UNIT;
  }
  if (rent < 350_000) {
    return 300_000;
  }
  if (rent < 400_000) {
    return 450_000;
  }
  if (rent < 450_000) {
    return 400_000;
  }
  if (rent < 500_000) {
    return 450_000;
  }
  if (rent < 1_000_000) {
    return 500_000;
  }
  return 1_000_000;
};

/**
 * 仲介側で入力された賃料を上限の選択肢に変換する
 */
export const convertRentMax = (rent: number) => {
  if (rent === 0) {
    return RENT_MAX_UPPER_VALUE;
  }
  if (rent <= 50_000) {
    return 50_000;
  }
  if (rent <= 300_000) {
    return Math.ceil(rent / RENT_UNIT) * RENT_UNIT;
  }
  if (rent <= 350_000) {
    return 350_000;
  }
  if (rent <= 400_000) {
    return 400_000;
  }
  if (rent <= 450_000) {
    return 450_000;
  }
  if (rent <= 500_000) {
    return 500_000;
  }
  if (rent <= 1_000_000) {
    return 1_000_000;
  }
  return RENT_MAX_UPPER_VALUE;
};

// 面積の選択肢を生成する

/**
 * 面積下限なしの値
 */
export const SQUARE_MIN_LOWER_VALUE = 0;
/**
 * 面積上限なしの値
 */
export const SQUARE_MAX_UPPER_VALUE = 999;

/**
 * 一人暮らし:35㎡以下
 * 二人暮らし:35㎡以上
 * ファミリー:45㎡以上
 */
export const SQUARE_RANGE_CHOICES = {
  single: { squareMax: 35, squareMin: SQUARE_MIN_LOWER_VALUE },
  couple: { squareMax: SQUARE_MAX_UPPER_VALUE, squareMin: 35 },
  family: { squareMax: SQUARE_MAX_UPPER_VALUE, squareMin: 45 },
};

const generateSquareMaster = (): number[] => {
  const SQUARE_MIN_LIST: number[] = [];
  for (let i = 20; i <= 100; i += 5) {
    SQUARE_MIN_LIST.push(i);
  }
  return SQUARE_MIN_LIST;
};

/**
 * 面積下限の選択肢
 */
export const SQUARE_MIN_CHOICES: SelectChoice[] = [
  { value: SQUARE_MIN_LOWER_VALUE, label: "下限なし" },
  ...generateSquareMaster().map((squareMin) => ({
    value: squareMin,
    label: `${squareMin}㎡以上`,
  })),
];

/**
 * 面積上限の選択肢
 */
export const SQUARE_MAX_CHOICES: SelectChoice[] = [
  { value: SQUARE_MAX_UPPER_VALUE, label: "上限なし" },
  ...generateSquareMaster()
    .slice(1)
    .map((squareMax) => ({
      value: squareMax,
      label: `${squareMax}㎡以下`,
    })),
];

/**
 * 仲介側で入力された面積を下限の選択肢に変換する
 */
export const convertSquareMin = (square: number) => {
  if (square === 0) {
    return SQUARE_MIN_LOWER_VALUE;
  }
  if (square < 20) {
    return 20;
  }
  if (square < 100) {
    return Math.floor(Math.floor(square) / 5) * 5;
  }
  return 100;
};

/**
 * 仲介側で入力された面積を上限の選択肢に変換する
 */
export const convertSquareMax = (square: number) => {
  if (square === 0) {
    return SQUARE_MAX_UPPER_VALUE;
  }
  if (square <= 25) {
    return 25;
  }
  if (square <= 100) {
    return Math.ceil(square / 5) * 5;
  }
  return SQUARE_MAX_UPPER_VALUE;
};

// エリア

/**
 * 市区郡の最大選択数
 */
export const MAX_CITY_COUNT = 35;

/**
 * 町村の最大選択数
 */
export const MAX_CHOMEI_COUNT = 60;

/**
 * 希望条件フォームのデフォルト値
 */
export const CONDITION_FORM_DEFAULT_VALUES: ConditionFormSchema = {
  customerId: "",
  moveInTiming: "",
  rentMin: RENT_MIN_LOWER_VALUE,
  rentMax: RENT_MAX_UPPER_VALUE,
  stationIdAndLineIdsList: [],
  chomeiIdsList: [], // フォームの制御のために値を保持しているが、リクエストには送信しない
  citiesList: [],
  cityIdsList: [],
  squareMin: SQUARE_MIN_LOWER_VALUE,
  squareMax: SQUARE_MAX_UPPER_VALUE,
  layoutIdsList: [],
  walkDuringMax: "",
  oldMax: "",
  customerBukkenOptionIdsList: [],
  other: "",
  phoneNumber: "",
};
