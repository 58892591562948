import { useEffect } from "react";

/**
 * ページが切り替わるごとにGAへイベントを送信する
 */
export const useAnalyticsPageView = (): void => {
  useEffect(() => {
    if (window.gtag === undefined) {
      return;
    }

    window.gtag("event", "page_view", {
      page_path: location.pathname,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};
