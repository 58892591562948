import { ReactComponent as Line } from "assets/icons/Line.svg";
import { Button } from "components/button/Button";
import { AuthContext, checkSuccessAuthContext } from "context/AuthProvider";
import { useContext } from "react";

import styles from "./Completed.module.scss";

export const Completed = () => {
  const context = useContext(AuthContext);

  const onClickNavigateStoreVisitAppointment = () => {
    if (checkSuccessAuthContext(context)) {
      window.open(
        `/${context.data.organizationID}/store-visit-appointment?auth_code=${context.data.authCode}`
      );
    }
  };

  const onClickNavigateAddLine = () => {
    if (checkSuccessAuthContext(context)) {
      window.open(
        `/${context.data.organizationID}/add-line-friend?auth_code=${context.data.authCode}&source=condition-form`
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.messageContainer}>
        <h2 className={styles.completedMessage}>
          ご回答ありがとうございました
        </h2>
        <p className={styles.comment}>
          このまま日時をお選びいただくだけで、内見・来店のリクエストができます。
          <br />
          まだLINE追加していない方は、今後のやりとりがスムーズになりますのでぜひ認証をお済ませください。
        </p>
      </div>

      <div className={styles.buttonContainer}>
        <Button type="button" onClick={onClickNavigateStoreVisitAppointment}>
          内見・来店予約する
        </Button>
        <Button
          color="vendorLine"
          type="button"
          variant="outline"
          onClick={onClickNavigateAddLine}
        >
          <div className={styles.lineIcon}>
            <Line />
          </div>
          <span>LINE認証へ進む</span>
        </Button>
      </div>
    </div>
  );
};
