const VISIT_STORE = "visit-store";
const GENCHI_SYUGO = "genchi-syugo";
const VIDEO = "video";
const OTHERS = "others";

export const MethodToVisit = {
  VISIT_STORE,
  GENCHI_SYUGO,
  VIDEO,
  OTHERS,
} as const;

export const MethodToVisitList = {
  [VISIT_STORE]: VISIT_STORE,
  [GENCHI_SYUGO]: GENCHI_SYUGO,
  [VIDEO]: VIDEO,
  [OTHERS]: OTHERS,
};

export const MethodToVisitPriority = {
  [VISIT_STORE]: 0,
  [GENCHI_SYUGO]: 1,
  [VIDEO]: 2,
  [OTHERS]: 3,
} as const;

export const isMethodToVisitID = (id: string): id is MethodToVisitID =>
  Object.keys(MethodToVisitList).includes(id);

export type MethodToVisitID = keyof typeof MethodToVisitList;
