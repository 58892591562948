import type {
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { RejectAutoButsudashiPageViewResponse } from "apis/proto/canary_cloud/customer/v1/api_pb";
import {
  EmptyRequest,
  RejectAutoButsudashiRequest,
} from "apis/proto/canary_cloud/customer/v1/api_pb";
import { ApiV1Client } from "apis/proto/canary_cloud/customer/v1/ApiServiceClientPb";
import { AuthContext, checkSuccessAuthContext } from "context/AuthProvider";
import { useContext } from "react";
import { config } from "util/constants/config";
import { queryKeyGenerator } from "util/queryKeyGenerator";

export const RESOURCE_NAME = "rejectAutoButsudashi";
const rejectAutoButsudashi = queryKeyGenerator(RESOURCE_NAME);

const rejectAutoButsudashiPageView = async (
  token: string,
  organizationID: string
): Promise<RejectAutoButsudashiPageViewResponse.AsObject> => {
  const client = new ApiV1Client(config.API_BASE_URL);
  const req = new EmptyRequest();
  const res = await client.rejectAutoButsudashiPageView(req, {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return res.toObject();
};

export const useRejectAutoButsudashiPageView = (
  options?: UseQueryOptions<
    RejectAutoButsudashiPageViewResponse.AsObject,
    unknown,
    RejectAutoButsudashiPageViewResponse.AsObject,
    ReturnType<typeof rejectAutoButsudashi.generateGetKey>
  >
) => {
  const context = useContext(AuthContext);
  const enabled = checkSuccessAuthContext(context);

  if (!enabled) {
    throw Error("not success auth context");
  }

  return useQuery({
    queryKey: rejectAutoButsudashi.generateGetKey(),
    queryFn: async () => {
      return await rejectAutoButsudashiPageView(
        context.data.token,
        context.data.organizationID
      );
    },
    ...options,
    enabled: enabled && options?.enabled,
  });
};

const doRejectAutoButsudashi = async (
  token: string,
  organizationID: string,
  variables: RejectAutoButsudashiRequest.AsObject
): Promise<EmptyRequest.AsObject> => {
  const client = new ApiV1Client(config.API_BASE_URL);
  const req = new RejectAutoButsudashiRequest();
  req.setReasonId(variables.reasonId);
  req.setOpinion(variables.opinion);

  const res = await client.rejectAutoButsudashi(req, {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return res.toObject();
};

export const useDoRejectAutoButsudashi = (
  options?: UseMutationOptions<
    EmptyRequest.AsObject,
    unknown,
    RejectAutoButsudashiRequest.AsObject,
    ReturnType<typeof rejectAutoButsudashi.generateMutationKey>
  >
) => {
  const context = useContext(AuthContext);
  const enabled = checkSuccessAuthContext(context);

  if (!enabled) {
    throw Error("not success auth context");
  }

  return useMutation({
    mutationKey: rejectAutoButsudashi.generateMutationKey(),
    mutationFn: async (variables: RejectAutoButsudashiRequest.AsObject) => {
      return await doRejectAutoButsudashi(
        context.data.token,
        context.data.organizationID,
        variables
      );
    },
    ...options,
  });
};
