import classNames from "classnames";
import type { AnchorHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";
import { forwardRef } from "react";

import styles from "./Button.module.scss";

type RequiredAnchorPropsName = "children" | "href";
type HTMLAnchorProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;
type BaseAnchorProps = Omit<HTMLAnchorProps, RequiredAnchorPropsName> &
  Required<Pick<HTMLAnchorProps, RequiredAnchorPropsName>>;

type LinkButtonProps = BaseAnchorProps & {
  children: ReactNode;
  color?: "primary" | "danger" | "vendorLine";
  href: string;
  left?: ReactNode;
  right?: ReactNode;
  variant?: "filled" | "outline" | "subtle";
};

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  (
    {
      href,
      children,
      variant = "filled",
      color = "primary",
      left,
      right,
      className,
      ...props
    },
    ref
  ) => {
    const variantStyle = {
      filled: styles.filled,
      outline: styles.outline,
      subtle: styles.subtle,
    }[variant];

    return (
      <a
        ref={ref}
        className={classNames(styles.buttonBase, variantStyle, className)}
        data-color={color}
        href={href}
        {...props}
      >
        {left && <span className={styles.left}>{left}</span>}
        <span className={styles.buttonLabel}>{children}</span>
        {right && <span className={styles.right}>{right}</span>}
      </a>
    );
  }
);

LinkButton.displayName = "LinkButton";
