import type {
  CreateStoreVisitAppointmentFormPageViewResponse,
  CustomerBukkenPageViewResponse,
  EmptyResponse,
} from "apis/proto/canary_cloud/customer/v1/api_pb";
import {
  CreateStoreVisitAppointmentRequest,
  CustomerBukkenPageViewRequest,
  EmptyRequest,
} from "apis/proto/canary_cloud/customer/v1/api_pb";
import { ApiV1Client } from "apis/proto/canary_cloud/customer/v1/ApiServiceClientPb";
import { Head } from "components/info/Head";
import { PageLoading } from "components/loading/PageLoading";
import { AuthContext } from "context/AuthProvider";
import { Container } from "foundations/Container";
import { ErrorPage } from "pages/Error/Error";
import { useContext, useEffect, useState } from "react";
import {
  convertFloorString,
  convertLayoutString,
  convertMonthlyString,
  convertSquareString,
} from "util/converter/customerBukken";
import { toUnixTimeFromDateTime } from "util/datetimeUtils";
import { checkErrorType, isGrpcError } from "util/error/error";
import { logError } from "util/error/logError";
import type { APIBaseResponseType } from "util/utilTypes";

import { useCustomerBukkenID } from "./hooks";
import type { StoreVisitAppointFormValues } from "./modules/StoreVisitAppointForm";
import { StoreVisitAppointForm } from "./modules/StoreVisitAppointForm";
import { StoreVisitAppointSuccess } from "./modules/StoreVisitAppointSuccess";
import styles from "./StoreVisitAppointFormPage.module.scss";

const getCustomerBukkenPageView = async (
  token: string,
  organizationID: string,
  customerBukkenID: string
): Promise<CustomerBukkenPageViewResponse.AsObject> => {
  const client = new ApiV1Client(
    process.env["REACT_APP_API_BASE_URL"] as string
  );
  const req = new CustomerBukkenPageViewRequest();
  req.setCustomerBukkenId(customerBukkenID);
  const res = await client.customerBukkenPageView(req, {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return res.toObject();
};

const getCreateStoreVisitAppointmentFormPageView = async (
  token: string,
  organizationID: string
): Promise<CreateStoreVisitAppointmentFormPageViewResponse.AsObject> => {
  const client = new ApiV1Client(
    process.env["REACT_APP_API_BASE_URL"] as string
  );
  const req = new EmptyRequest();
  const res = await client.createStoreVisitAppointmentFormPageView(req, {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return res.toObject();
};

const createStoreVisitAppointment = async (
  token: string,
  organizationID: string,
  values: CreateStoreVisitAppointmentRequest.AsObject
): Promise<EmptyResponse.AsObject> => {
  const client = new ApiV1Client(
    process.env["REACT_APP_API_BASE_URL"] as string
  );
  const req = new CreateStoreVisitAppointmentRequest();
  req.setAppointedAt(values.appointedAt);
  req.setDemand(values.demand);
  req.setMethodToVisit(values.methodToVisit);
  req.setReservationsCount(values.reservationsCount);
  req.setPhoneNumber(values.phoneNumber);

  const res = await client.createStoreVisitAppointment(req, {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return res.toObject();
};

export const StoreVisitAppointFormPage = () => {
  const context = useContext(AuthContext);
  const customerBukkenID = useCustomerBukkenID();

  const [
    createStoreVisitAppointmentFormPageViewResponse,
    setCreateStoreVisitAppointmentFormPageViewResponse,
  ] = useState<
    APIBaseResponseType<CreateStoreVisitAppointmentFormPageViewResponse.AsObject>
  >({
    state: "initial",
    data: null,
  });

  const [customerBukken, setCustomerBukken] = useState<
    APIBaseResponseType<
      NonNullable<CustomerBukkenPageViewResponse.AsObject["customerBukken"]>
    >
  >({
    state: "initial",
    data: null,
  });

  const [
    createStoreVisitAppointmentResponse,
    setCreateStoreVisitAppointmentResponse,
  ] = useState<APIBaseResponseType<EmptyResponse.AsObject>>({
    state: "initial",
    data: null,
  });

  const handleSubmit = async (values: StoreVisitAppointFormValues) => {
    try {
      if (context.state !== "success") {
        throw Error("authentication failed");
      }

      setCreateStoreVisitAppointmentResponse({
        state: "loading",
        data: null,
      });

      let customerBukkenString = "";
      if (customerBukken.state === "success") {
        customerBukkenString = `\n${toCustomerBukkenString(customerBukken.data)}`;
      }

      await createStoreVisitAppointment(
        context.data.token,
        context.data.organizationID,
        {
          methodToVisit: values.methodToVisitId,
          reservationsCount: values.reservedCount,
          demand: values.demand + customerBukkenString,
          appointedAt: toUnixTimeFromDateTime({
            date: values.selectedDate,
            time: values.selectedTime,
          }),
          phoneNumber: values.phoneNumber,
        }
      );

      setCreateStoreVisitAppointmentResponse({
        state: "success",
        data: {},
      });
    } catch (e) {
      logError(e);

      if (isGrpcError(e)) {
        const errorType = checkErrorType(e.code);
        setCreateStoreVisitAppointmentResponse({
          state: "failed",
          data: {
            errorCode: errorType,
            error: new Error(JSON.stringify(e)),
          },
        });
      } else {
        setCreateStoreVisitAppointmentResponse({
          state: "failed",
          data: {
            errorCode: "other",
            error: new Error(JSON.stringify(e)),
          },
        });
      }
    }

    return;
  };

  useEffect(() => {
    if (context.state === "success") {
      (async () => {
        try {
          if (!customerBukkenID) {
            return;
          }

          setCustomerBukken({
            state: "loading",
            data: null,
          });

          const res = await getCustomerBukkenPageView(
            context.data.token,
            context.data.organizationID,
            customerBukkenID
          );

          if (!res.customerBukken) {
            throw new Error("customer bukken is not found");
          }

          setCustomerBukken({
            state: "success",
            data: res.customerBukken,
          });
        } catch (e) {
          logError(e);

          if (isGrpcError(e)) {
            const errorType = checkErrorType(e.code);
            setCustomerBukken({
              state: "failed",
              data: {
                errorCode: errorType,
                error: new Error(JSON.stringify(e)),
              },
            });
          } else {
            setCustomerBukken({
              state: "failed",
              data: {
                errorCode: "other",
                error: new Error(JSON.stringify(e)),
              },
            });
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (context.state === "success") {
      (async () => {
        try {
          setCreateStoreVisitAppointmentFormPageViewResponse({
            state: "loading",
            data: null,
          });
          const res = await getCreateStoreVisitAppointmentFormPageView(
            context.data.token,
            context.data.organizationID
          );

          setCreateStoreVisitAppointmentFormPageViewResponse({
            state: "success",
            data: res,
          });
        } catch (e) {
          logError(e);

          if (isGrpcError(e)) {
            const errorType = checkErrorType(e.code);
            setCreateStoreVisitAppointmentFormPageViewResponse({
              state: "failed",
              data: {
                errorCode: errorType,
                error: new Error(JSON.stringify(e)),
              },
            });
          } else {
            setCreateStoreVisitAppointmentFormPageViewResponse({
              state: "failed",
              data: {
                errorCode: "other",
                error: new Error(JSON.stringify(e)),
              },
            });
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    context.state === "success" &&
    !context.data.isEnableStoreVisitAppointment
  ) {
    return <ErrorPage error="store-visit-appointment-not-allowed" />;
  }

  if (
    createStoreVisitAppointmentFormPageViewResponse.state === "loading" ||
    createStoreVisitAppointmentFormPageViewResponse.state === "initial" ||
    customerBukken.state === "loading"
  ) {
    return <PageLoading fullscreen />;
  }

  if (createStoreVisitAppointmentFormPageViewResponse.state === "failed") {
    return (
      <ErrorPage
        error={createStoreVisitAppointmentFormPageViewResponse.data.errorCode}
      />
    );
  }

  if (customerBukken.state === "failed") {
    return <ErrorPage error={customerBukken.data.errorCode} />;
  }

  if (createStoreVisitAppointmentResponse.state === "success") {
    return (
      <>
        <Head title="来店予約完了ページ" />
        <div className={styles.container}>
          <StoreVisitAppointSuccess />
        </div>
      </>
    );
  }

  return (
    <>
      <Head title="来店予約ページ" />
      <Container>
        <div className={styles.container}>
          <StoreVisitAppointForm
            isError={createStoreVisitAppointmentResponse.state === "failed"}
            createStoreVisitAppointmentForm={
              createStoreVisitAppointmentFormPageViewResponse.data
            }
            onSubmit={handleSubmit}
          />
        </div>
      </Container>
    </>
  );
};

const toCustomerBukkenString = (
  customerBukken: NonNullable<
    CustomerBukkenPageViewResponse.AsObject["customerBukken"]
  >
): string => {
  return `物件名: ${customerBukken.name}
家賃: ${convertMonthlyString(customerBukken)}
住所: ${customerBukken.address}
階数: ${convertFloorString(customerBukken)}
面積: ${convertSquareString(customerBukken)}
間取り: ${convertLayoutString(customerBukken)}`;
};
