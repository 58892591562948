import type { ReactNode } from "react";
import type {
  Control,
  DeepPartialSkipArrayKey,
  FieldValues,
} from "react-hook-form";
import { useWatch } from "react-hook-form";

type WatchingFormValuesProps<T extends FieldValues = FieldValues> = {
  children: (values: DeepPartialSkipArrayKey<T>) => ReactNode;
  control: Control<T>;
};

export const WatchingFormValues = <T extends FieldValues = FieldValues>(
  props: WatchingFormValuesProps<T>
) => {
  const { control, children } = props;
  const value = useWatch<T>({ control });
  return <>{children(value)}</>;
};
