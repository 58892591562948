import classNames from "classnames";
import type { FieldAttributes } from "formik";
import { Field } from "formik";
import type {
  ChangeEvent,
  CSSProperties,
  DetailedHTMLProps,
  InputHTMLAttributes,
} from "react";
import { forwardRef } from "react";
import type { Control, FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

import styles from "./Checkbox.module.scss";
import labelStyles from "./FormLabel.module.scss";

type FormikCheckboxProps<T = unknown> = FieldAttributes<T> & {
  containerStyle?: CSSProperties;
  label?: string;
  small?: boolean;
};

/**
 * @deprecated
 * 同ファイル内の Checkbox を使用してください。
 */
export const FormikCheckbox = (props: FormikCheckboxProps) => {
  const { label, containerStyle = { width: 85 }, small, ...feildProps } = props;
  return (
    <label
      className={classNames([styles.checkboxContainer, small && styles.small])}
      style={containerStyle}
    >
      <Field className={styles.checkbox} type="checkbox" {...feildProps} />
      {label && label}
    </label>
  );
};

type RequiredInputPropsName = "id" | "name" | "value";
type HTMLInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
type BaseCheckboxProps = Omit<
  HTMLInputProps,
  RequiredInputPropsName | "ref" | "type"
> &
  Required<Pick<HTMLInputProps, RequiredInputPropsName>>;

type CheckboxProps = BaseCheckboxProps & {
  classNames?: {
    checkbox?: string;
    container?: string;
    label?: string;
  };
  label?: string;
};

export const CheckboxView = forwardRef<HTMLInputElement, HTMLInputProps>(
  (props, ref) => {
    const {
      id,
      className,
      disabled = false,
      readOnly = false,
      ...otherProps
    } = props;

    return (
      <input
        ref={ref}
        aria-readonly={readOnly}
        className={classNames(styles.checkbox, className)}
        disabled={disabled || readOnly}
        id={id}
        type="checkbox"
        {...otherProps}
      />
    );
  }
);

CheckboxView.displayName = "CheckboxView";

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const {
      id,
      label,
      className,
      disabled = false,
      readOnly = false,
      classNames: overrideClassNames = {},
      ...otherProps
    } = props;

    if (label) {
      return (
        <label
          aria-disabled={disabled}
          aria-readonly={readOnly}
          htmlFor={id}
          className={classNames(
            labelStyles.labelContainer,
            overrideClassNames.container
          )}
        >
          <CheckboxView
            ref={ref}
            aria-readonly={readOnly}
            disabled={disabled || readOnly}
            id={id}
            className={classNames(
              styles.checkbox,
              className,
              overrideClassNames.checkbox
            )}
            {...otherProps}
          />
          <span className={classNames(styles.label, overrideClassNames.label)}>
            {label}
          </span>
        </label>
      );
    }

    return (
      <CheckboxView
        ref={ref}
        aria-readonly={readOnly}
        disabled={disabled || readOnly}
        id={id}
        className={classNames(
          styles.checkbox,
          className,
          overrideClassNames.checkbox
        )}
        {...otherProps}
      />
    );
  }
);

Checkbox.displayName = "Checkbox";

type ControlCheckboxProps<
  T extends FieldValues,
  N extends FieldPath<T>,
> = BaseCheckboxProps & {
  control: Control<T>;
  id: string;
  label?: string;
  name: N;
};

export const ControlCheckbox = <T extends FieldValues, N extends FieldPath<T>>(
  props: ControlCheckboxProps<T, N>
) => {
  const {
    id,
    label,
    className,
    disabled = false,
    readOnly = false,
    control,
    value,
    name,
    onChange,
    ...otherProps
  } = props;

  const { field } = useController({
    name,
    control,
  });

  const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    onChange && onChange(e);
  };

  return (
    <Checkbox
      ref={field.ref}
      className={classNames(styles.checkbox, className)}
      disabled={disabled || readOnly}
      id={id}
      label={label}
      name={field.name}
      readOnly={readOnly}
      value={value}
      onChange={onChangeCheckbox}
      {...otherProps}
    />
  );
};

ControlCheckbox.displayName = "ControlCheckbox";
