import classNames from "classnames";
import { Button } from "components/button/Button";
import { Select } from "components/form/Select";
import { Modal } from "components/modal/Modal";
import { WatchingFormValues } from "libs/react-hook-form/components/WatchigFormValues";
import { useForm } from "react-hook-form";

import {
  SQUARE_MAX_CHOICES,
  SQUARE_MAX_UPPER_VALUE,
  SQUARE_MIN_CHOICES,
  SQUARE_MIN_LOWER_VALUE,
} from "./constant";
import styles from "./SelectModal.module.scss";

export type SquareRangeValues = {
  squareMax: number;
  squareMin: number;
};

type SelectSquareModalProps = {
  defaultValues: SquareRangeValues;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: SquareRangeValues) => void;
};

export const SelectSquareModal = (props: SelectSquareModalProps) => {
  const { isOpen, onClose, onSubmit, defaultValues } = props;

  const methods = useForm<SquareRangeValues>({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    control,
    reset,
    register,
    formState: { isValid },
  } = methods;

  const handleSubmitEvent = (data: SquareRangeValues) => {
    onSubmit({
      squareMax: data.squareMax,
      squareMin: data.squareMin,
    });
  };

  const onClickResetButton = () => {
    reset({
      squareMax: SQUARE_MAX_UPPER_VALUE,
      squareMin: SQUARE_MIN_LOWER_VALUE,
    });
  };

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose}>
      <Modal.Header onClose={onClose}>お部屋の面積を入力</Modal.Header>

      <form
        className={classNames(styles.formContainer, styles.selectSquareHeight)}
      >
        <WatchingFormValues control={control}>
          {({ squareMax, squareMin }) => (
            <div className={styles.flexRow}>
              <Select
                id="squareMin"
                choices={SQUARE_MIN_CHOICES.map((choice) => ({
                  value: choice.value,
                  label: choice.label,
                  disabled: squareMax ? choice.value > squareMax : false,
                }))}
                {...register("squareMin", { valueAsNumber: true })}
              />
              <span>~</span>
              <Select
                id="squareMax"
                choices={SQUARE_MAX_CHOICES.map((choice) => ({
                  value: choice.value,
                  label: choice.label,
                  disabled: squareMin ? choice.value < squareMin : false,
                }))}
                {...register("squareMax", { valueAsNumber: true })}
              />
            </div>
          )}
        </WatchingFormValues>
      </form>

      <Modal.Footer>
        <Button type="button" variant="subtle" onClick={onClickResetButton}>
          リセット
        </Button>
        <Button
          disabled={!isValid}
          type="button"
          onClick={handleSubmit(handleSubmitEvent)}
        >
          決定
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
