// source: canary_cloud/customer/v1/lib.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.Date', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.DateTime', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.NullDate', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.NullDateTime', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.NullDouble', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.NullInt64', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.NullString', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.Date = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.Date, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.Date.displayName = 'proto.apis.proto.canary_cloud.customer.v1.Date';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.DateTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.DateTime.displayName = 'proto.apis.proto.canary_cloud.customer.v1.DateTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.NullInt64 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.NullInt64, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.NullInt64.displayName = 'proto.apis.proto.canary_cloud.customer.v1.NullInt64';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.NullDouble = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.NullDouble, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.NullDouble.displayName = 'proto.apis.proto.canary_cloud.customer.v1.NullDouble';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.NullString = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.NullString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.NullString.displayName = 'proto.apis.proto.canary_cloud.customer.v1.NullString';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.NullDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.NullDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.NullDate.displayName = 'proto.apis.proto.canary_cloud.customer.v1.NullDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.NullDateTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.NullDateTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.NullDateTime.displayName = 'proto.apis.proto.canary_cloud.customer.v1.NullDateTime';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.Date.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.Date.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Date} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Date.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Date}
 */
proto.apis.proto.canary_cloud.customer.v1.Date.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.Date;
  return proto.apis.proto.canary_cloud.customer.v1.Date.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Date} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Date}
 */
proto.apis.proto.canary_cloud.customer.v1.Date.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.Date.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.Date.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Date} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Date.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.Date.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Date} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Date.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.Date.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Date} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Date.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 day = 3;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.Date.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Date} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Date.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.DateTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.DateTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hour: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minute: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.DateTime}
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.DateTime;
  return proto.apis.proto.canary_cloud.customer.v1.DateTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.DateTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.DateTime}
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHour(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.DateTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.DateTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHour();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMinute();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.DateTime} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.DateTime} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 day = 3;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.DateTime} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 hour = 4;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.prototype.getHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.DateTime} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.prototype.setHour = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 minute = 5;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.prototype.getMinute = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.DateTime} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.DateTime.prototype.setMinute = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.NullInt64.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.NullInt64.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullInt64} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.NullInt64.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hasValue: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullInt64}
 */
proto.apis.proto.canary_cloud.customer.v1.NullInt64.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.NullInt64;
  return proto.apis.proto.canary_cloud.customer.v1.NullInt64.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullInt64} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullInt64}
 */
proto.apis.proto.canary_cloud.customer.v1.NullInt64.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.NullInt64.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.NullInt64.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullInt64} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.NullInt64.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getHasValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 value = 1;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.NullInt64.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullInt64} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.NullInt64.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool has_value = 2;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.NullInt64.prototype.getHasValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullInt64} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.NullInt64.prototype.setHasValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDouble.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.NullDouble.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullDouble} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.NullDouble.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    hasValue: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDouble}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDouble.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.NullDouble;
  return proto.apis.proto.canary_cloud.customer.v1.NullDouble.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullDouble} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDouble}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDouble.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDouble.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.NullDouble.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullDouble} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.NullDouble.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getHasValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDouble.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDouble} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.NullDouble.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional bool has_value = 2;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDouble.prototype.getHasValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDouble} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.NullDouble.prototype.setHasValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.NullString.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.NullString.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullString} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.NullString.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hasValue: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullString}
 */
proto.apis.proto.canary_cloud.customer.v1.NullString.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.NullString;
  return proto.apis.proto.canary_cloud.customer.v1.NullString.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullString}
 */
proto.apis.proto.canary_cloud.customer.v1.NullString.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.NullString.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.NullString.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.NullString.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHasValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.NullString.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullString} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.NullString.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool has_value = 2;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.NullString.prototype.getHasValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullString} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.NullString.prototype.setHasValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDate.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.NullDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.NullDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && proto.apis.proto.canary_cloud.customer.v1.Date.toObject(includeInstance, f),
    hasValue: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDate}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.NullDate;
  return proto.apis.proto.canary_cloud.customer.v1.NullDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDate}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apis.proto.canary_cloud.customer.v1.Date;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.Date.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.NullDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.NullDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.apis.proto.canary_cloud.customer.v1.Date.serializeBinaryToWriter
    );
  }
  f = message.getHasValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Date value = 1;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.Date}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDate.prototype.getValue = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.Date} */ (
    jspb.Message.getWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.Date, 1));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.Date|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDate} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.NullDate.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDate} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.NullDate.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDate.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool has_value = 2;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDate.prototype.getHasValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDate} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.NullDate.prototype.setHasValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDateTime.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.NullDateTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullDateTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.NullDateTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && proto.apis.proto.canary_cloud.customer.v1.DateTime.toObject(includeInstance, f),
    hasValue: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDateTime}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDateTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.NullDateTime;
  return proto.apis.proto.canary_cloud.customer.v1.NullDateTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullDateTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDateTime}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDateTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apis.proto.canary_cloud.customer.v1.DateTime;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.DateTime.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDateTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.NullDateTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.NullDateTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.NullDateTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.apis.proto.canary_cloud.customer.v1.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getHasValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional DateTime value = 1;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.DateTime}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDateTime.prototype.getValue = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.DateTime} */ (
    jspb.Message.getWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.DateTime, 1));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.DateTime|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDateTime} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.NullDateTime.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDateTime} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.NullDateTime.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDateTime.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool has_value = 2;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.NullDateTime.prototype.getHasValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.NullDateTime} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.NullDateTime.prototype.setHasValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


goog.object.extend(exports, proto.apis.proto.canary_cloud.customer.v1);
