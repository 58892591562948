import { Helmet } from "react-helmet-async";

type Props = {
  title: string;
};

export const Head = (props: Props) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      {process.env.REACT_APP_GOOGLE_ANALYTICS_ID && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`}
          />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}');
            `}
          </script>
        </>
      )}
    </Helmet>
  );
};
