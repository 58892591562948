import classNames from "classnames";
import { Field } from "formik";
import type {
  ChangeEvent,
  DetailedHTMLProps,
  TextareaHTMLAttributes,
} from "react";
import { forwardRef } from "react";
import type { Control, FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

import styles from "./Textarea.module.scss";

type Props = {
  fieldName: string;
  placeholder?: string;
};

/**
 * @deprecated
 * 同ファイル内の Textarea を使用してください。
 */
export const FormikTextarea = (props: Props) => {
  return (
    <div>
      <Field
        as="textarea"
        className={styles.textarea}
        name={props.fieldName}
        placeholder={props.placeholder}
        rows={5}
      />
    </div>
  );
};

type RequiredTextareaPropsName = "id" | "name";
type HTMLTextareaProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;
type BaseTextareaProps = Omit<
  HTMLTextareaProps,
  RequiredTextareaPropsName | "ref"
> &
  Required<Pick<HTMLTextareaProps, RequiredTextareaPropsName>>;

type TextareaProps = BaseTextareaProps;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const { rows = 5, className, ...otherProps } = props;

    return (
      <textarea
        ref={ref}
        className={classNames(styles.textarea, className)}
        rows={rows}
        {...otherProps}
      />
    );
  }
);

Textarea.displayName = "Textarea";

type ControlTextareaProps<
  T extends FieldValues,
  N extends FieldPath<T>,
> = BaseTextareaProps & {
  control: Control<T>;
  name: N;
};

export const ControlTextarea = <T extends FieldValues, N extends FieldPath<T>>(
  props: ControlTextareaProps<T, N>
) => {
  const { control, name, className, onChange, ...otherProps } = props;
  const { field } = useController({
    name,
    control,
  });

  const onChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    field.onChange(e);
    onChange && onChange(e);
  };

  return (
    <Textarea
      ref={field.ref}
      className={className}
      name={field.name}
      value={field.value}
      onChange={onChangeTextarea}
      {...otherProps}
    />
  );
};
