import type { RejectAutoButsudashiPageViewResponse } from "apis/proto/canary_cloud/customer/v1/api_pb";
import { Button } from "components/button/Button";
import { FormLabel } from "components/form/FormLabel";
import { FormikRadioGroup } from "components/form/RadioGroup";
import { FormikTextarea } from "components/form/Textarea";
import { Form, Formik } from "formik";

import styles from "./RejectAutoButsudashiForm.module.scss";

interface Props {
  onSubmit: (values: RejectAutoButsudashiFormValues) => Promise<void>;
  reasons: RejectAutoButsudashiPageViewResponse.Reason.AsObject[];
}

export type RejectAutoButsudashiFormValues = {
  opinion: string;
  reasonID: string;
};

export const RejectAutoButsudashiForm = (props: Props) => {
  const initialValues: RejectAutoButsudashiFormValues = {
    reasonID: "",
    opinion: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        await props.onSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <h1 className={styles.pageHeading}>配信停止</h1>
          <fieldset className={styles.formRow}>
            <span className={styles.heading}>
              配信停止の理由をお聞かせください
              <FormLabel type="require" />
            </span>
            <FormikRadioGroup choices={props.reasons} fieldName="reasonID" />
          </fieldset>

          <fieldset className={styles.formRow}>
            <span className={styles.heading}>
              その他ご意見・ご要望
              <FormLabel type="optional" />
            </span>
            <FormikTextarea fieldName="opinion" />
          </fieldset>

          <Button
            color="danger"
            disabled={isSubmitting || values.reasonID === ""}
            isSubmitting={isSubmitting}
            type="submit"
          >
            配信を停止する
          </Button>
        </Form>
      )}
    </Formik>
  );
};
