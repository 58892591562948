import { ReactComponent as CaretIcon } from "assets/icons/Caret.svg";
import classNames from "classnames";
import type { ReactNode } from "react";
import { useCallback, useState } from "react";

import styles from "./Collapse.module.scss";

type CollapseProps = {
  children: ReactNode | (({ expanded }: { expanded: boolean }) => ReactNode);
  classNames?: {
    collapse?: string;
    collapseControl?: string;
    collapseLabel?: string;
    container?: string;
    icon?: string;
  };
  enableScroll?: boolean;
  expand?: boolean;
  label: string;
  leftElement?: ReactNode;
};

export const Collapse = (props: CollapseProps) => {
  const {
    leftElement,
    label,
    children,
    expand: isExpand = false,
    enableScroll = false,
    classNames: overrrideClassNames = {},
  } = props;
  const [expand, setExpand] = useState(isExpand);

  const onClick = useCallback(() => {
    setExpand((prev) => !prev);
  }, []);

  const asChildren =
    typeof children === "function" ? children({ expanded: expand }) : children;

  return (
    <div className={classNames(overrrideClassNames.container)}>
      <button
        type="button"
        className={classNames(
          styles.collapseControl,
          overrrideClassNames.collapseControl
        )}
        onClick={onClick}
      >
        {leftElement && leftElement}
        <div
          className={classNames(
            styles.collapseLabel,
            overrrideClassNames.collapseLabel
          )}
        >
          {label}
        </div>
        <div
          className={classNames(
            styles.icon,
            expand ? styles.openRotate : styles.closeRotate,
            overrrideClassNames.icon
          )}
        >
          <CaretIcon />
        </div>
      </button>

      <div
        className={classNames(styles.collapse, overrrideClassNames.collapse)}
        data-scroll={enableScroll}
        style={expand ? { height: "auto" } : { height: "0" }}
      >
        {asChildren}
      </div>
    </div>
  );
};
