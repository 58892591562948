import styles from "./StoreVisitAppointSuccess.module.scss";

export const StoreVisitAppointSuccess = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>ご予約が完了しました！</h1>
      <div className={styles.sentence}>
        <p>担当者より直接連絡がございますので、</p>
        <p>少々お待ちください！</p>
      </div>
      <img
        alt="お問い合わせ完了！"
        className={styles.image}
        height={375}
        src="/images/StoreVisitAppointSuccess.png"
        width={375}
      />
    </div>
  );
};
