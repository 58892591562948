import type { CustomerBukkenPageViewResponse } from "../../../apis/proto/canary_cloud/customer/v1/api_pb";
import styles from "./CustomerBukkenPopularOptions.module.scss";

type Props = {
  popularOptionsList: CustomerBukkenPageViewResponse.CustomerBukken.AsObject["popularOptionsList"];
};

export const CustomerBukkenPopularOptions = (props: Props) => {
  return (
    <section className={styles.container}>
      <h1 className={styles.title}>人気の条件</h1>
      <ul className={styles.optionArea}>
        {props.popularOptionsList.map((option) => {
          return (
            <li
              key={option.id}
              className={option.isAvailable ? styles.option : styles.noneOption}
            >
              {option.name}
            </li>
          );
        })}
      </ul>
    </section>
  );
};
