import classNames from "classnames";
import type { ReactNode } from "react";

import styles from "./CheckButton.module.scss";

type CheckButtonProps = {
  children: ReactNode;
  icon?: ReactNode;
  isChecked: boolean;
  onClick: () => void;
};

export const CheckButton = (props: CheckButtonProps) => {
  const { icon, children, isChecked, onClick } = props;

  return (
    <button
      type="button"
      className={classNames(
        styles.button,
        isChecked ? styles.checked : styles.unchecked
      )}
      onClick={onClick}
    >
      {icon}
      <span className={styles.label}>{children}</span>
    </button>
  );
};
