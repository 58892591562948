import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import type {
  RestartDeliveryRequest,
  RestartDeliveryResponse,
} from "apis/proto/canary_cloud/customer/v1/api_pb";
import { EmptyRequest } from "apis/proto/canary_cloud/customer/v1/api_pb";
import { ApiV1Client } from "apis/proto/canary_cloud/customer/v1/ApiServiceClientPb";
import { AuthContext, checkSuccessAuthContext } from "context/AuthProvider";
import { useContext } from "react";
import { config } from "util/constants/config";
import { queryKeyGenerator } from "util/queryKeyGenerator";

export const RESOURCE_NAME = "restartDelivery";
const restartDeliveryKey = queryKeyGenerator(RESOURCE_NAME);

const restartDelivery = async (
  token: string,
  organizationID: string
): Promise<RestartDeliveryResponse.AsObject> => {
  const client = new ApiV1Client(config.API_BASE_URL);
  const req = new EmptyRequest();
  const res = await client.restartDelivery(req, {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return res.toObject();
};

export const useDoRestartDelivery = (
  options?: UseMutationOptions<
    EmptyRequest.AsObject,
    unknown,
    RestartDeliveryRequest.AsObject,
    ReturnType<typeof restartDeliveryKey.generateMutationKey>
  >
) => {
  const context = useContext(AuthContext);
  const enabled = checkSuccessAuthContext(context);

  if (!enabled) {
    throw Error("not success auth context");
  }

  return useMutation({
    mutationKey: restartDeliveryKey.generateMutationKey(),
    mutationFn: async () => {
      return await restartDelivery(
        context.data.token,
        context.data.organizationID
      );
    },
    ...options,
  });
};
