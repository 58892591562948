import { SuspensePage } from "components/loading/SuspensePage";
import { AuthContext, AuthProvider } from "context/AuthProvider";
import { useAnalyticsPageView } from "customHooks/useAnalyticsPageView";
import { useAnalyticsUser } from "customHooks/useAnalyticsUser";
import { useCheckRevision } from "customHooks/useCheckRevision";
import { Header } from "foundations/Header";
import { AddLineFriend } from "pages/AddLineFriend/AddLineFriend";
import { CreateCustomer } from "pages/CreateCustomer/CreateCustomer";
import { CustomerBukken } from "pages/CustomerBukken/CustomerBukken";
import { CustomerConditionForm } from "pages/CustomerConditionForm/CustomerConditionForm";
import { ErrorPage } from "pages/Error/Error";
import { RejectAutoButsudashiPage } from "pages/RejectAutoButsudashi/RejectAutoButsudashiPage";
import { StoreVisitAppointFormPage } from "pages/StoreVisitAppointFormPage/StoreVisitAppointFormPage";
import { useContext } from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";

const FunctionalRoutes = () => {
  useAnalyticsPageView();
  useCheckRevision();
  return <Outlet />;
};

const AnalysisRoutes = () => {
  const context = useContext(AuthContext);

  useAnalyticsUser(
    {
      name: context.state === "success" ? context.data.name : "",
      organizationId:
        context.state === "success" ? context.data.organizationID : "",
    },
    process.env.REACT_APP_GOOGLE_ANALYTICS_ID
  );

  return <Outlet />;
};
export const router = createBrowserRouter([
  {
    path: ":organizationId",
    element: <FunctionalRoutes />,
    children: [
      {
        path: "create-customer",
        element: <CreateCustomer />,
      },
      {
        path: "",
        element: (
          <AuthProvider>
            <Header />
            <AnalysisRoutes />
          </AuthProvider>
        ),
        children: [
          {
            path: "condition-form",
            element: (
              <SuspensePage>
                <CustomerConditionForm />
              </SuspensePage>
            ),
          },
          {
            path: "customer-bukkens",
            children: [
              {
                path: ":customerBukkenId",
                element: <CustomerBukken />,
              },
              {
                path: "",
                element: <ErrorPage error="notfound" />,
              },
            ],
          },
          {
            path: "store-visit-appointment",
            element: <StoreVisitAppointFormPage />,
          },
          {
            path: "reject-auto-butsudashi",
            element: <RejectAutoButsudashiPage />,
          },
          {
            path: "add-line-friend",
            element: <AddLineFriend />,
          },
          {
            path: "",
            element: <ErrorPage error="notfound" />,
          },
        ],
      },
      {
        path: "*",
        element: <ErrorPage error="notfound" />,
      },
    ],
  },
  {
    path: "",
    element: <ErrorPage error="notfound" />,
  },
]);
