import { useCallback, useState } from "react";

/**
 * ページ内でモーダルの表示状態を管理するカスタムフック
 */
export const useOpenModalKey = <T extends string>(
  _controlledModalKeys: T[],
  options: {
    defaultOpenModalKey?: T;
  } = {}
) => {
  const { defaultOpenModalKey } = options;

  const [openModalKey, setOpenModalKey] = useState<T | null>(
    defaultOpenModalKey || null
  );

  const onOpenModal = useCallback((key: T) => {
    setOpenModalKey(key);
  }, []);

  const onCloseModal = useCallback(() => {
    setOpenModalKey(null);
  }, []);

  return {
    isOpenModal: (modalKey: T) => openModalKey === modalKey,
    onCloseModal,
    onOpenModal,
  };
};
