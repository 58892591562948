import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import type { CustomerBukkenPageViewResponse } from "apis/proto/canary_cloud/customer/v1/api_pb";
import classNames from "classnames";
import type { MouseEventHandler, MutableRefObject } from "react";
import { useRef, useState } from "react";
import type { Settings } from "react-slick";
import Slider from "react-slick";

import styles from "./ImageSlider.module.scss";

type ArrowButtonProps = {
  direction: "prev" | "next";
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const ArrowButton = (props: ArrowButtonProps) => {
  const isPrev = props.direction === "prev";
  return (
    <button
      aria-label={isPrev ? "前の画像へ移動" : "次の画像へ移動"}
      className={classNames(
        styles.arrowButton,
        isPrev ? styles.prev : styles.next
      )}
      onClick={props.onClick}
    />
  );
};

type Props = {
  imagesList: CustomerBukkenPageViewResponse.CustomerBukken.AsObject["imagesList"];
};

export const ImageSlider = (props: Props) => {
  const [imageSlideIndex, setImageSlideIndex] = useState(1);
  const slider: MutableRefObject<Slider | null> = useRef(null);

  const sliderSettings: Settings = {
    dots: false,
    infinite: true,
    speed: 400,
    arrows: true,
    prevArrow: (
      <ArrowButton direction="prev" onClick={slider.current?.slickPrev} />
    ),
    nextArrow: (
      <ArrowButton direction="next" onClick={slider.current?.slickNext} />
    ),
    className: styles.slickSlider,
    afterChange: (currentSlide: number) => {
      setImageSlideIndex(currentSlide + 1);
    },
    beforeChange: (currentSlide: number) => {
      setImageSlideIndex(currentSlide + 1);
    },
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.badge}
      >{`${imageSlideIndex}/${props.imagesList.length}`}</div>
      <div className={styles.imageArea}>
        <Slider ref={slider} {...sliderSettings}>
          {props.imagesList.map((image) => {
            return (
              <div key={image.id} className={styles.images}>
                <div className={styles.imagesInner}>
                  <img
                    alt="物件画像"
                    className={styles.image}
                    src={image.url}
                  />
                  <p className={styles.comment}>{image.comment}</p>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
