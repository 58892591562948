import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import type { UseSuspenseQueryOptions } from "@tanstack/react-query/src/types";
import type {
  GetConditionResponse,
  UpdateConditionResponse,
} from "apis/proto/canary_cloud/customer/v1/api_pb";
import {
  GetConditionRequest,
  UpdateConditionRequest,
} from "apis/proto/canary_cloud/customer/v1/api_pb";
import { ApiV1Client } from "apis/proto/canary_cloud/customer/v1/ApiServiceClientPb";
import { AuthContext, checkSuccessAuthContext } from "context/AuthProvider";
import { useContext } from "react";
import { config } from "util/constants/config";
import { queryKeyGenerator } from "util/queryKeyGenerator";

export const RESOURCE_NAME = "customerCondition";
const customerCondition = queryKeyGenerator(RESOURCE_NAME);

const getCondition = async (
  token: string,
  organizationID: string,
  customerID: string
): Promise<GetConditionResponse.AsObject> => {
  const client = new ApiV1Client(config.API_BASE_URL);
  const req = new GetConditionRequest();
  req.setCustomerId(customerID);
  const res = await client.getCondition(req, {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return res.toObject();
};

export const useGetCondition = (
  options?: UseSuspenseQueryOptions<
    GetConditionResponse.AsObject,
    unknown,
    GetConditionResponse.AsObject,
    ReturnType<typeof customerCondition.generateGetKey>
  >
) => {
  const context = useContext(AuthContext);
  const enabled = checkSuccessAuthContext(context);

  if (!enabled) {
    throw Error("not success auth context");
  }

  return useSuspenseQuery({
    queryKey: customerCondition.generateGetKey(context.data.customerId),
    queryFn: async () => {
      return await getCondition(
        context.data.token,
        context.data.organizationID,
        context.data.customerId
      );
    },
    ...options,
  });
};

const updateCondition = async (
  token: string,
  organizationID: string,
  customerID: string,
  variables: UpdateConditionRequest.AsObject
): Promise<UpdateConditionResponse.AsObject> => {
  const client = new ApiV1Client(config.API_BASE_URL);
  const req = new UpdateConditionRequest();
  req.setCustomerId(customerID);
  req.setMoveInTiming(variables.moveInTiming);
  if (variables.rentMin !== 0) {
    req.setRentMin(variables.rentMin);
  }
  req.setRentMax(variables.rentMax);
  req.setStationIdAndLineIdsList(
    variables.stationIdAndLineIdsList.map((item) => {
      const stationAndLineId = new UpdateConditionRequest.StationIdAndLineId();
      stationAndLineId.setStationId(item.stationId);
      stationAndLineId.setLineId(item.lineId);
      return stationAndLineId;
    })
  );
  req.setCityIdsList(variables.cityIdsList);
  req.setCitiesList(
    variables.citiesList.map((item) => {
      const city = new UpdateConditionRequest.City();
      city.setId(item.id);
      city.setChomeiIdsList(item.chomeiIdsList);
      return city;
    })
  );
  if (variables.squareMin !== 0) {
    req.setSquareMin(variables.squareMin);
  }
  req.setSquareMax(variables.squareMax);
  req.setLayoutIdsList(variables.layoutIdsList);
  if (variables.walkDuringMax !== 0) {
    req.setWalkDuringMax(variables.walkDuringMax);
  }
  if (variables.oldMax !== 0) {
    req.setOldMax(variables.oldMax);
  }
  req.setCustomerBukkenOptionIdsList(variables.customerBukkenOptionIdsList);
  req.setOther(variables.other);
  req.setPhoneNumber(variables.phoneNumber);

  const res = await client.updateCondition(req, {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return res.toObject();
};

export const useUpdateCondition = (
  options?: UseMutationOptions<
    UpdateConditionResponse.AsObject,
    unknown,
    UpdateConditionRequest.AsObject,
    ReturnType<typeof customerCondition.generateMutationKey>
  >
) => {
  const context = useContext(AuthContext);
  const enabled = checkSuccessAuthContext(context);

  if (!enabled) {
    throw Error("not success auth context");
  }

  return useMutation({
    mutationKey: customerCondition.generateMutationKey(context.data.customerId),
    mutationFn: async (variables: UpdateConditionRequest.AsObject) => {
      return await updateCondition(
        context.data.token,
        context.data.organizationID,
        context.data.customerId,
        variables
      );
    },
    ...options,
  });
};
