/**
 * @fileoverview gRPC-Web generated client stub for apis.proto.canary_cloud.customer.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as canary_cloud_customer_v1_api_pb from '../../../canary_cloud/customer/v1/api_pb';


export class ApiV1Client {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetToken = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.GetTokenResponse,
    (request: canary_cloud_customer_v1_api_pb.GetTokenRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.GetTokenResponse.deserializeBinary
  );

  getToken(
    request: canary_cloud_customer_v1_api_pb.GetTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.GetTokenResponse>;

  getToken(
    request: canary_cloud_customer_v1_api_pb.GetTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.GetTokenResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.GetTokenResponse>;

  getToken(
    request: canary_cloud_customer_v1_api_pb.GetTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.GetTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/GetToken',
        request,
        metadata || {},
        this.methodInfoGetToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/GetToken',
    request,
    metadata || {},
    this.methodInfoGetToken);
  }

  methodInfoGetStore = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.GetStoreResponse,
    (request: canary_cloud_customer_v1_api_pb.EmptyRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.GetStoreResponse.deserializeBinary
  );

  getStore(
    request: canary_cloud_customer_v1_api_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.GetStoreResponse>;

  getStore(
    request: canary_cloud_customer_v1_api_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.GetStoreResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.GetStoreResponse>;

  getStore(
    request: canary_cloud_customer_v1_api_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.GetStoreResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/GetStore',
        request,
        metadata || {},
        this.methodInfoGetStore,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/GetStore',
    request,
    metadata || {},
    this.methodInfoGetStore);
  }

  methodInfoCustomerBukkenPageView = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.CustomerBukkenPageViewResponse,
    (request: canary_cloud_customer_v1_api_pb.CustomerBukkenPageViewRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.CustomerBukkenPageViewResponse.deserializeBinary
  );

  customerBukkenPageView(
    request: canary_cloud_customer_v1_api_pb.CustomerBukkenPageViewRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.CustomerBukkenPageViewResponse>;

  customerBukkenPageView(
    request: canary_cloud_customer_v1_api_pb.CustomerBukkenPageViewRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.CustomerBukkenPageViewResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.CustomerBukkenPageViewResponse>;

  customerBukkenPageView(
    request: canary_cloud_customer_v1_api_pb.CustomerBukkenPageViewRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.CustomerBukkenPageViewResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/CustomerBukkenPageView',
        request,
        metadata || {},
        this.methodInfoCustomerBukkenPageView,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/CustomerBukkenPageView',
    request,
    metadata || {},
    this.methodInfoCustomerBukkenPageView);
  }

  methodInfoCreateStoreVisitAppointmentFormPageView = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.CreateStoreVisitAppointmentFormPageViewResponse,
    (request: canary_cloud_customer_v1_api_pb.EmptyRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.CreateStoreVisitAppointmentFormPageViewResponse.deserializeBinary
  );

  createStoreVisitAppointmentFormPageView(
    request: canary_cloud_customer_v1_api_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.CreateStoreVisitAppointmentFormPageViewResponse>;

  createStoreVisitAppointmentFormPageView(
    request: canary_cloud_customer_v1_api_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.CreateStoreVisitAppointmentFormPageViewResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.CreateStoreVisitAppointmentFormPageViewResponse>;

  createStoreVisitAppointmentFormPageView(
    request: canary_cloud_customer_v1_api_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.CreateStoreVisitAppointmentFormPageViewResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/CreateStoreVisitAppointmentFormPageView',
        request,
        metadata || {},
        this.methodInfoCreateStoreVisitAppointmentFormPageView,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/CreateStoreVisitAppointmentFormPageView',
    request,
    metadata || {},
    this.methodInfoCreateStoreVisitAppointmentFormPageView);
  }

  methodInfoCreateStoreVisitAppointment = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.EmptyResponse,
    (request: canary_cloud_customer_v1_api_pb.CreateStoreVisitAppointmentRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.EmptyResponse.deserializeBinary
  );

  createStoreVisitAppointment(
    request: canary_cloud_customer_v1_api_pb.CreateStoreVisitAppointmentRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.EmptyResponse>;

  createStoreVisitAppointment(
    request: canary_cloud_customer_v1_api_pb.CreateStoreVisitAppointmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.EmptyResponse>;

  createStoreVisitAppointment(
    request: canary_cloud_customer_v1_api_pb.CreateStoreVisitAppointmentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/CreateStoreVisitAppointment',
        request,
        metadata || {},
        this.methodInfoCreateStoreVisitAppointment,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/CreateStoreVisitAppointment',
    request,
    metadata || {},
    this.methodInfoCreateStoreVisitAppointment);
  }

  methodInfoRejectAutoButsudashiPageView = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.RejectAutoButsudashiPageViewResponse,
    (request: canary_cloud_customer_v1_api_pb.EmptyRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.RejectAutoButsudashiPageViewResponse.deserializeBinary
  );

  rejectAutoButsudashiPageView(
    request: canary_cloud_customer_v1_api_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.RejectAutoButsudashiPageViewResponse>;

  rejectAutoButsudashiPageView(
    request: canary_cloud_customer_v1_api_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.RejectAutoButsudashiPageViewResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.RejectAutoButsudashiPageViewResponse>;

  rejectAutoButsudashiPageView(
    request: canary_cloud_customer_v1_api_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.RejectAutoButsudashiPageViewResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/RejectAutoButsudashiPageView',
        request,
        metadata || {},
        this.methodInfoRejectAutoButsudashiPageView,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/RejectAutoButsudashiPageView',
    request,
    metadata || {},
    this.methodInfoRejectAutoButsudashiPageView);
  }

  methodInfoRejectAutoButsudashi = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.EmptyResponse,
    (request: canary_cloud_customer_v1_api_pb.RejectAutoButsudashiRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.EmptyResponse.deserializeBinary
  );

  rejectAutoButsudashi(
    request: canary_cloud_customer_v1_api_pb.RejectAutoButsudashiRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.EmptyResponse>;

  rejectAutoButsudashi(
    request: canary_cloud_customer_v1_api_pb.RejectAutoButsudashiRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.EmptyResponse>;

  rejectAutoButsudashi(
    request: canary_cloud_customer_v1_api_pb.RejectAutoButsudashiRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/RejectAutoButsudashi',
        request,
        metadata || {},
        this.methodInfoRejectAutoButsudashi,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/RejectAutoButsudashi',
    request,
    metadata || {},
    this.methodInfoRejectAutoButsudashi);
  }

  methodInfoRestartDelivery = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.RestartDeliveryResponse,
    (request: canary_cloud_customer_v1_api_pb.RestartDeliveryRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.RestartDeliveryResponse.deserializeBinary
  );

  restartDelivery(
    request: canary_cloud_customer_v1_api_pb.RestartDeliveryRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.RestartDeliveryResponse>;

  restartDelivery(
    request: canary_cloud_customer_v1_api_pb.RestartDeliveryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.RestartDeliveryResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.RestartDeliveryResponse>;

  restartDelivery(
    request: canary_cloud_customer_v1_api_pb.RestartDeliveryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.RestartDeliveryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/RestartDelivery',
        request,
        metadata || {},
        this.methodInfoRestartDelivery,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/RestartDelivery',
    request,
    metadata || {},
    this.methodInfoRestartDelivery);
  }

  methodInfoAddLineFriendPageView = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.AddLineFriendPageViewResponse,
    (request: canary_cloud_customer_v1_api_pb.EmptyRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.AddLineFriendPageViewResponse.deserializeBinary
  );

  addLineFriendPageView(
    request: canary_cloud_customer_v1_api_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.AddLineFriendPageViewResponse>;

  addLineFriendPageView(
    request: canary_cloud_customer_v1_api_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.AddLineFriendPageViewResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.AddLineFriendPageViewResponse>;

  addLineFriendPageView(
    request: canary_cloud_customer_v1_api_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.AddLineFriendPageViewResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/AddLineFriendPageView',
        request,
        metadata || {},
        this.methodInfoAddLineFriendPageView,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/AddLineFriendPageView',
    request,
    metadata || {},
    this.methodInfoAddLineFriendPageView);
  }

  methodInfoCreateCustomerFromLINE = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.CreateCustomerFromLINEResponse,
    (request: canary_cloud_customer_v1_api_pb.CreateCustomerFromLINERequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.CreateCustomerFromLINEResponse.deserializeBinary
  );

  createCustomerFromLINE(
    request: canary_cloud_customer_v1_api_pb.CreateCustomerFromLINERequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.CreateCustomerFromLINEResponse>;

  createCustomerFromLINE(
    request: canary_cloud_customer_v1_api_pb.CreateCustomerFromLINERequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.CreateCustomerFromLINEResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.CreateCustomerFromLINEResponse>;

  createCustomerFromLINE(
    request: canary_cloud_customer_v1_api_pb.CreateCustomerFromLINERequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.CreateCustomerFromLINEResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/CreateCustomerFromLINE',
        request,
        metadata || {},
        this.methodInfoCreateCustomerFromLINE,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/CreateCustomerFromLINE',
    request,
    metadata || {},
    this.methodInfoCreateCustomerFromLINE);
  }

  methodInfoGetSurveySetting = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.GetSurveySettingResponse,
    (request: canary_cloud_customer_v1_api_pb.GetSurveySettingRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.GetSurveySettingResponse.deserializeBinary
  );

  getSurveySetting(
    request: canary_cloud_customer_v1_api_pb.GetSurveySettingRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.GetSurveySettingResponse>;

  getSurveySetting(
    request: canary_cloud_customer_v1_api_pb.GetSurveySettingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.GetSurveySettingResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.GetSurveySettingResponse>;

  getSurveySetting(
    request: canary_cloud_customer_v1_api_pb.GetSurveySettingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.GetSurveySettingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/GetSurveySetting',
        request,
        metadata || {},
        this.methodInfoGetSurveySetting,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/GetSurveySetting',
    request,
    metadata || {},
    this.methodInfoGetSurveySetting);
  }

  methodInfoSubmitSurvey = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.SubmitSurveyResponse,
    (request: canary_cloud_customer_v1_api_pb.SubmitSurveyRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.SubmitSurveyResponse.deserializeBinary
  );

  submitSurvey(
    request: canary_cloud_customer_v1_api_pb.SubmitSurveyRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.SubmitSurveyResponse>;

  submitSurvey(
    request: canary_cloud_customer_v1_api_pb.SubmitSurveyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.SubmitSurveyResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.SubmitSurveyResponse>;

  submitSurvey(
    request: canary_cloud_customer_v1_api_pb.SubmitSurveyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.SubmitSurveyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/SubmitSurvey',
        request,
        metadata || {},
        this.methodInfoSubmitSurvey,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/SubmitSurvey',
    request,
    metadata || {},
    this.methodInfoSubmitSurvey);
  }

  methodInfoGetCondition = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.GetConditionResponse,
    (request: canary_cloud_customer_v1_api_pb.GetConditionRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.GetConditionResponse.deserializeBinary
  );

  getCondition(
    request: canary_cloud_customer_v1_api_pb.GetConditionRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.GetConditionResponse>;

  getCondition(
    request: canary_cloud_customer_v1_api_pb.GetConditionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.GetConditionResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.GetConditionResponse>;

  getCondition(
    request: canary_cloud_customer_v1_api_pb.GetConditionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.GetConditionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/GetCondition',
        request,
        metadata || {},
        this.methodInfoGetCondition,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/GetCondition',
    request,
    metadata || {},
    this.methodInfoGetCondition);
  }

  methodInfoUpdateCondition = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.UpdateConditionResponse,
    (request: canary_cloud_customer_v1_api_pb.UpdateConditionRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.UpdateConditionResponse.deserializeBinary
  );

  updateCondition(
    request: canary_cloud_customer_v1_api_pb.UpdateConditionRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.UpdateConditionResponse>;

  updateCondition(
    request: canary_cloud_customer_v1_api_pb.UpdateConditionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.UpdateConditionResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.UpdateConditionResponse>;

  updateCondition(
    request: canary_cloud_customer_v1_api_pb.UpdateConditionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.UpdateConditionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/UpdateCondition',
        request,
        metadata || {},
        this.methodInfoUpdateCondition,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/UpdateCondition',
    request,
    metadata || {},
    this.methodInfoUpdateCondition);
  }

  methodInfoSearchStations = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.SearchStationsResponse,
    (request: canary_cloud_customer_v1_api_pb.SearchStationsRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.SearchStationsResponse.deserializeBinary
  );

  searchStations(
    request: canary_cloud_customer_v1_api_pb.SearchStationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.SearchStationsResponse>;

  searchStations(
    request: canary_cloud_customer_v1_api_pb.SearchStationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.SearchStationsResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.SearchStationsResponse>;

  searchStations(
    request: canary_cloud_customer_v1_api_pb.SearchStationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.SearchStationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/SearchStations',
        request,
        metadata || {},
        this.methodInfoSearchStations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/SearchStations',
    request,
    metadata || {},
    this.methodInfoSearchStations);
  }

  methodInfoSearchCities = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.SearchCitiesResponse,
    (request: canary_cloud_customer_v1_api_pb.SearchCitiesRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.SearchCitiesResponse.deserializeBinary
  );

  searchCities(
    request: canary_cloud_customer_v1_api_pb.SearchCitiesRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.SearchCitiesResponse>;

  searchCities(
    request: canary_cloud_customer_v1_api_pb.SearchCitiesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.SearchCitiesResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.SearchCitiesResponse>;

  searchCities(
    request: canary_cloud_customer_v1_api_pb.SearchCitiesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.SearchCitiesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/SearchCities',
        request,
        metadata || {},
        this.methodInfoSearchCities,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/SearchCities',
    request,
    metadata || {},
    this.methodInfoSearchCities);
  }

  methodInfoBatchGetPrefectures = new grpcWeb.AbstractClientBase.MethodInfo(
    canary_cloud_customer_v1_api_pb.BatchGetPrefecturesResponse,
    (request: canary_cloud_customer_v1_api_pb.BatchGetPrefecturesRequest) => {
      return request.serializeBinary();
    },
    canary_cloud_customer_v1_api_pb.BatchGetPrefecturesResponse.deserializeBinary
  );

  batchGetPrefectures(
    request: canary_cloud_customer_v1_api_pb.BatchGetPrefecturesRequest,
    metadata: grpcWeb.Metadata | null): Promise<canary_cloud_customer_v1_api_pb.BatchGetPrefecturesResponse>;

  batchGetPrefectures(
    request: canary_cloud_customer_v1_api_pb.BatchGetPrefecturesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.BatchGetPrefecturesResponse) => void): grpcWeb.ClientReadableStream<canary_cloud_customer_v1_api_pb.BatchGetPrefecturesResponse>;

  batchGetPrefectures(
    request: canary_cloud_customer_v1_api_pb.BatchGetPrefecturesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: canary_cloud_customer_v1_api_pb.BatchGetPrefecturesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/apis.proto.canary_cloud.customer.v1.ApiV1/BatchGetPrefectures',
        request,
        metadata || {},
        this.methodInfoBatchGetPrefectures,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/apis.proto.canary_cloud.customer.v1.ApiV1/BatchGetPrefectures',
    request,
    metadata || {},
    this.methodInfoBatchGetPrefectures);
  }

}

