import { useEffect } from "react";

type UserInfo = {
  name: string;
  organizationId: string;
};

/**
 * ユーザー情報が取得されたら実行し、GAの情報に載せる
 */
export const useAnalyticsUser = (
  userInfo: UserInfo,
  trackingId: string | undefined
): void => {
  useEffect(() => {
    if (window.gtag === undefined || trackingId === undefined) {
      return;
    }

    window.gtag("config", trackingId, {
      name: userInfo.name,
    });
    window.gtag("set", "user_properties", {
      organization_id: userInfo.organizationId,
      name: userInfo.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.name]);
};
