import { Button } from "components/button/Button";

import styles from "./CompletedView.module.scss";

type Props = {
  onClickDisplayedRejectAutoButsudashiForm: () => void;
};

export const CompletedRestartDeliveryView = ({
  onClickDisplayedRejectAutoButsudashiForm,
}: Props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>配信を再開しました</h1>

      <p className={styles.message}>
        メール配信を再開しました。
        <br />
        停止したい場合は以下のボタンから配信を停止できます。
      </p>

      <Button
        className={styles.button}
        type="button"
        onClick={onClickDisplayedRejectAutoButsudashiForm}
      >
        配信を停止する
      </Button>
    </div>
  );
};
