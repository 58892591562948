import type { CustomerBukkenPageViewResponse } from "../../../apis/proto/canary_cloud/customer/v1/api_pb";
import styles from "./CustomerBukkenOptions.module.scss";

const NO_CONTENT = "-";

type Props = {
  optionCategoriesList: CustomerBukkenPageViewResponse.CustomerBukken.AsObject["optionCategoriesList"];
};

export const CustomerBukkenOptions = (props: Props) => {
  return (
    <section className={styles.container}>
      <h1 className={styles.title}>お部屋の特徴</h1>
      <div className={styles.categories}>
        {props.optionCategoriesList.map((category) => {
          return (
            <div key={category.id} className={styles.category}>
              <h2 className={styles.categoryTitle}>{category.name}</h2>
              <p className={styles.categoryItems}>
                {category.optionsList.length !== 0
                  ? category.optionsList.map((option) => {
                      return <span key={option.id}>{option.name}</span>;
                    })
                  : NO_CONTENT}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};
