import {
  convertFloorString,
  convertLayoutString,
  convertMoneyString,
  convertMonthlyString,
  convertOldString,
  convertSquareString,
  convertStoryString,
  NO_CONTENT,
} from "util/converter/customerBukken";

import type { CustomerBukkenPageViewResponse } from "../../../apis/proto/canary_cloud/customer/v1/api_pb";
import styles from "./CustomerBukkenBase.module.scss";

type Props = {
  bukkenInfo: CustomerBukkenPageViewResponse.CustomerBukken.AsObject;
};

export const CustomerBukkenBase = (props: Props) => {
  return (
    <section className={styles.container}>
      <h1 className={styles.title}>{props.bukkenInfo.name}</h1>
      <p className={styles.price}>{convertMonthlyString(props.bukkenInfo)}</p>
      <dl className={styles.infoTable}>
        <div className={styles.data}>
          <dt className={styles.header}>敷 / 礼</dt>
          <dd className={styles.content}>
            {convertMoneyString(props.bukkenInfo.securityDeposit)} /{" "}
            {convertMoneyString(props.bukkenInfo.keyMoney)}
          </dd>
        </div>

        <div className={styles.data}>
          <dt className={styles.header}>間取り</dt>
          <dd className={styles.content}>
            {convertLayoutString(props.bukkenInfo)}
          </dd>
        </div>

        <div className={styles.data}>
          <dt className={styles.header}>広さ</dt>
          <dd className={styles.content}>
            {convertSquareString(props.bukkenInfo)}
          </dd>
        </div>

        <div className={styles.data}>
          <dt className={styles.header}>向き</dt>
          <dd className={styles.content}>
            {props.bukkenInfo.direction?.name || NO_CONTENT}
          </dd>
        </div>

        <div className={styles.data}>
          <dt className={styles.header}>階数</dt>
          <dd className={styles.content}>
            {props.bukkenInfo.floor ? (
              <>
                <span>{convertFloorString(props.bukkenInfo)}</span>
                <span>{convertStoryString(props.bukkenInfo)}</span>
              </>
            ) : (
              NO_CONTENT
            )}
          </dd>
        </div>

        <div className={styles.data}>
          <dt className={styles.header}>築年数</dt>
          <dd className={styles.content}>
            {convertOldString(props.bukkenInfo)}
          </dd>
        </div>

        <div className={styles.data}>
          <dt className={styles.header}>建物</dt>
          <dd className={styles.content}>
            {props.bukkenInfo.buildingType?.name || NO_CONTENT}
          </dd>
        </div>

        <div className={styles.data}>
          <dt className={styles.header}>交通</dt>
          <div className={styles.contentList}>
            {props.bukkenInfo.trainAccessesList.length !== 0
              ? props.bukkenInfo.trainAccessesList.map((access, index) => {
                  return (
                    <dd key={index} className={styles.content}>
                      {access.line && <span>{access.line.name}</span>}
                      {access.station && <span>{access.station.name}</span>}
                      <span>徒歩{access.during}分</span>
                    </dd>
                  );
                })
              : NO_CONTENT}
          </div>
        </div>

        <div className={styles.data}>
          <dt className={styles.header}>住所</dt>
          <dd className={styles.content}>
            {props.bukkenInfo.address || NO_CONTENT}
          </dd>
        </div>
      </dl>
    </section>
  );
};
