export const SUNDAY = "sunday";
export const MONDAY = "monday";
export const TUESDAY = "tuesday";
export const WEDNESDAY = "wednesday";
export const THURSDAY = "thursday";
export const FRIDAY = "friday";
export const SATURDAY = "saturday";

export const WeekDay = {
  [SUNDAY]: 0,
  [MONDAY]: 1,
  [TUESDAY]: 2,
  [WEDNESDAY]: 3,
  [THURSDAY]: 4,
  [FRIDAY]: 5,
  [SATURDAY]: 6,
} as const;

export type WeekDayID = keyof typeof WeekDay;

export const isWeekDayID = (id: string): id is WeekDayID =>
  Object.keys(WeekDay).includes(id);
