import { ReactComponent as Alert } from "assets/icons/Alert.svg";
import classNames from "classnames";
import type { ReactNode } from "react";

import styles from "./FormField.module.scss";
import { FormLabel } from "./FormLabel";

type FormFieldProps = {
  children: ReactNode;
  classNames?: {
    container?: string;
  };
  id?: string;
  isRequired?: boolean;
  label?: string;
};

export const FormField = (props: FormFieldProps) => {
  const {
    id,
    children,
    label,
    isRequired = false,
    classNames: overrideClassNames,
  } = props;

  const innerContent = (
    <>
      {label && (
        <div className={styles.labelContainer}>
          <span className={styles.label}>{label}</span>
          {isRequired && <FormLabel type="require" />}
        </div>
      )}
      {children}
    </>
  );

  if (id) {
    return (
      <label
        htmlFor={id}
        className={classNames(
          styles.container,
          styles.pointer,
          overrideClassNames?.container
        )}
      >
        {innerContent}
      </label>
    );
  }

  return (
    <div
      className={classNames(styles.container, overrideClassNames?.container)}
    >
      {innerContent}
    </div>
  );
};

type FormFieldMessageProps = {
  children?: string;
  classNames?: {
    container?: string;
    message?: string;
  };
};

const ErrorMessage = (props: FormFieldMessageProps) => {
  const { children, classNames: overrideClassNames } = props;

  if (!children) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.errorContainer,
        overrideClassNames?.container
      )}
    >
      <Alert className={styles.alertIcon} />
      <p className={classNames(styles.error, overrideClassNames?.message)}>
        {children}
      </p>
    </div>
  );
};

const InfoMessage = (props: FormFieldMessageProps) => {
  const { children, classNames: overrideClassNames } = props;

  if (!children) {
    return null;
  }

  return (
    <p className={classNames(styles.info, overrideClassNames?.message)}>
      {children}
    </p>
  );
};

FormField.Error = ErrorMessage;
FormField.Info = InfoMessage;
