import { logError } from "./util/error/logError";
import { getLocalStorageItem } from "./util/localStorageUtils";

const REVISION_KEY = "REVISION_KEY";

type Revision = {
  version: string;
};

export const checkRevision = (): void => {
  const hash = Date.now();
  fetch(`/revision.json?${hash}`)
    .then((res) => res.json())
    .then((revision) => {
      const prevRevision: Revision | null = getLocalStorageItem(REVISION_KEY);

      if (!prevRevision?.version) {
        localStorage.setItem(REVISION_KEY, JSON.stringify(revision));
        return;
      }

      if (revision?.version && prevRevision.version !== revision.version) {
        localStorage.setItem(REVISION_KEY, JSON.stringify(revision));
        location.reload();
        return;
      }
    })
    .catch(logError);
};

export const getCurrentRevision = (): Revision | null => {
  const prevRevision = getLocalStorageItem<Revision>(REVISION_KEY);
  if (!prevRevision) {
    return null;
  }
  return prevRevision;
};
