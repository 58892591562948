import type { SuspenseProps } from "react";
import { Suspense } from "react";

import { PageLoading } from "./PageLoading";

export const SuspensePage = (
  props: SuspenseProps & {
    fullscreen?: boolean;
  }
) => {
  const { fallback, fullscreen = true, children } = props;
  const fallbackComponent = fallback || <PageLoading fullscreen={fullscreen} />;
  return <Suspense fallback={fallbackComponent}>{children}</Suspense>;
};
