import { ReactComponent as HandIcon } from "assets/icons/Hand.svg";
import { LinkButton } from "components/button/LinkButton";
import { CUSTOMER_BUKKEN_ID_QUERY_PARAM_KEY } from "pages/StoreVisitAppointFormPage/hooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import type { CustomerBukkenPageViewResponse } from "../../apis/proto/canary_cloud/customer/v1/api_pb";
import { CustomerBukkenPageViewRequest } from "../../apis/proto/canary_cloud/customer/v1/api_pb";
import { ApiV1Client } from "../../apis/proto/canary_cloud/customer/v1/ApiServiceClientPb";
import { Head } from "../../components/info/Head";
import { PageLoading } from "../../components/loading/PageLoading";
import { AuthContext } from "../../context/AuthProvider";
import { Container } from "../../foundations/Container";
import { checkErrorType, isGrpcError } from "../../util/error/error";
import { logError } from "../../util/error/logError";
import type { APIBaseResponseType } from "../../util/utilTypes";
import { ErrorPage } from "../Error/Error";
import styles from "./CustomerBukken.module.scss";
import { CustomerBukkenBase } from "./modules/CustomerBukkenBase";
import { CustomerBukkenOptions } from "./modules/CustomerBukkenOptions";
import { CustomerBukkenPopularOptions } from "./modules/CustomerBukkenPopularOptions";
import { ImageSlider } from "./modules/ImageSlider";
import { RecruitEndBar } from "./modules/RecruitEndBar";

const getCustomerBukkenPageView = async (
  token: string,
  organizationID: string,
  customerBukkenID: string
): Promise<CustomerBukkenPageViewResponse.AsObject> => {
  const client = new ApiV1Client(
    process.env["REACT_APP_API_BASE_URL"] as string
  );
  const req = new CustomerBukkenPageViewRequest();
  req.setCustomerBukkenId(customerBukkenID);
  const res = await client.customerBukkenPageView(req, {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return res.toObject();
};

type CustomerBukkenType = APIBaseResponseType<
  NonNullable<CustomerBukkenPageViewResponse.AsObject["customerBukken"]>
>;

export const CustomerBukken = () => {
  const [customerBukken, setCustomerBukken] = useState<CustomerBukkenType>({
    state: "initial",
    data: null,
  });
  const context = useContext(AuthContext);
  const { customerBukkenId } = useParams<{ customerBukkenId: string }>();

  useEffect(() => {
    if (context.state === "success") {
      (async () => {
        try {
          setCustomerBukken({
            state: "loading",
            data: null,
          });
          const res = await getCustomerBukkenPageView(
            context.data.token,
            context.data.organizationID,
            customerBukkenId ?? ""
          );
          if (!res.customerBukken) {
            throw new Error("customer bukken is not found");
          }
          setCustomerBukken({
            state: "success",
            data: res.customerBukken,
          });
        } catch (e) {
          logError(e);

          if (isGrpcError(e)) {
            const errorType = checkErrorType(e.code);
            setCustomerBukken({
              state: "failed",
              data: {
                errorCode: errorType,
                error: new Error(JSON.stringify(e)),
              },
            });
          } else {
            setCustomerBukken({
              state: "failed",
              data: {
                errorCode: "other",
                error: new Error(JSON.stringify(e)),
              },
            });
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    customerBukken.state === "loading" ||
    customerBukken.state === "initial"
  ) {
    return <PageLoading fullscreen />;
  }

  if (customerBukken.state === "failed") {
    return <ErrorPage error={customerBukken.data.errorCode} />;
  }

  const { isOpen, imagesList, popularOptionsList, optionCategoriesList } =
    customerBukken.data;

  return (
    <>
      {context.state === "success" && <Head title={context.data.name} />}
      {!isOpen && <RecruitEndBar />}
      <Container>
        <div className={styles.container}>
          <div className={styles.main}>
            <div className={styles.slider}>
              <ImageSlider imagesList={imagesList} />
            </div>

            <div className={styles.button}>
              {context.state === "success" &&
                context.data.isEnableStoreVisitAppointment && (
                  <div className={styles.storeVisitAppointmentLinkButton}>
                    <LinkButton
                      href={createStoreVisitHref(
                        context.data.organizationID,
                        customerBukken.data.id
                      )}
                    >
                      <HandIcon style={{ marginRight: 10 }} />
                      <span>内見・来店したい</span>
                    </LinkButton>
                  </div>
                )}
            </div>

            <div className={styles.info}>
              <CustomerBukkenBase bukkenInfo={customerBukken.data} />
            </div>
          </div>

          <CustomerBukkenPopularOptions
            popularOptionsList={popularOptionsList}
          />
          <CustomerBukkenOptions optionCategoriesList={optionCategoriesList} />

          {context.state === "success" &&
            context.data.isEnableStoreVisitAppointment && (
              <div className={styles.storeVisitAppointmentLinkButton}>
                <LinkButton
                  href={createStoreVisitHref(
                    context.data.organizationID,
                    customerBukken.data.id
                  )}
                >
                  <HandIcon style={{ marginRight: 10 }} />
                  <span>内見・来店したい</span>
                </LinkButton>
              </div>
            )}
        </div>
      </Container>
    </>
  );
};

const createStoreVisitHref = (
  organizationID: string,
  customerBukkenId: string
) => {
  const searchParams = new URLSearchParams();
  searchParams.set(CUSTOMER_BUKKEN_ID_QUERY_PARAM_KEY, customerBukkenId);

  return `/${organizationID}/store-visit-appointment?${searchParams.toString()}`;
};
