// source: canary_cloud/customer/v1/mapping.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.Area', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.Chomei', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.Chomeis', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.City', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.Line', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.Prefecture', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.Route', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.Station', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.StationAndLine', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.Chomei, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.Chomei.displayName = 'proto.apis.proto.canary_cloud.customer.v1.Chomei';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.Chomeis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.Chomeis.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.Chomeis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.Chomeis.displayName = 'proto.apis.proto.canary_cloud.customer.v1.Chomeis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.City = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.City.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.City, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.City.displayName = 'proto.apis.proto.canary_cloud.customer.v1.City';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.StationAndLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.StationAndLine.displayName = 'proto.apis.proto.canary_cloud.customer.v1.StationAndLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.displayName = 'proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.displayName = 'proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.Prefecture = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.Prefecture, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.Prefecture.displayName = 'proto.apis.proto.canary_cloud.customer.v1.Prefecture';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.Route = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.Route.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.Route, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.Route.displayName = 'proto.apis.proto.canary_cloud.customer.v1.Route';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.Line = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.Line.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.Line, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.Line.displayName = 'proto.apis.proto.canary_cloud.customer.v1.Line';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.Station = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.Station, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.Station.displayName = 'proto.apis.proto.canary_cloud.customer.v1.Station';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.Area = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.Area.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.Area, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.Area.displayName = 'proto.apis.proto.canary_cloud.customer.v1.Area';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.Chomei.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Chomei} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei.toObject = function(includeInstance, msg) {
  var f, obj = {
    cityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chomeiId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    chomei: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Chomei}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.Chomei;
  return proto.apis.proto.canary_cloud.customer.v1.Chomei.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Chomei} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Chomei}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChomeiId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChomei(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.Chomei.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Chomei} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChomeiId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChomei();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string city_id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei.prototype.getCityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Chomei} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei.prototype.setCityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string chomei_id = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei.prototype.getChomeiId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Chomei} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei.prototype.setChomeiId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string chomei = 3;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei.prototype.getChomei = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Chomei} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Chomei.prototype.setChomei = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.Chomeis.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomeis.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.Chomeis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Chomeis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Chomeis.toObject = function(includeInstance, msg) {
  var f, obj = {
    chomeisList: jspb.Message.toObjectList(msg.getChomeisList(),
    proto.apis.proto.canary_cloud.customer.v1.Chomei.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Chomeis}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomeis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.Chomeis;
  return proto.apis.proto.canary_cloud.customer.v1.Chomeis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Chomeis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Chomeis}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomeis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apis.proto.canary_cloud.customer.v1.Chomei;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.Chomei.deserializeBinaryFromReader);
      msg.addChomeis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomeis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.Chomeis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Chomeis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Chomeis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChomeisList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.apis.proto.canary_cloud.customer.v1.Chomei.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Chomei chomeis = 1;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.Chomei>}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomeis.prototype.getChomeisList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.Chomei>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.Chomei, 1));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.Chomei>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Chomeis} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Chomeis.prototype.setChomeisList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Chomei=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Chomei}
 */
proto.apis.proto.canary_cloud.customer.v1.Chomeis.prototype.addChomeis = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.apis.proto.canary_cloud.customer.v1.Chomei, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Chomeis} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Chomeis.prototype.clearChomeisList = function() {
  return this.setChomeisList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.City.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.City.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.City.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.City} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.City.toObject = function(includeInstance, msg) {
  var f, obj = {
    cityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    chomeisList: jspb.Message.toObjectList(msg.getChomeisList(),
    proto.apis.proto.canary_cloud.customer.v1.Chomei.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.City}
 */
proto.apis.proto.canary_cloud.customer.v1.City.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.City;
  return proto.apis.proto.canary_cloud.customer.v1.City.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.City} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.City}
 */
proto.apis.proto.canary_cloud.customer.v1.City.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.apis.proto.canary_cloud.customer.v1.Chomei;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.Chomei.deserializeBinaryFromReader);
      msg.addChomeis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.City.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.City.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.City} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.City.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChomeisList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.apis.proto.canary_cloud.customer.v1.Chomei.serializeBinaryToWriter
    );
  }
};


/**
 * optional string city_id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.City.prototype.getCityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.City} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.City.prototype.setCityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.City.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.City} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.City.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Chomei chomeis = 3;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.Chomei>}
 */
proto.apis.proto.canary_cloud.customer.v1.City.prototype.getChomeisList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.Chomei>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.Chomei, 3));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.Chomei>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.City} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.City.prototype.setChomeisList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Chomei=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Chomei}
 */
proto.apis.proto.canary_cloud.customer.v1.City.prototype.addChomeis = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apis.proto.canary_cloud.customer.v1.Chomei, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.City} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.City.prototype.clearChomeisList = function() {
  return this.setChomeisList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.StationAndLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    station: (f = msg.getStation()) && proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.toObject(includeInstance, f),
    line: (f = msg.getLine()) && proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.StationAndLine;
  return proto.apis.proto.canary_cloud.customer.v1.StationAndLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.deserializeBinaryFromReader);
      msg.setStation(value);
      break;
    case 2:
      var value = new proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.deserializeBinaryFromReader);
      msg.setLine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.StationAndLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.serializeBinaryToWriter
    );
  }
  f = message.getLine();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station;
  return proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line;
  return proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Station station = 1;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.prototype.getStation = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station} */ (
    jspb.Message.getWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station, 1));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Station|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.prototype.setStation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.prototype.clearStation = function() {
  return this.setStation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.prototype.hasStation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Line line = 2;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.prototype.getLine = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line} */ (
    jspb.Message.getWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line, 2));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.StationAndLine.Line|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.prototype.setLine = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.prototype.clearLine = function() {
  return this.setLine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.StationAndLine.prototype.hasLine = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.Prefecture.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.Prefecture.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Prefecture} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Prefecture.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Prefecture}
 */
proto.apis.proto.canary_cloud.customer.v1.Prefecture.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.Prefecture;
  return proto.apis.proto.canary_cloud.customer.v1.Prefecture.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Prefecture} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Prefecture}
 */
proto.apis.proto.canary_cloud.customer.v1.Prefecture.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.Prefecture.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.Prefecture.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Prefecture} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Prefecture.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Prefecture.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Prefecture} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Prefecture.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Prefecture.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Prefecture} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Prefecture.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.Route.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.Route.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.Route.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Route} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Route.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linesList: jspb.Message.toObjectList(msg.getLinesList(),
    proto.apis.proto.canary_cloud.customer.v1.Line.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Route}
 */
proto.apis.proto.canary_cloud.customer.v1.Route.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.Route;
  return proto.apis.proto.canary_cloud.customer.v1.Route.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Route} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Route}
 */
proto.apis.proto.canary_cloud.customer.v1.Route.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.apis.proto.canary_cloud.customer.v1.Line;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.Line.deserializeBinaryFromReader);
      msg.addLines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.Route.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.Route.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Route} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Route.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.apis.proto.canary_cloud.customer.v1.Line.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Route.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Route} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Route.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Route.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Route} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Route.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Line lines = 3;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.Line>}
 */
proto.apis.proto.canary_cloud.customer.v1.Route.prototype.getLinesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.Line>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.Line, 3));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.Line>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Route} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Route.prototype.setLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Line=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Line}
 */
proto.apis.proto.canary_cloud.customer.v1.Route.prototype.addLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apis.proto.canary_cloud.customer.v1.Line, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Route} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Route.prototype.clearLinesList = function() {
  return this.setLinesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.Line.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.Line.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.Line.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Line} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Line.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stationsList: jspb.Message.toObjectList(msg.getStationsList(),
    proto.apis.proto.canary_cloud.customer.v1.Station.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Line}
 */
proto.apis.proto.canary_cloud.customer.v1.Line.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.Line;
  return proto.apis.proto.canary_cloud.customer.v1.Line.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Line} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Line}
 */
proto.apis.proto.canary_cloud.customer.v1.Line.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.apis.proto.canary_cloud.customer.v1.Station;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.Station.deserializeBinaryFromReader);
      msg.addStations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.Line.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.Line.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Line} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Line.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.apis.proto.canary_cloud.customer.v1.Station.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Line.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Line} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Line.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Line.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Line} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Line.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Station stations = 3;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.Station>}
 */
proto.apis.proto.canary_cloud.customer.v1.Line.prototype.getStationsList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.Station>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.Station, 3));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.Station>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Line} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Line.prototype.setStationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Station=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Station}
 */
proto.apis.proto.canary_cloud.customer.v1.Line.prototype.addStations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apis.proto.canary_cloud.customer.v1.Station, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Line} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Line.prototype.clearStationsList = function() {
  return this.setStationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.Station.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.Station.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Station} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Station.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Station}
 */
proto.apis.proto.canary_cloud.customer.v1.Station.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.Station;
  return proto.apis.proto.canary_cloud.customer.v1.Station.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Station} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Station}
 */
proto.apis.proto.canary_cloud.customer.v1.Station.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.Station.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.Station.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Station} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Station.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Station.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Station} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Station.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Station.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Station} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Station.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.Area.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.Area.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.Area.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Area} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Area.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    citiesList: jspb.Message.toObjectList(msg.getCitiesList(),
    proto.apis.proto.canary_cloud.customer.v1.City.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Area}
 */
proto.apis.proto.canary_cloud.customer.v1.Area.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.Area;
  return proto.apis.proto.canary_cloud.customer.v1.Area.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Area} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Area}
 */
proto.apis.proto.canary_cloud.customer.v1.Area.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.apis.proto.canary_cloud.customer.v1.City;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.City.deserializeBinaryFromReader);
      msg.addCities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.Area.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.Area.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Area} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Area.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.apis.proto.canary_cloud.customer.v1.City.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Area.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Area} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Area.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Area.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Area} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Area.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated City cities = 3;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.City>}
 */
proto.apis.proto.canary_cloud.customer.v1.Area.prototype.getCitiesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.City>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.City, 3));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.City>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Area} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Area.prototype.setCitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.City=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.City}
 */
proto.apis.proto.canary_cloud.customer.v1.Area.prototype.addCities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apis.proto.canary_cloud.customer.v1.City, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Area} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Area.prototype.clearCitiesList = function() {
  return this.setCitiesList([]);
};


goog.object.extend(exports, proto.apis.proto.canary_cloud.customer.v1);
