import {
  addDays as dateFnsAddDays,
  addHours as dateFnsAddHours,
  addMinutes as dateFnsAddMinutes,
  addSeconds as dateFnsAddSeconds,
  getDay as dateFnsGetDay,
  getUnixTime,
  isAfter as dateFnsIsAfter,
  isBefore as dateFnsIsBefore,
  isSameDay as dateFnsIsSameDay,
  isSameHour as dateFnsIsSameHour,
  isSameMinute as dateFnsIsSameMinute,
  isSameMonth as dateFnsIsSameMonth,
  isSameYear as dateFnsIsSameYear,
  isWithinInterval as dateFnsIsWithinInterval,
  parse as dateFnsParse,
  set,
  setHours as dateFnsSetHours,
  setMinutes as dateFnsSetMinutes,
  setSeconds as dateFnsSetSeconds,
} from "date-fns";

export const addDays = (date: Date, days: number): Date => {
  return dateFnsAddDays(date, days);
};

export const addMinutes = (date: Date, minutes: number): Date => {
  return dateFnsAddMinutes(date, minutes);
};

export const addHours = (date: Date, hours: number): Date => {
  return dateFnsAddHours(date, hours);
};

export const addSeconds = (date: Date, seconds: number): Date => {
  return dateFnsAddSeconds(date, seconds);
};

export const isAfter = (date: Date, dateToCompare: Date): boolean => {
  return dateFnsIsAfter(date, dateToCompare);
};

export const isBefore = (date: Date, dateToCompare: Date): boolean => {
  return dateFnsIsBefore(date, dateToCompare);
};

export const isSameDay = (date: Date, dateToCompare: Date): boolean => {
  return dateFnsIsSameDay(date, dateToCompare);
};

export const isSameHour = (date: Date, dateToCompare: Date): boolean => {
  return dateFnsIsSameHour(date, dateToCompare);
};

export const isSameMinute = (date: Date, dateToCompare: Date): boolean => {
  return dateFnsIsSameMinute(date, dateToCompare);
};

export const isSameMonth = (date: Date, dateToCompare: Date): boolean => {
  return dateFnsIsSameMonth(date, dateToCompare);
};

export const isSameYear = (date: Date, dateToCompare: Date): boolean => {
  return dateFnsIsSameYear(date, dateToCompare);
};

export const isWithinInterval = (
  date: Date,
  interval: { end: Date; start: Date }
): boolean => {
  return dateFnsIsWithinInterval(date, interval);
};

export const setHours = (date: Date, hours: number): Date => {
  return dateFnsSetHours(date, hours);
};

export const setMinutes = (date: Date, minutes: number): Date => {
  return dateFnsSetMinutes(date, minutes);
};

export const setSeconds = (date: Date, seconds: number): Date => {
  return dateFnsSetSeconds(date, seconds);
};

export const getDay = (date: Date): number => {
  return dateFnsGetDay(date);
};

/**
 * convert Epoch Unix Timestamp to Date object
 * @param utcSeconds Epoch Unix Timestamp (seconds) to convert
 * @returns Date
 */
export const secondToDate = (utcSeconds: number): Date => {
  const ms = utcSeconds * 1000;
  return new Date(ms);
};

/**
 * Date 型から UnixTime に変換する
 * @param date 日付
 * @param time 時間
 */
export const toUnixTimeFromDateTime = (args: {
  date: Date;
  time: Date;
}): number => {
  return getUnixTime(
    set(args.date, {
      hours: args.time.getHours(),
      minutes: args.time.getMinutes(),
    })
  );
};

/**
 * 日付文字列をDate型にパースする
 *
 * @param dateString 日付文字列
 * @param formatString フォーマット文字列 (例: 'yyyy-MM-dd')
 * @param referenceDate 基準日
 */
export const parseStringToDate = (args: {
  dateString: string;
  formatString: string;
  referenceDate: Date;
}): Date => {
  return dateFnsParse(args.dateString, args.formatString, args.referenceDate);
};

/**
 * 日付のみ初期化して（時間・分・秒）は0にする
 * @param {Date} date
 * @returns {Date}
 */
export const newOnlyDate = (date: Date): Date => {
  return setHours(setMinutes(setSeconds(date, 0), 0), 0);
};

export const isSameDateTime = (date: Date, dateToCompare: Date): boolean =>
  isSameYear(date, dateToCompare) &&
  isSameMonth(date, dateToCompare) &&
  isSameDay(date, dateToCompare) &&
  isSameHour(date, dateToCompare) &&
  isSameMinute(date, dateToCompare);
