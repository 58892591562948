import { zodResolver } from "@hookform/resolvers/zod";
import type {
  Chomei,
  Prefecture,
} from "apis/proto/canary_cloud/customer/v1/mapping_pb";
import classNames from "classnames";
import { Button } from "components/button/Button";
import { Collapse } from "components/collapse/Collapse";
import { Checkbox } from "components/form/Checkbox";
import { FormField } from "components/form/FormField";
import { Modal } from "components/modal/Modal";
import { WatchingFormValues } from "libs/react-hook-form/components/WatchigFormValues";
import type { MouseEvent } from "react";
import { useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import type { SearchCitiesResponse } from "usecase/city/city";
import { z } from "zod";

import { MAX_CHOMEI_COUNT, MAX_CITY_COUNT } from "./constant";
import styles from "./SelectModal.module.scss";

const onClickStopPropagation = (e: MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

const validationSchema = z.object({
  cityIdsList: z.array(z.string()).max(MAX_CITY_COUNT, {
    message: `市区郡は最大${MAX_CITY_COUNT}件までしか選択できません。`,
  }),
  chomeiIdsList: z.array(z.string()).max(MAX_CHOMEI_COUNT, {
    message: `町村は最大${MAX_CHOMEI_COUNT}件までしか選択できません。`,
  }),
});

export type CitiesAndCityIdsFormSchema = z.infer<typeof validationSchema>;

type SelectCityModalProps = {
  citiesByPrefecture: SearchCitiesResponse[];
  defaultValues: CitiesAndCityIdsFormSchema;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: CitiesAndCityIdsFormSchema) => void;
  prefecturesList: Prefecture.AsObject[];
};

export const SelectCityModal = (props: SelectCityModalProps) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    defaultValues,
    prefecturesList,
    citiesByPrefecture,
  } = props;

  const methods = useForm<CitiesAndCityIdsFormSchema>({
    mode: "onSubmit",
    defaultValues,
    resolver: zodResolver(validationSchema),
  });
  const {
    handleSubmit,
    reset,
    trigger,
    formState: { errors, isValid },
  } = methods;

  const handleSubmitEvent = (data: CitiesAndCityIdsFormSchema) => {
    onSubmit(data);
  };

  const onClickResetButton = () => {
    reset({
      cityIdsList: [],
      chomeiIdsList: [],
    });
  };

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
      trigger().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, isOpen, reset]);

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose}>
      <Modal.Header onClose={onClose}>市区町村を選択</Modal.Header>

      <FormProvider {...methods}>
        <form className={styles.formContainer}>
          {citiesByPrefecture.length === 1 ? (
            <AreaContainer
              isExpand
              isSinglePrefecture
              areasList={citiesByPrefecture[0].areasList}
              prefecture={prefecturesList[0]}
            />
          ) : (
            citiesByPrefecture.map((city, index) => (
              <Collapse
                key={prefecturesList[index].id}
                expand={false}
                label={prefecturesList[index].name}
                classNames={{
                  container: styles.prefectureContainer,
                  collapseLabel: styles.prefectureLabel,
                }}
              >
                {({ expanded }) =>
                  expanded && (
                    <AreaContainer
                      areasList={city.areasList}
                      isExpand={false}
                      isSinglePrefecture={false}
                      prefecture={prefecturesList[index]}
                    />
                  )
                }
              </Collapse>
            ))
          )}
        </form>
      </FormProvider>

      <FormField.Error
        classNames={{
          container: styles.errorContainer,
        }}
      >
        {errors.cityIdsList?.message || ""}
      </FormField.Error>
      <FormField.Error
        classNames={{
          container: styles.errorContainer,
        }}
      >
        {errors.chomeiIdsList?.message || ""}
      </FormField.Error>

      <Modal.Footer>
        <Button type="button" variant="subtle" onClick={onClickResetButton}>
          リセット
        </Button>
        <Button
          disabled={!isValid}
          type="button"
          onClick={handleSubmit(handleSubmitEvent)}
        >
          決定
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

type AreaContainerProps = {
  areasList: SearchCitiesResponse["areasList"];
  isExpand: boolean;
  isSinglePrefecture: boolean;
  prefecture: Prefecture.AsObject;
};

const AreaContainer = (props: AreaContainerProps) => {
  const { prefecture, areasList, isExpand, isSinglePrefecture } = props;
  const { control, setValue, trigger } =
    useFormContext<CitiesAndCityIdsFormSchema>();

  // エリアが1つの場合は、Collapseを使用せずに表示する
  if (areasList.length === 1) {
    return (
      <>
        {/* エリアが1つの場合は、都道府県名を表示する */}
        {isSinglePrefecture && (
          <p className={classNames(styles.prefectureLabel, styles.single)}>
            {prefecture.name}
          </p>
        )}
        <CityContainer citiesList={areasList[0].citiesList} isExpand={false} />
      </>
    );
  }

  const onChangeArea = async (
    isChecked = false,
    area: SearchCitiesResponse["areasList"][number],
    cityIdsList: string[] = []
  ) => {
    try {
      if (isChecked) {
        setValue(
          "cityIdsList",
          cityIdsList?.filter(
            (cityId) => !area.cityIdsListInThisArea.includes(cityId)
          ) ?? []
        );
        return;
      }
      setValue(
        "cityIdsList",
        Array.from(
          new Set([...(cityIdsList ?? []), ...area.cityIdsListInThisArea])
        )
      );
    } finally {
      await trigger();
    }
  };

  return (
    <>
      {areasList.map((area) => {
        return (
          <Collapse
            key={area?.id}
            expand={isExpand}
            label={area.name}
            classNames={{
              container: styles.areaContainer,
              collapseLabel: styles.areaLabel,
              collapse: styles.areaCollapse,
            }}
            leftElement={
              <WatchingFormValues control={control}>
                {({ cityIdsList }) => {
                  const selectedCityIdsListInThisArea =
                    cityIdsList?.filter((cityId) =>
                      area.cityIdsListInThisArea.includes(cityId)
                    ) ?? [];
                  const isChecked =
                    selectedCityIdsListInThisArea?.length ===
                    area.cityIdsListInThisArea.length;
                  return (
                    <Checkbox
                      key={area.id}
                      checked={isChecked}
                      id={area.id}
                      label=""
                      name=""
                      value=""
                      classNames={{
                        label: styles.checkboxLabel,
                      }}
                      onClick={onClickStopPropagation} // Collapseへイベント伝搬を止める
                      onChange={() =>
                        onChangeArea(isChecked, area, cityIdsList)
                      }
                    />
                  );
                }}
              </WatchingFormValues>
            }
          >
            {({ expanded }) =>
              expanded && (
                <CityContainer citiesList={area.citiesList} isExpand={false} />
              )
            }
          </Collapse>
        );
      })}
    </>
  );
};

type CityContainerProps = {
  citiesList: SearchCitiesResponse["areasList"][number]["citiesList"];
  isExpand: boolean;
};

const CityContainer = (props: CityContainerProps) => {
  const { citiesList, isExpand } = props;
  const { control, setValue, trigger } =
    useFormContext<CitiesAndCityIdsFormSchema>();

  const onChangeCity = async (
    isChecked = false,
    city: SearchCitiesResponse["areasList"][number]["citiesList"][number],
    cityIdsList: string[] = []
  ) => {
    try {
      if (isChecked) {
        setValue(
          "cityIdsList",
          cityIdsList?.filter((cityId) => cityId !== city.cityId) ?? []
        );
        setValue(
          "chomeiIdsList",
          cityIdsList?.filter(
            (cityId) => !city.chomeiIdsListInThisCity.includes(cityId)
          ) ?? []
        );
        return;
      }
      setValue(
        "cityIdsList",
        Array.from(new Set([...(cityIdsList ?? []), city.cityId]))
      );
    } finally {
      await trigger();
    }
  };

  return (
    <>
      {citiesList.map((city) => {
        // chomeisListを持たない場合は、市区郡名のみ表示する
        if (city.chomeisList.length === 0) {
          return (
            <WatchingFormValues key={city.cityId} control={control}>
              {({ cityIdsList }) => {
                const isChecked = cityIdsList?.includes(city.cityId);
                return (
                  <Checkbox
                    key={city.cityId}
                    checked={isChecked}
                    id={city.cityId}
                    label={city.name}
                    name=""
                    value=""
                    classNames={{
                      container: styles.cityCheckboxContainer,
                      label: styles.checkboxLabel,
                    }}
                    onChange={() => onChangeCity(isChecked, city, cityIdsList)}
                    onClick={onClickStopPropagation} // Collapseへイベント伝搬を止める
                  />
                );
              }}
            </WatchingFormValues>
          );
        }

        return (
          <Collapse
            key={city.cityId}
            expand={isExpand}
            label={city.name}
            classNames={{
              collapseControl: styles.cityCollapseControl,
              collapse: styles.cityCollapse,
              collapseLabel: styles.cityLabel,
              container: styles.cityContainer,
            }}
            leftElement={
              <WatchingFormValues control={control}>
                {({ cityIdsList }) => {
                  const isChecked = cityIdsList?.includes(city.cityId);
                  return (
                    <Checkbox
                      key={city.cityId}
                      checked={isChecked}
                      id={city.cityId}
                      label=""
                      name=""
                      value=""
                      classNames={{
                        label: styles.checkboxLabel,
                      }}
                      onClick={onClickStopPropagation} // Collapseへイベント伝搬を止める
                      onChange={() =>
                        onChangeCity(isChecked, city, cityIdsList)
                      }
                    />
                  );
                }}
              </WatchingFormValues>
            }
          >
            {({ expanded }) =>
              expanded && (
                <ChomeiContainer
                  chomeiIdsInThisCity={city.chomeiIdsListInThisCity}
                  chomeiList={city.chomeisList}
                />
              )
            }
          </Collapse>
        );
      })}
    </>
  );
};

type ChomeiContainerProps = {
  chomeiIdsInThisCity: SearchCitiesResponse["areasList"][number]["citiesList"][number]["chomeiIdsListInThisCity"];
  chomeiList: SearchCitiesResponse["areasList"][number]["citiesList"][number]["chomeisList"];
};

const ChomeiContainer = (props: ChomeiContainerProps) => {
  const { chomeiList, chomeiIdsInThisCity } = props;
  const { control, setValue, trigger } =
    useFormContext<CitiesAndCityIdsFormSchema>();

  const onChangeChomei = async (
    isChecked = false,
    chomei: Chomei.AsObject,
    cityIdsList: string[] = [],
    chomeiIdsList: string[] = []
  ) => {
    try {
      if (isChecked) {
        setValue(
          "chomeiIdsList",
          chomeiIdsList?.filter((chomeiId) => chomeiId !== chomei.chomeiId) ??
            []
        );
        const selectedChomeiIds = chomeiIdsInThisCity.filter((chomeiId) =>
          chomeiIdsList?.includes(chomeiId)
        );
        if (selectedChomeiIds.length === 1) {
          setValue(
            "cityIdsList",
            cityIdsList?.filter((cityId) => cityId !== chomei.cityId) ?? []
          );
        }
        return;
      }
      setValue(
        "chomeiIdsList",
        Array.from(new Set([...(chomeiIdsList ?? []), chomei.chomeiId]))
      );
      setValue(
        "cityIdsList",
        Array.from(new Set([...(cityIdsList ?? []), chomei.cityId]))
      );
    } finally {
      await trigger();
    }
  };

  return (
    <>
      {chomeiList.map((chomei) => {
        return (
          // HACK:shouldUnregister={false}の指定ができなかったので、暫定的に制御コンポーネントとして扱う
          <WatchingFormValues key={chomei.chomeiId} control={control}>
            {({ chomeiIdsList, cityIdsList }) => {
              const isChecked = chomeiIdsList?.includes(chomei.chomeiId);
              return (
                <Checkbox
                  key={chomei.chomeiId}
                  checked={isChecked}
                  id={chomei.chomeiId}
                  label={chomei.chomei}
                  name=""
                  value=""
                  classNames={{
                    label: styles.checkboxLabel,
                    container: styles.chomeiCheckboxContainer,
                  }}
                  onClick={onClickStopPropagation} // Collapseへイベント伝搬を止める
                  onChange={() =>
                    onChangeChomei(
                      isChecked,
                      chomei,
                      cityIdsList,
                      chomeiIdsList
                    )
                  }
                />
              );
            }}
          </WatchingFormValues>
        );
      })}
    </>
  );
};
