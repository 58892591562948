// source: canary_cloud/customer/v1/api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var canary_cloud_customer_v1_auth_code_pb = require('../../../canary_cloud/customer/v1/auth_code_pb.js');
goog.object.extend(proto, canary_cloud_customer_v1_auth_code_pb);
var canary_cloud_customer_v1_condition_pb = require('../../../canary_cloud/customer/v1/condition_pb.js');
goog.object.extend(proto, canary_cloud_customer_v1_condition_pb);
var canary_cloud_customer_v1_customer_pb = require('../../../canary_cloud/customer/v1/customer_pb.js');
goog.object.extend(proto, canary_cloud_customer_v1_customer_pb);
var canary_cloud_customer_v1_lib_pb = require('../../../canary_cloud/customer/v1/lib_pb.js');
goog.object.extend(proto, canary_cloud_customer_v1_lib_pb);
var canary_cloud_customer_v1_mapping_pb = require('../../../canary_cloud/customer/v1/mapping_pb.js');
goog.object.extend(proto, canary_cloud_customer_v1_mapping_pb);
var canary_cloud_customer_v1_process_pb = require('../../../canary_cloud/customer/v1/process_pb.js');
goog.object.extend(proto, canary_cloud_customer_v1_process_pb);
var canary_cloud_customer_v1_survey_setting_pb = require('../../../canary_cloud/customer/v1/survey_setting_pb.js');
goog.object.extend(proto, canary_cloud_customer_v1_survey_setting_pb);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.EmptyRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.EmptyResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.EmptyRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.EmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.EmptyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.EmptyResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.EmptyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.displayName = 'proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.displayName = 'proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.displayName = 'proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.displayName = 'proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.displayName = 'proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.displayName = 'proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.EmptyRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.EmptyRequest;
  return proto.apis.proto.canary_cloud.customer.v1.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.EmptyRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.EmptyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.EmptyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.EmptyResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.EmptyResponse;
  return proto.apis.proto.canary_cloud.customer.v1.EmptyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.EmptyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.EmptyResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.EmptyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.EmptyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.EmptyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest;
  return proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse;
  return proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.GetTokenResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isEnableStoreVisitAppoint: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse;
  return proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnableStoreVisitAppoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsEnableStoreVisitAppoint();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_enable_store_visit_appoint = 2;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.prototype.getIsEnableStoreVisitAppoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.GetStoreResponse.prototype.setIsEnableStoreVisitAppoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerBukkenId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerBukkenId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerBukkenId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_bukken_id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest.prototype.getCustomerBukkenId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewRequest.prototype.setCustomerBukkenId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerBukken: (f = msg.getCustomerBukken()) && proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.deserializeBinaryFromReader);
      msg.setCustomerBukken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerBukken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.repeatedFields_ = [2,16,17,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rent: jspb.Message.getFieldWithDefault(msg, 4, 0),
    adminFee: jspb.Message.getFieldWithDefault(msg, 5, 0),
    securityDeposit: jspb.Message.getFieldWithDefault(msg, 6, 0),
    keyMoney: jspb.Message.getFieldWithDefault(msg, 7, 0),
    layout: (f = msg.getLayout()) && proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.toObject(includeInstance, f),
    square: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    direction: (f = msg.getDirection()) && proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.toObject(includeInstance, f),
    floor: jspb.Message.getFieldWithDefault(msg, 11, 0),
    aboveGroundStory: jspb.Message.getFieldWithDefault(msg, 12, 0),
    underGroundStory: jspb.Message.getFieldWithDefault(msg, 13, 0),
    old: jspb.Message.getFieldWithDefault(msg, 14, 0),
    buildingType: (f = msg.getBuildingType()) && proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.toObject(includeInstance, f),
    trainAccessesList: jspb.Message.toObjectList(msg.getTrainAccessesList(),
    proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.toObject, includeInstance),
    popularOptionsList: jspb.Message.toObjectList(msg.getPopularOptionsList(),
    proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.toObject, includeInstance),
    optionCategoriesList: jspb.Message.toObjectList(msg.getOptionCategoriesList(),
    proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.toObject, includeInstance),
    isOpen: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    address: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAdminFee(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSecurityDeposit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKeyMoney(value);
      break;
    case 8:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.deserializeBinaryFromReader);
      msg.setLayout(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSquare(value);
      break;
    case 10:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.deserializeBinaryFromReader);
      msg.setDirection(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFloor(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAboveGroundStory(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnderGroundStory(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOld(value);
      break;
    case 15:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.deserializeBinaryFromReader);
      msg.setBuildingType(value);
      break;
    case 16:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.deserializeBinaryFromReader);
      msg.addTrainAccesses(value);
      break;
    case 17:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.deserializeBinaryFromReader);
      msg.addPopularOptions(value);
      break;
    case 18:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.deserializeBinaryFromReader);
      msg.addOptionCategories(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOpen(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRent();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAdminFee();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSecurityDeposit();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getKeyMoney();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getLayout();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.serializeBinaryToWriter
    );
  }
  f = message.getSquare();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getDirection();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.serializeBinaryToWriter
    );
  }
  f = message.getFloor();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getAboveGroundStory();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getUnderGroundStory();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getOld();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getBuildingType();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.serializeBinaryToWriter
    );
  }
  f = message.getTrainAccessesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.serializeBinaryToWriter
    );
  }
  f = message.getPopularOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.serializeBinaryToWriter
    );
  }
  f = message.getOptionCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.serializeBinaryToWriter
    );
  }
  f = message.getIsOpen();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    priority: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.prototype.setComment = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.prototype.clearComment = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.prototype.hasComment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 priority = 4;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.toObject = function(includeInstance, msg) {
  var f, obj = {
    aboveGround: jspb.Message.getFieldWithDefault(msg, 1, 0),
    underGround: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAboveGround(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnderGround(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAboveGround();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUnderGround();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 above_ground = 1;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.prototype.getAboveGround = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.prototype.setAboveGround = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 under_ground = 2;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.prototype.getUnderGround = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Story.prototype.setUnderGround = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.toObject = function(includeInstance, msg) {
  var f, obj = {
    line: (f = msg.getLine()) && proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.toObject(includeInstance, f),
    station: (f = msg.getStation()) && proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.toObject(includeInstance, f),
    during: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.deserializeBinaryFromReader);
      msg.setLine(value);
      break;
    case 2:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.deserializeBinaryFromReader);
      msg.setStation(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuring(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLine();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.serializeBinaryToWriter
    );
  }
  f = message.getStation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.serializeBinaryToWriter
    );
  }
  f = message.getDuring();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Route.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Line line = 1;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.prototype.getLine = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line} */ (
    jspb.Message.getWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line, 1));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Line|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.prototype.setLine = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.prototype.clearLine = function() {
  return this.setLine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.prototype.hasLine = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Station station = 2;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.prototype.getStation = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station} */ (
    jspb.Message.getWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station, 2));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.Station|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.prototype.setStation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.prototype.clearStation = function() {
  return this.setStation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.prototype.hasStation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 during = 3;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.prototype.getDuring = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess.prototype.setDuring = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    priority: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriority(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAvailable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIsAvailable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 priority = 3;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_available = 4;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.prototype.getIsAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption.prototype.setIsAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    priority: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriority(value);
      break;
    case 4:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    priority: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option;
  return proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 priority = 3;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 priority = 3;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Option options = 4;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option>}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option, 4));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.Option, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Image images = 2;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image>}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image, 2));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 rent = 4;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getRent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setRent = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 admin_fee = 5;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getAdminFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setAdminFee = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 security_deposit = 6;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getSecurityDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setSecurityDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 key_money = 7;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getKeyMoney = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setKeyMoney = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Layout layout = 8;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getLayout = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout} */ (
    jspb.Message.getWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout, 8));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Layout|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setLayout = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.clearLayout = function() {
  return this.setLayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.hasLayout = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional double square = 9;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getSquare = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setSquare = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional Direction direction = 10;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getDirection = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction} */ (
    jspb.Message.getWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction, 10));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.Direction|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setDirection = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.clearDirection = function() {
  return this.setDirection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.hasDirection = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int64 floor = 11;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setFloor = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 above_ground_story = 12;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getAboveGroundStory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setAboveGroundStory = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 under_ground_story = 13;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getUnderGroundStory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setUnderGroundStory = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 old = 14;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getOld = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setOld = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional BuildingType building_type = 15;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getBuildingType = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType} */ (
    jspb.Message.getWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType, 15));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.BuildingType|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setBuildingType = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.clearBuildingType = function() {
  return this.setBuildingType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.hasBuildingType = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated TrainAccess train_accesses = 16;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess>}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getTrainAccessesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess, 16));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setTrainAccessesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.addTrainAccesses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.TrainAccess, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.clearTrainAccessesList = function() {
  return this.setTrainAccessesList([]);
};


/**
 * repeated PopularOption popular_options = 17;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption>}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getPopularOptionsList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption, 17));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setPopularOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.addPopularOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.PopularOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.clearPopularOptionsList = function() {
  return this.setPopularOptionsList([]);
};


/**
 * repeated OptionCategory option_categories = 18;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory>}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getOptionCategoriesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory, 18));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setOptionCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.addOptionCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.OptionCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.clearOptionCategoriesList = function() {
  return this.setOptionCategoriesList([]);
};


/**
 * optional bool is_open = 19;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getIsOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setIsOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string address = 20;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional CustomerBukken customer_bukken = 1;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.prototype.getCustomerBukken = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken} */ (
    jspb.Message.getWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken, 1));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.CustomerBukken|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.prototype.setCustomerBukken = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.prototype.clearCustomerBukken = function() {
  return this.setCustomerBukken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CustomerBukkenPageViewResponse.prototype.hasCustomerBukken = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.repeatedFields_ = [1,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    methodToVisitsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    startEnabledAppointTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endEnabledAppointTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    notice: jspb.Message.getFieldWithDefault(msg, 4, ""),
    storeAddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
    nonWorkingDaysList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    nonWorkingTimesList: jspb.Message.toObjectList(msg.getNonWorkingTimesList(),
    proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse;
  return proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addMethodToVisits(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartEnabledAppointTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndEnabledAppointTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoreAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addNonWorkingDays(value);
      break;
    case 7:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.deserializeBinaryFromReader);
      msg.addNonWorkingTimes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMethodToVisitsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getStartEnabledAppointTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndEnabledAppointTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNotice();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStoreAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNonWorkingDaysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getNonWorkingTimesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    startedAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endedAt: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime;
  return proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartedAt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 started_at = 1;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.prototype.getStartedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.prototype.setStartedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 ended_at = 2;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.prototype.getEndedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime.prototype.setEndedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string method_to_visits = 1;
 * @return {!Array<string>}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.getMethodToVisitsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.setMethodToVisitsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.addMethodToVisits = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.clearMethodToVisitsList = function() {
  return this.setMethodToVisitsList([]);
};


/**
 * optional string start_enabled_appoint_time = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.getStartEnabledAppointTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.setStartEnabledAppointTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_enabled_appoint_time = 3;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.getEndEnabledAppointTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.setEndEnabledAppointTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string notice = 4;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.getNotice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.setNotice = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string store_address = 5;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.getStoreAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.setStoreAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string non_working_days = 6;
 * @return {!Array<string>}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.getNonWorkingDaysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.setNonWorkingDaysList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.addNonWorkingDays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.clearNonWorkingDaysList = function() {
  return this.setNonWorkingDaysList([]);
};


/**
 * repeated NonWorkingTime non_working_times = 7;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime>}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.getNonWorkingTimesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime, 7));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.setNonWorkingTimesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.addNonWorkingTimes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.NonWorkingTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentFormPageViewResponse.prototype.clearNonWorkingTimesList = function() {
  return this.setNonWorkingTimesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    methodToVisit: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appointedAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reservationsCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    demand: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest;
  return proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodToVisit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAppointedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReservationsCount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDemand(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMethodToVisit();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppointedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getReservationsCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDemand();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string method_to_visit = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.prototype.getMethodToVisit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.prototype.setMethodToVisit = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 appointed_at = 2;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.prototype.getAppointedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.prototype.setAppointedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 reservations_count = 3;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.prototype.getReservationsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.prototype.setReservationsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string demand = 4;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.prototype.getDemand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.prototype.setDemand = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateStoreVisitAppointmentRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAutoButsudashi: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isRejectAutoButsudashi: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    reasonsList: jspb.Message.toObjectList(msg.getReasonsList(),
    proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse;
  return proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAutoButsudashi(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRejectAutoButsudashi(value);
      break;
    case 3:
      var value = new proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.deserializeBinaryFromReader);
      msg.addReasons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAutoButsudashi();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsRejectAutoButsudashi();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getReasonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sequence: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason;
  return proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSequence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 sequence = 3;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_auto_butsudashi = 1;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.prototype.getIsAutoButsudashi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.prototype.setIsAutoButsudashi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_reject_auto_butsudashi = 2;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.prototype.getIsRejectAutoButsudashi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.prototype.setIsRejectAutoButsudashi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated Reason reasons = 3;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason>}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.prototype.getReasonsList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason, 3));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.prototype.setReasonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.prototype.addReasons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.Reason, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiPageViewResponse.prototype.clearReasonsList = function() {
  return this.setReasonsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reasonId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    opinion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest;
  return proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpinion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReasonId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpinion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string reason_id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.prototype.getReasonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.prototype.setReasonId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string opinion = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.prototype.getOpinion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.RejectAutoButsudashiRequest.prototype.setOpinion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest;
  return proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse;
  return proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.RestartDeliveryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lineAddFriendUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    authenticated: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    authType: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse;
  return proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineAddFriendUrl(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuthenticated(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLineAddFriendUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuthenticated();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAuthType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string auth_number = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.prototype.getAuthNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.prototype.setAuthNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 expired_at = 2;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string line_add_friend_url = 3;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.prototype.getLineAddFriendUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.prototype.setLineAddFriendUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool authenticated = 4;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.prototype.getAuthenticated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.prototype.setAuthenticated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string auth_type = 5;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.prototype.getAuthType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.AddLineFriendPageViewResponse.prototype.setAuthType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includesAuthCode: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    processValuesList: jspb.Message.toObjectList(msg.getProcessValuesList(),
    proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest;
  return proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludesAuthCode(value);
      break;
    case 3:
      var value = new proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.deserializeBinaryFromReader);
      msg.addProcessValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludesAuthCode();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getProcessValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    processFieldKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && canary_cloud_customer_v1_lib_pb.NullString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue;
  return proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessFieldKey(value);
      break;
    case 2:
      var value = new canary_cloud_customer_v1_lib_pb.NullString;
      reader.readMessage(value,canary_cloud_customer_v1_lib_pb.NullString.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessFieldKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      canary_cloud_customer_v1_lib_pb.NullString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string process_field_key = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.prototype.getProcessFieldKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.prototype.setProcessFieldKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullString value = 2;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.NullString}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.prototype.getValue = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.NullString} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_lib_pb.NullString, 2));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.NullString|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool includes_auth_code = 2;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.prototype.getIncludesAuthCode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.prototype.setIncludesAuthCode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated ProcessValue process_values = 3;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue>}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.prototype.getProcessValuesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue, 3));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.prototype.setProcessValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.prototype.addProcessValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.ProcessValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINERequest.prototype.clearProcessValuesList = function() {
  return this.setProcessValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customer: (f = msg.getCustomer()) && canary_cloud_customer_v1_customer_pb.Customer.toObject(includeInstance, f),
    authCode: (f = msg.getAuthCode()) && canary_cloud_customer_v1_auth_code_pb.AuthCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse;
  return proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new canary_cloud_customer_v1_customer_pb.Customer;
      reader.readMessage(value,canary_cloud_customer_v1_customer_pb.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 2:
      var value = new canary_cloud_customer_v1_auth_code_pb.AuthCode;
      reader.readMessage(value,canary_cloud_customer_v1_auth_code_pb.AuthCode.deserializeBinaryFromReader);
      msg.setAuthCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      canary_cloud_customer_v1_customer_pb.Customer.serializeBinaryToWriter
    );
  }
  f = message.getAuthCode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      canary_cloud_customer_v1_auth_code_pb.AuthCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional Customer customer = 1;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.Customer}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.prototype.getCustomer = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.Customer} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_customer_pb.Customer, 1));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.Customer|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AuthCode auth_code = 2;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.AuthCode}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.prototype.getAuthCode = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.AuthCode} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_auth_code_pb.AuthCode, 2));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.AuthCode|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.prototype.setAuthCode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.prototype.clearAuthCode = function() {
  return this.setAuthCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.CreateCustomerFromLINEResponse.prototype.hasAuthCode = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest;
  return proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    surveySetting: (f = msg.getSurveySetting()) && canary_cloud_customer_v1_survey_setting_pb.SurveySetting.toObject(includeInstance, f),
    processFieldsList: jspb.Message.toObjectList(msg.getProcessFieldsList(),
    canary_cloud_customer_v1_process_pb.ProcessField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse;
  return proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new canary_cloud_customer_v1_survey_setting_pb.SurveySetting;
      reader.readMessage(value,canary_cloud_customer_v1_survey_setting_pb.SurveySetting.deserializeBinaryFromReader);
      msg.setSurveySetting(value);
      break;
    case 2:
      var value = new canary_cloud_customer_v1_process_pb.ProcessField;
      reader.readMessage(value,canary_cloud_customer_v1_process_pb.ProcessField.deserializeBinaryFromReader);
      msg.addProcessFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurveySetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      canary_cloud_customer_v1_survey_setting_pb.SurveySetting.serializeBinaryToWriter
    );
  }
  f = message.getProcessFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      canary_cloud_customer_v1_process_pb.ProcessField.serializeBinaryToWriter
    );
  }
};


/**
 * optional SurveySetting survey_setting = 1;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.SurveySetting}
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.prototype.getSurveySetting = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.SurveySetting} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_survey_setting_pb.SurveySetting, 1));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.SurveySetting|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.prototype.setSurveySetting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.prototype.clearSurveySetting = function() {
  return this.setSurveySetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.prototype.hasSurveySetting = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ProcessField process_fields = 2;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.ProcessField>}
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.prototype.getProcessFieldsList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.ProcessField>} */ (
    jspb.Message.getRepeatedWrapperField(this, canary_cloud_customer_v1_process_pb.ProcessField, 2));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.ProcessField>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.prototype.setProcessFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ProcessField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ProcessField}
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.prototype.addProcessFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.apis.proto.canary_cloud.customer.v1.ProcessField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.GetSurveySettingResponse.prototype.clearProcessFieldsList = function() {
  return this.setProcessFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    processValuesList: jspb.Message.toObjectList(msg.getProcessValuesList(),
    proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest;
  return proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.deserializeBinaryFromReader);
      msg.addProcessValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    processFieldKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    textValue: (f = msg.getTextValue()) && canary_cloud_customer_v1_lib_pb.NullString.toObject(includeInstance, f),
    floatValue: (f = msg.getFloatValue()) && canary_cloud_customer_v1_lib_pb.NullDouble.toObject(includeInstance, f),
    intValue: (f = msg.getIntValue()) && canary_cloud_customer_v1_lib_pb.NullInt64.toObject(includeInstance, f),
    timeValue: (f = msg.getTimeValue()) && canary_cloud_customer_v1_lib_pb.NullDateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue;
  return proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessFieldKey(value);
      break;
    case 2:
      var value = new canary_cloud_customer_v1_lib_pb.NullString;
      reader.readMessage(value,canary_cloud_customer_v1_lib_pb.NullString.deserializeBinaryFromReader);
      msg.setTextValue(value);
      break;
    case 3:
      var value = new canary_cloud_customer_v1_lib_pb.NullDouble;
      reader.readMessage(value,canary_cloud_customer_v1_lib_pb.NullDouble.deserializeBinaryFromReader);
      msg.setFloatValue(value);
      break;
    case 4:
      var value = new canary_cloud_customer_v1_lib_pb.NullInt64;
      reader.readMessage(value,canary_cloud_customer_v1_lib_pb.NullInt64.deserializeBinaryFromReader);
      msg.setIntValue(value);
      break;
    case 5:
      var value = new canary_cloud_customer_v1_lib_pb.NullDateTime;
      reader.readMessage(value,canary_cloud_customer_v1_lib_pb.NullDateTime.deserializeBinaryFromReader);
      msg.setTimeValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessFieldKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTextValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      canary_cloud_customer_v1_lib_pb.NullString.serializeBinaryToWriter
    );
  }
  f = message.getFloatValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      canary_cloud_customer_v1_lib_pb.NullDouble.serializeBinaryToWriter
    );
  }
  f = message.getIntValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      canary_cloud_customer_v1_lib_pb.NullInt64.serializeBinaryToWriter
    );
  }
  f = message.getTimeValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      canary_cloud_customer_v1_lib_pb.NullDateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string process_field_key = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.getProcessFieldKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.setProcessFieldKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullString text_value = 2;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.NullString}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.getTextValue = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.NullString} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_lib_pb.NullString, 2));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.NullString|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.setTextValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.clearTextValue = function() {
  return this.setTextValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.hasTextValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullDouble float_value = 3;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.NullDouble}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.getFloatValue = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.NullDouble} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_lib_pb.NullDouble, 3));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.NullDouble|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.setFloatValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.clearFloatValue = function() {
  return this.setFloatValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.hasFloatValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullInt64 int_value = 4;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.NullInt64}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.getIntValue = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.NullInt64} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_lib_pb.NullInt64, 4));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.NullInt64|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.setIntValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.clearIntValue = function() {
  return this.setIntValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.hasIntValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullDateTime time_value = 5;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.NullDateTime}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.getTimeValue = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.NullDateTime} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_lib_pb.NullDateTime, 5));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.NullDateTime|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.setTimeValue = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.clearTimeValue = function() {
  return this.setTimeValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue.prototype.hasTimeValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated ProcessValue process_values = 1;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue>}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.prototype.getProcessValuesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue, 1));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.prototype.setProcessValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.prototype.addProcessValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.ProcessValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyRequest.prototype.clearProcessValuesList = function() {
  return this.setProcessValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse;
  return proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SubmitSurveyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest;
  return proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customer: (f = msg.getCustomer()) && canary_cloud_customer_v1_customer_pb.Customer.toObject(includeInstance, f),
    condition: (f = msg.getCondition()) && canary_cloud_customer_v1_condition_pb.Condition.toObject(includeInstance, f),
    layoutsList: jspb.Message.toObjectList(msg.getLayoutsList(),
    canary_cloud_customer_v1_condition_pb.ConditionLayout.toObject, includeInstance),
    prefecturesList: jspb.Message.toObjectList(msg.getPrefecturesList(),
    canary_cloud_customer_v1_mapping_pb.Prefecture.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse;
  return proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new canary_cloud_customer_v1_customer_pb.Customer;
      reader.readMessage(value,canary_cloud_customer_v1_customer_pb.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 2:
      var value = new canary_cloud_customer_v1_condition_pb.Condition;
      reader.readMessage(value,canary_cloud_customer_v1_condition_pb.Condition.deserializeBinaryFromReader);
      msg.setCondition(value);
      break;
    case 3:
      var value = new canary_cloud_customer_v1_condition_pb.ConditionLayout;
      reader.readMessage(value,canary_cloud_customer_v1_condition_pb.ConditionLayout.deserializeBinaryFromReader);
      msg.addLayouts(value);
      break;
    case 4:
      var value = new canary_cloud_customer_v1_mapping_pb.Prefecture;
      reader.readMessage(value,canary_cloud_customer_v1_mapping_pb.Prefecture.deserializeBinaryFromReader);
      msg.addPrefectures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      canary_cloud_customer_v1_customer_pb.Customer.serializeBinaryToWriter
    );
  }
  f = message.getCondition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      canary_cloud_customer_v1_condition_pb.Condition.serializeBinaryToWriter
    );
  }
  f = message.getLayoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      canary_cloud_customer_v1_condition_pb.ConditionLayout.serializeBinaryToWriter
    );
  }
  f = message.getPrefecturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      canary_cloud_customer_v1_mapping_pb.Prefecture.serializeBinaryToWriter
    );
  }
};


/**
 * optional Customer customer = 1;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.Customer}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.getCustomer = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.Customer} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_customer_pb.Customer, 1));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.Customer|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Condition condition = 2;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.Condition}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.getCondition = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.Condition} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_condition_pb.Condition, 2));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.Condition|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.setCondition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.clearCondition = function() {
  return this.setCondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.hasCondition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ConditionLayout layouts = 3;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout>}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.getLayoutsList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout>} */ (
    jspb.Message.getRepeatedWrapperField(this, canary_cloud_customer_v1_condition_pb.ConditionLayout, 3));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.setLayoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.addLayouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apis.proto.canary_cloud.customer.v1.ConditionLayout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.clearLayoutsList = function() {
  return this.setLayoutsList([]);
};


/**
 * repeated Prefecture prefectures = 4;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.Prefecture>}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.getPrefecturesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.Prefecture>} */ (
    jspb.Message.getRepeatedWrapperField(this, canary_cloud_customer_v1_mapping_pb.Prefecture, 4));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.Prefecture>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.setPrefecturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Prefecture=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Prefecture}
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.addPrefectures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.apis.proto.canary_cloud.customer.v1.Prefecture, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.GetConditionResponse.prototype.clearPrefecturesList = function() {
  return this.setPrefecturesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.repeatedFields_ = [4,9,10,14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    moveInTiming: jspb.Message.getFieldWithDefault(msg, 3, ""),
    stationIdAndLineIdsList: jspb.Message.toObjectList(msg.getStationIdAndLineIdsList(),
    proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.toObject, includeInstance),
    rentMax: jspb.Message.getFieldWithDefault(msg, 5, 0),
    rentMin: jspb.Message.getFieldWithDefault(msg, 6, 0),
    squareMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    squareMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    layoutIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    cityIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    walkDuringMax: jspb.Message.getFieldWithDefault(msg, 11, 0),
    oldMax: jspb.Message.getFieldWithDefault(msg, 12, 0),
    other: jspb.Message.getFieldWithDefault(msg, 13, ""),
    customerBukkenOptionIdsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    citiesList: jspb.Message.toObjectList(msg.getCitiesList(),
    proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest;
  return proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMoveInTiming(value);
      break;
    case 4:
      var value = new proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.deserializeBinaryFromReader);
      msg.addStationIdAndLineIds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRentMax(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRentMin(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSquareMax(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSquareMin(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addLayoutIds(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addCityIds(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWalkDuringMax(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOldMax(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOther(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addCustomerBukkenOptionIds(value);
      break;
    case 15:
      var value = new proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.deserializeBinaryFromReader);
      msg.addCities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMoveInTiming();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStationIdAndLineIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLayoutIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getCityIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getOther();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCustomerBukkenOptionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getCitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chomeiIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City;
  return proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addChomeiIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChomeiIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string chomei_ids = 2;
 * @return {!Array<string>}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.prototype.getChomeiIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.prototype.setChomeiIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.prototype.addChomeiIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City.prototype.clearChomeiIdsList = function() {
  return this.setChomeiIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lineId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId;
  return proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLineId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string station_id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.prototype.getStationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.prototype.setStationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string line_id = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.prototype.getLineId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId.prototype.setLineId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string move_in_timing = 3;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getMoveInTiming = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setMoveInTiming = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated StationIdAndLineId station_id_and_line_ids = 4;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId>}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getStationIdAndLineIdsList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId, 4));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setStationIdAndLineIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.addStationIdAndLineIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.StationIdAndLineId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.clearStationIdAndLineIdsList = function() {
  return this.setStationIdAndLineIdsList([]);
};


/**
 * optional int64 rent_max = 5;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getRentMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setRentMax = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.clearRentMax = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.hasRentMax = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 rent_min = 6;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getRentMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setRentMin = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.clearRentMin = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.hasRentMin = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double square_max = 7;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getSquareMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setSquareMax = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.clearSquareMax = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.hasSquareMax = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double square_min = 8;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getSquareMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setSquareMin = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.clearSquareMin = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.hasSquareMin = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string layout_ids = 9;
 * @return {!Array<string>}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getLayoutIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setLayoutIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.addLayoutIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.clearLayoutIdsList = function() {
  return this.setLayoutIdsList([]);
};


/**
 * repeated string city_ids = 10;
 * @return {!Array<string>}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getCityIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setCityIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.addCityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.clearCityIdsList = function() {
  return this.setCityIdsList([]);
};


/**
 * optional int64 walk_during_max = 11;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getWalkDuringMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setWalkDuringMax = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.clearWalkDuringMax = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.hasWalkDuringMax = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 old_max = 12;
 * @return {number}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getOldMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setOldMax = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.clearOldMax = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.hasOldMax = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string other = 13;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setOther = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated string customer_bukken_option_ids = 14;
 * @return {!Array<string>}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getCustomerBukkenOptionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setCustomerBukkenOptionIdsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.addCustomerBukkenOptionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.clearCustomerBukkenOptionIdsList = function() {
  return this.setCustomerBukkenOptionIdsList([]);
};


/**
 * repeated City cities = 15;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City>}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.getCitiesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City, 15));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.setCitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.addCities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.City, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionRequest.prototype.clearCitiesList = function() {
  return this.setCitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse;
  return proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.UpdateConditionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    prefectureId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest;
  return proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrefectureId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrefectureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string prefecture_id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest.prototype.getPrefectureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsRequest.prototype.setPrefectureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    routesList: jspb.Message.toObjectList(msg.getRoutesList(),
    canary_cloud_customer_v1_mapping_pb.Route.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse;
  return proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new canary_cloud_customer_v1_mapping_pb.Route;
      reader.readMessage(value,canary_cloud_customer_v1_mapping_pb.Route.deserializeBinaryFromReader);
      msg.addRoutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      canary_cloud_customer_v1_mapping_pb.Route.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Route routes = 1;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.Route>}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.prototype.getRoutesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.Route>} */ (
    jspb.Message.getRepeatedWrapperField(this, canary_cloud_customer_v1_mapping_pb.Route, 1));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.Route>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.prototype.setRoutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Route=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Route}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.prototype.addRoutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.apis.proto.canary_cloud.customer.v1.Route, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.SearchStationsResponse.prototype.clearRoutesList = function() {
  return this.setRoutesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    prefectureId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest;
  return proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrefectureId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrefectureId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string prefecture_id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest.prototype.getPrefectureId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesRequest.prototype.setPrefectureId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    areasList: jspb.Message.toObjectList(msg.getAreasList(),
    canary_cloud_customer_v1_mapping_pb.Area.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse;
  return proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new canary_cloud_customer_v1_mapping_pb.Area;
      reader.readMessage(value,canary_cloud_customer_v1_mapping_pb.Area.deserializeBinaryFromReader);
      msg.addAreas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAreasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      canary_cloud_customer_v1_mapping_pb.Area.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Area areas = 1;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.Area>}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.prototype.getAreasList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.Area>} */ (
    jspb.Message.getRepeatedWrapperField(this, canary_cloud_customer_v1_mapping_pb.Area, 1));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.Area>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.prototype.setAreasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Area=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Area}
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.prototype.addAreas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.apis.proto.canary_cloud.customer.v1.Area, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.SearchCitiesResponse.prototype.clearAreasList = function() {
  return this.setAreasList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    prefectureIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest;
  return proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest}
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPrefectureIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrefectureIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string prefecture_ids = 1;
 * @return {!Array<string>}
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.prototype.getPrefectureIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.prototype.setPrefectureIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.prototype.addPrefectureIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesRequest.prototype.clearPrefectureIdsList = function() {
  return this.setPrefectureIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    prefecturesList: jspb.Message.toObjectList(msg.getPrefecturesList(),
    canary_cloud_customer_v1_mapping_pb.Prefecture.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse;
  return proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse}
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new canary_cloud_customer_v1_mapping_pb.Prefecture;
      reader.readMessage(value,canary_cloud_customer_v1_mapping_pb.Prefecture.deserializeBinaryFromReader);
      msg.addPrefectures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrefecturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      canary_cloud_customer_v1_mapping_pb.Prefecture.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Prefecture prefectures = 1;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.Prefecture>}
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.prototype.getPrefecturesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.Prefecture>} */ (
    jspb.Message.getRepeatedWrapperField(this, canary_cloud_customer_v1_mapping_pb.Prefecture, 1));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.Prefecture>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.prototype.setPrefecturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Prefecture=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Prefecture}
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.prototype.addPrefectures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.apis.proto.canary_cloud.customer.v1.Prefecture, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.BatchGetPrefecturesResponse.prototype.clearPrefecturesList = function() {
  return this.setPrefecturesList([]);
};


goog.object.extend(exports, proto.apis.proto.canary_cloud.customer.v1);
