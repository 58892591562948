import classNames from "classnames";

import styles from "./FormLabel.module.scss";

interface Props {
  type: "require" | "optional" | "multipleSelection";
}

export const FormLabel = (props: Props) => {
  const isRequire = props.type === "require";
  const isOptional = props.type === "optional";
  const isMultipleSelection = props.type === "multipleSelection";

  return (
    <span
      className={classNames(
        isRequire ? styles.requireMark : styles.optionalMark
      )}
    >
      {isRequire
        ? "必須"
        : isOptional
          ? "任意"
          : isMultipleSelection
            ? "複数選択可"
            : ""}
    </span>
  );
};
