import type { Error } from "grpc-web";
import { StatusCode } from "grpc-web";

export type ErrorType =
  | "unauthenticated"
  | "notfound"
  | "store-visit-appointment-not-allowed"
  | "unexpected-run-time-error"
  | "other";

export const checkErrorType = (code: number): ErrorType => {
  if (code === StatusCode.NOT_FOUND) {
    return "notfound";
  } else if (
    code === StatusCode.PERMISSION_DENIED ||
    code === StatusCode.UNAUTHENTICATED ||
    code === StatusCode.INVALID_ARGUMENT
  ) {
    return "unauthenticated";
  } else {
    return "other";
  }
};

export const isGrpcError = (e: unknown): e is Error => {
  if (typeof e === "object" && e !== null && "code" in e && "message" in e) {
    return true;
  }
  return false;
};
