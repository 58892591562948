import "./index.scss";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createRoot } from "react-dom/client";

import { App } from "./App";
import { getCurrentRevision } from "./revision";

const currentRevisiton = getCurrentRevision();

Sentry.init({
  dsn: "https://85d2cb3baf534fbfb52a3eb89282b6ed@o148273.ingest.sentry.io/5976647",
  integrations: [new Integrations.BrowserTracing()],
  release: currentRevisiton?.version,
  environment: process.env.REACT_APP_DEPLOYED_STAGE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  ignoreErrors: [
    // https://github.com/getsentry/sentry-javascript/issues/2292
    /Non-Error exception captured.*/,
  ],
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
  /**
   * NOTE:React18から、React.StrictMode を使うとuseEffectが2回呼ばれるようになったため、一旦削除しています。
   * データフェチ系でuseEffectを使用しているため、TanStack-Queryに移行が完了できたら、再度StrictModeを有効にしてください。
   */
  <App />
);
