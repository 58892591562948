import { zodResolver } from "@hookform/resolvers/zod";
import type {
  Prefecture,
  Station,
} from "apis/proto/canary_cloud/customer/v1/mapping_pb";
import { Button } from "components/button/Button";
import { Collapse } from "components/collapse/Collapse";
import { Checkbox } from "components/form/Checkbox";
import { Modal } from "components/modal/Modal";
import { WatchingFormValues } from "libs/react-hook-form/components/WatchigFormValues";
import type { MouseEvent } from "react";
import { useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import type { SearchStationsResponse } from "usecase/station/station";
import { z } from "zod";

import styles from "./SelectModal.module.scss";

const onClickStopPropagation = (e: MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

const validationSchema = z.object({
  stationIdAndLineIdsList: z.array(z.string()),
});

export type StationIdAndLineIdsFormSchema = z.infer<typeof validationSchema>;

type SelectStationModalProps = {
  defaultValues: StationIdAndLineIdsFormSchema;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: StationIdAndLineIdsFormSchema) => void;
  prefecturesList: Prefecture.AsObject[];
  stationsByPrefecture: SearchStationsResponse[];
};

export const SelectStationModal = (props: SelectStationModalProps) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    defaultValues,
    prefecturesList,
    stationsByPrefecture,
  } = props;

  const methods = useForm<StationIdAndLineIdsFormSchema>({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: zodResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;

  const handleSubmitEvent = (data: StationIdAndLineIdsFormSchema) => {
    onSubmit(data);
  };

  const onClickResetButton = () => {
    reset({
      stationIdAndLineIdsList: [],
    });
  };

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [defaultValues, isOpen, reset]);

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose}>
      <Modal.Header onClose={onClose}>路線・駅を選択</Modal.Header>

      <FormProvider {...methods}>
        <form className={styles.formContainer}>
          {stationsByPrefecture.length === 1 ? (
            <RoutesContainer
              isExpand
              isSinglePrefecture
              routesList={stationsByPrefecture[0].routesList}
            />
          ) : (
            stationsByPrefecture.map((station, index) => (
              <Collapse
                key={prefecturesList[index].id}
                expand={false}
                label={prefecturesList[index].name}
                classNames={{
                  container: styles.prefectureContainer,
                  collapseLabel: styles.prefectureLabel,
                }}
              >
                {({ expanded }) =>
                  expanded && (
                    <RoutesContainer
                      isExpand={false}
                      isSinglePrefecture={false}
                      routesList={station.routesList}
                    />
                  )
                }
              </Collapse>
            ))
          )}
        </form>
      </FormProvider>

      <Modal.Footer>
        <Button type="button" variant="subtle" onClick={onClickResetButton}>
          リセット
        </Button>
        <Button type="button" onClick={handleSubmit(handleSubmitEvent)}>
          決定
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

type RoutesContainerProps = {
  isExpand: boolean;
  isSinglePrefecture: boolean;
  routesList: SearchStationsResponse["routesList"];
};

const RoutesContainer = (props: RoutesContainerProps) => {
  const { routesList, isExpand, isSinglePrefecture: _ } = props;
  const { setValue, control } = useFormContext<StationIdAndLineIdsFormSchema>();

  const onChangeRoute = (
    isChecked = false,
    route: SearchStationsResponse["routesList"][number],
    stationIdAndLineIdsList: string[] = []
  ) => {
    if (isChecked) {
      setValue(
        "stationIdAndLineIdsList",
        stationIdAndLineIdsList?.filter(
          (stationIdAndLineId) =>
            !route.stationIdsInThisRouteList.includes(stationIdAndLineId)
        ) ?? []
      );
      return;
    }
    setValue(
      "stationIdAndLineIdsList",
      Array.from(
        new Set([
          ...(stationIdAndLineIdsList ?? []),
          ...route.stationIdsInThisRouteList,
        ])
      )
    );
  };

  return (
    <div>
      {routesList?.map((route) => {
        return (
          <Collapse
            key={route?.id}
            expand={isExpand}
            label={route.name}
            classNames={{
              container: styles.routeContainer,
              collapseLabel: styles.routeLabel,
              collapse: styles.routeCollapse,
            }}
            leftElement={
              <WatchingFormValues control={control}>
                {({ stationIdAndLineIdsList }) => {
                  const selectedStationIdInThisRoute =
                    stationIdAndLineIdsList?.filter((stationId) =>
                      route.stationIdsInThisRouteList.includes(stationId)
                    ) ?? [];
                  const isChecked =
                    selectedStationIdInThisRoute.length ===
                    route.stationIdsInThisRouteList.length;
                  return (
                    <Checkbox
                      key={route.id}
                      checked={isChecked}
                      id={route.id}
                      label=""
                      name=""
                      value=""
                      classNames={{
                        label: styles.checkboxLabel,
                      }}
                      onClick={onClickStopPropagation} // Collapseへイベント伝搬を止める
                      onChange={() =>
                        onChangeRoute(isChecked, route, stationIdAndLineIdsList)
                      }
                    />
                  );
                }}
              </WatchingFormValues>
            }
          >
            {({ expanded }) =>
              expanded && (
                <LineContainer isExpand={false} linesList={route.linesList} />
              )
            }
          </Collapse>
        );
      })}
    </div>
  );
};

type LineContainerProps = {
  isExpand: boolean;
  linesList: SearchStationsResponse["routesList"][number]["linesList"];
};

const LineContainer = (props: LineContainerProps) => {
  const { linesList, isExpand } = props;
  const { setValue, control } = useFormContext<StationIdAndLineIdsFormSchema>();

  // 路線が1つの場合は、Collapseを使用せずに表示する
  if (linesList.length === 1) {
    return (
      <div className={styles.singleLineContainer}>
        <StationContainer stationsList={linesList[0].stationsList} />
      </div>
    );
  }

  const onChangeLine = (
    isChecked = false,
    line: SearchStationsResponse["routesList"][number]["linesList"][number],
    stationIdAndLineIdsList: string[] = []
  ) => {
    if (isChecked) {
      setValue(
        "stationIdAndLineIdsList",
        stationIdAndLineIdsList?.filter(
          (stationIdAndLineId) =>
            !line.stationIdsInThisLineList.includes(stationIdAndLineId)
        ) ?? []
      );
      return;
    }
    setValue(
      "stationIdAndLineIdsList",
      Array.from(
        new Set([
          ...(stationIdAndLineIdsList ?? []),
          ...line.stationIdsInThisLineList,
        ])
      )
    );
  };

  return (
    <>
      {linesList.map((line) => {
        return (
          <Collapse
            key={line.id}
            expand={isExpand}
            label={line.name}
            classNames={{
              collapseControl: styles.lineCollapseControl,
              collapse: styles.lineCollapse,
              collapseLabel: styles.lineLabel,
              container: styles.lineContainer,
            }}
            leftElement={
              <WatchingFormValues control={control}>
                {({ stationIdAndLineIdsList }) => {
                  const selectedStationIdInThisLine =
                    stationIdAndLineIdsList?.filter((stationId) => {
                      return line.stationIdsInThisLineList.includes(stationId);
                    }) ?? [];
                  const isChecked =
                    selectedStationIdInThisLine.length ===
                    line.stationIdsInThisLineList.length;
                  return (
                    <Checkbox
                      key={line.id}
                      checked={isChecked}
                      id={line.id}
                      label=""
                      name=""
                      value=""
                      classNames={{
                        label: styles.checkboxLabel,
                      }}
                      onClick={onClickStopPropagation} // Collapseへイベント伝搬を止める
                      onChange={() =>
                        onChangeLine(isChecked, line, stationIdAndLineIdsList)
                      }
                    />
                  );
                }}
              </WatchingFormValues>
            }
          >
            {({ expanded }) =>
              expanded && <StationContainer stationsList={line.stationsList} />
            }
          </Collapse>
        );
      })}
    </>
  );
};

type StationContainerProps = {
  stationsList: SearchStationsResponse["routesList"][number]["linesList"][number]["stationsList"];
};

const StationContainer = (props: StationContainerProps) => {
  const { stationsList } = props;
  const { control, setValue } = useFormContext<StationIdAndLineIdsFormSchema>();

  const onChangeStation = (
    isChecked = false,
    station: Station.AsObject,
    stationIdAndLineIdsList: string[] = []
  ) => {
    if (isChecked) {
      setValue(
        "stationIdAndLineIdsList",
        stationIdAndLineIdsList?.filter(
          (stationIdAndLineId) => stationIdAndLineId !== station.id
        ) ?? []
      );
      return;
    }
    setValue(
      "stationIdAndLineIdsList",
      Array.from(new Set([...(stationIdAndLineIdsList ?? []), station.id]))
    );
  };

  return (
    <>
      {stationsList.map((station) => {
        return (
          // HACK:shouldUnregister={false}の指定ができなかったので、暫定的に制御コンポーネントとして扱う
          <WatchingFormValues key={station.id} control={control}>
            {({ stationIdAndLineIdsList }) => {
              const isChecked = stationIdAndLineIdsList?.includes(station.id);
              return (
                <Checkbox
                  key={station.id}
                  checked={isChecked}
                  id={station.id}
                  label={station.name}
                  name=""
                  value=""
                  classNames={{
                    label: styles.checkboxLabel,
                    container: styles.stationCheckboxContainer,
                  }}
                  onClick={onClickStopPropagation} // Collapseへイベント伝搬を止める
                  onChange={() =>
                    onChangeStation(isChecked, station, stationIdAndLineIdsList)
                  }
                />
              );
            }}
          </WatchingFormValues>
        );
      })}
    </>
  );
};
