import type { CustomerBukkenPageViewResponse } from "apis/proto/canary_cloud/customer/v1/api_pb";

export const NO_CONTENT = "-";

// 料金を文字列に変換
export const convertMoneyString = (money: number) => {
  if (money === 0) {
    return "無料";
  }

  if (money >= 10000) {
    return `${money / 10000}万円`;
  } else {
    return `${money.toLocaleString()}円`;
  }
};

// 物件の築年数を文字列に変換
export const convertOldString = (
  customerBukken: CustomerBukkenPageViewResponse.CustomerBukken.AsObject
) => {
  if (customerBukken.old === 0) {
    return "新築";
  }

  return `${customerBukken.old}年`;
};

// 物件の月額料金を文字列に変換
export const convertMonthlyString = (
  customerBukken: CustomerBukkenPageViewResponse.CustomerBukken.AsObject
) => {
  return `${convertMoneyString(
    customerBukken.rent
  )} / 管理費 ${convertMoneyString(customerBukken.adminFee)}`;
};

// 物件の間取りを文字列に変換
export const convertLayoutString = (
  customerBukken: CustomerBukkenPageViewResponse.CustomerBukken.AsObject
) => customerBukken.layout?.name || NO_CONTENT;

// 物件の階数を文字列に変換
export const convertFloorString = (
  customerBukken: CustomerBukkenPageViewResponse.CustomerBukken.AsObject
) => {
  if (!customerBukken.floor) {
    return NO_CONTENT;
  }
  return customerBukken.floor < 0
    ? `地下${Math.abs(customerBukken.floor)}階`
    : `${customerBukken.floor}階`;
};

// 物件の階建てを文字列に変換
export const convertStoryString = (
  customerBukken: CustomerBukkenPageViewResponse.CustomerBukken.AsObject
) => {
  return customerBukken.underGroundStory !== 0
    ? `地上${customerBukken.aboveGroundStory}階 地下${Math.abs(
        customerBukken.underGroundStory
      )}階`
    : `${customerBukken.aboveGroundStory}階建`;
};

// 物件の面積を文字列に変換
export const convertSquareString = (
  customerBukken: CustomerBukkenPageViewResponse.CustomerBukken.AsObject
) => (customerBukken.square ? `${customerBukken.square}㎡` : NO_CONTENT);
