import type { ErrorType } from "util/error/error";

import { ReactComponent as WarningIcon } from "../../assets/icons/Warning.svg";
import { ReactComponent as LogoIcon } from "../../assets/logo/Logo.svg";
import { Head } from "../../components/info/Head";
import styles from "./Erorr.module.scss";

type ErrorDetail = {
  errorMessage: string;
  errorTitle: string;
};

const getErrorDetail = (type?: ErrorType): ErrorDetail => {
  switch (type) {
    case "unauthenticated":
      return {
        errorTitle: "認証エラー",
        errorMessage: `お客様の認証に失敗しました。
          再度送信されたメールのURLをお試しください`,
      };
    case "notfound":
      return {
        errorTitle: "404エラー",
        errorMessage: "お探しのページが見つかりません",
      };
    case "store-visit-appointment-not-allowed": {
      return {
        errorTitle: "エラー",
        errorMessage: `現在利用Webからの来店予約機能は使用できない状態になっています。
          メール・電話にてお問い合わせください。`,
      };
    }
    case "unexpected-run-time-error": {
      return {
        errorTitle: "不明なエラーが発生しました",
        errorMessage: `ただいま調査しております。
        今しばらくお待ちくださいませ。`,
      };
    }
    default:
      return {
        errorTitle: "不明なエラー",
        errorMessage: `不明なエラーが発生しました。
          リロードをお試しください`,
      };
  }
};

type Props = {
  error: ErrorType;
};

export const ErrorPage = (props: Props) => {
  const errorDetail = getErrorDetail(props.error);

  return (
    <>
      <Head title={errorDetail.errorTitle} />
      <div className={styles.container}>
        <div className={styles.title}>
          <WarningIcon />
          {errorDetail.errorTitle}
        </div>
        <LogoIcon />
        <div className={styles.message}>{errorDetail.errorMessage}</div>
      </div>
    </>
  );
};
