import classNames from "classnames";
import { Field } from "formik";
import type {
  ChangeEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
} from "react";
import { forwardRef } from "react";
import type { Control, FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

import styles from "./Input.module.scss";

type FormikProps = {
  fieldName: string;
  placeholder?: string;
  type?: string;
};

/**
 * @deprecated
 * 同ファイル内の Input を使用してください。
 */
export const FormikInput = (props: FormikProps) => {
  return (
    <div>
      <Field
        as="input"
        className={styles.input}
        name={props.fieldName}
        placeholder={props.placeholder}
        type={props.type}
      />
    </div>
  );
};

type RequiredInputPropsName = "id" | "name";
type HTMLInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
type BaseInputProps = Omit<HTMLInputProps, RequiredInputPropsName | "ref"> &
  Required<Pick<HTMLInputProps, RequiredInputPropsName>>;

type InputProps = BaseInputProps;

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { className, ...otherProps } = props;

  return (
    <input
      ref={ref}
      className={classNames(styles.input, className)}
      {...otherProps}
    />
  );
});

Input.displayName = "Input";

type ControlInputProps<
  T extends FieldValues,
  N extends FieldPath<T>,
> = BaseInputProps & {
  control: Control<T>;
  name: N;
};

export const ControlInput = <T extends FieldValues, N extends FieldPath<T>>(
  props: ControlInputProps<T, N>
) => {
  const { control, name, className, onChange, ...otherProps } = props;
  const { field } = useController({
    name,
    control,
  });

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    onChange && onChange(e);
  };

  return (
    <Input
      ref={field.ref}
      className={className}
      name={field.name}
      value={field.value}
      onChange={onChangeInput}
      {...otherProps}
    />
  );
};
