import type { CreateCustomerFromLINEResponse } from "apis/proto/canary_cloud/customer/v1/api_pb";
import { CreateCustomerFromLINERequest } from "apis/proto/canary_cloud/customer/v1/api_pb";
import { ApiV1Client } from "apis/proto/canary_cloud/customer/v1/ApiServiceClientPb";
import { PageLoading } from "components/loading/PageLoading";
import { ErrorPage } from "pages/Error/Error";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checkErrorType, isGrpcError } from "util/error/error";
import { logError } from "util/error/logError";
import type { APIBaseResponseType } from "util/utilTypes";

import { NullString } from "../../apis/proto/canary_cloud/customer/v1/lib_pb";

const createCustomer = async (
  organizationId: string,
  processValueList: Array<CreateCustomerFromLINERequest.ProcessValue>
): Promise<CreateCustomerFromLINEResponse.AsObject> => {
  const client = new ApiV1Client(
    process.env["REACT_APP_API_BASE_URL"] as string
  );
  const req = new CreateCustomerFromLINERequest();
  req.setOrganizationId(organizationId);
  req.setIncludesAuthCode(true);
  req.setProcessValuesList(processValueList);
  const res = await client.createCustomerFromLINE(req, {
    "x-organization-id": organizationId,
  });
  return res.toObject();
};

type CreateCustomerType = APIBaseResponseType<
  NonNullable<CreateCustomerFromLINEResponse.AsObject>
>;

export const CreateCustomer = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [createCustomerResponse, setCreateCustomerResponse] =
    useState<CreateCustomerType>({
      state: "initial",
      data: null,
    });
  const navigate = useNavigate();

  const processValues: CreateCustomerFromLINERequest.ProcessValue[] = [];

  const query = new URLSearchParams(location.search);

  Array.from(new Set(query.keys())).forEach((key) => {
    const val = query.get(key) || "";
    if (key.startsWith("pf-")) {
      key = key.replace(/^pf-/, "");
    }
    processValues.push(
      new CreateCustomerFromLINERequest.ProcessValue()
        .setProcessFieldKey(key)
        .setValue(new NullString().setValue(val).setHasValue(true))
    );
  });

  useEffect(() => {
    (async () => {
      try {
        setCreateCustomerResponse({
          state: "loading",
          data: null,
        });
        const res = await createCustomer(organizationId ?? "", processValues);
        setCreateCustomerResponse({
          state: "success",
          data: res,
        });
        navigate(
          `/${organizationId}/add-line-friend?auth_code=${res.authCode?.code}&source=create-customer`
        );
      } catch (e) {
        logError(e);
        if (isGrpcError(e)) {
          const errorType = checkErrorType(e.code);
          setCreateCustomerResponse({
            state: "failed",
            data: {
              errorCode: errorType,
              error: new Error(JSON.stringify(e)),
            },
          });
        } else {
          setCreateCustomerResponse({
            state: "failed",
            data: {
              errorCode: "other",
              error: new Error(JSON.stringify(e)),
            },
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    createCustomerResponse.state === "loading" ||
    createCustomerResponse.state === "initial"
  ) {
    return <PageLoading fullscreen />;
  }

  if (createCustomerResponse.state === "failed") {
    return <ErrorPage error={createCustomerResponse.data.errorCode} />;
  }

  return null;
};
