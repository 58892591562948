import "react-datepicker/dist/react-datepicker.css";

import type { CreateStoreVisitAppointmentFormPageViewResponse } from "apis/proto/canary_cloud/customer/v1/api_pb";
import { ReactComponent as CalendarIcon } from "assets/icons/Calendar.svg";
import { ReactComponent as CaretIcon } from "assets/icons/Caret.svg";
import { Button } from "components/button/Button";
import { FormLabel } from "components/form/FormLabel";
import { FormikInput } from "components/form/Input";
import type { RadioFieldChoice } from "components/form/RadioGroup";
import { FormikRadioGroup } from "components/form/RadioGroup";
import { FormikTextarea } from "components/form/Textarea";
import ja from "date-fns/locale/ja";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import type { MethodToVisitID } from "util/constants/methodToVisit";
import {
  isMethodToVisitID,
  MethodToVisit,
  MethodToVisitPriority,
} from "util/constants/methodToVisit";
import { isWeekDayID, WeekDay } from "util/constants/weekDay";
import {
  addDays,
  getDay,
  isAfter,
  isBefore,
  isSameDateTime,
  isSameDay,
  isWithinInterval,
  newOnlyDate,
  parseStringToDate,
  secondToDate,
  setHours,
  setMinutes,
  setSeconds,
} from "util/datetimeUtils";
import * as yup from "yup";

import styles from "./StoreVisitAppointForm.module.scss";

registerLocale("ja", ja);
setDefaultLocale("ja");

interface Props {
  createStoreVisitAppointmentForm: CreateStoreVisitAppointmentFormPageViewResponse.AsObject;
  isError: boolean;
  onSubmit: (values: StoreVisitAppointFormValues) => Promise<void>;
}

export type StoreVisitAppointFormValues = {
  demand: string;
  methodToVisitId: (typeof MethodToVisit)[keyof typeof MethodToVisit];
  phoneNumber: string;
  reservedCount: number;
  selectedDate: Date;
  selectedTime: Date;
};

const searchInitialDay = (
  initialDate: Date,
  nonWorkingDays: number[]
): Date => {
  if (nonWorkingDays.includes(getDay(initialDate))) {
    return searchInitialDay(addDays(initialDate, 1), nonWorkingDays);
  }

  return initialDate;
};

export const StoreVisitAppointForm = (props: Props) => {
  const nonWorkingDays =
    props.createStoreVisitAppointmentForm.nonWorkingDaysList
      .map((id) => (isWeekDayID(id) ? WeekDay[id] : null))
      .filter((value) => value !== null) as number[];

  const isEnableDay = (date: Date) => {
    const isHolyday =
      props.createStoreVisitAppointmentForm.nonWorkingTimesList.some(
        (holiday) => {
          const holidayStartDate = secondToDate(holiday.startedAt);
          const holidayEndDate = secondToDate(holiday.endedAt);
          return (
            (isSameDateTime(date, holidayStartDate) ||
              isAfter(date, holidayStartDate)) &&
            isBefore(date, holidayEndDate)
          );
        }
      );

    if (isHolyday) {
      return false;
    }
    const day = date.getDay();
    return !nonWorkingDays.includes(day);
  };

  const isEnableTime = (date: Date, selected: Date) => {
    const isHolidayTime =
      props.createStoreVisitAppointmentForm.nonWorkingTimesList.some(
        (holiday) => {
          const holidayStartDate = secondToDate(holiday.startedAt);
          const holidayEndDate = secondToDate(holiday.endedAt);
          const holidayStartOnlyDate = newOnlyDate(holidayStartDate);
          const holidayEndOnlyDate = newOnlyDate(holidayEndDate);

          const holidayStartTime = setHours(
            setMinutes(setSeconds(today, 0), holidayStartDate.getMinutes()),
            holidayStartDate.getHours()
          );
          const holidayEndTime = setHours(
            setMinutes(setSeconds(today, 0), holidayEndDate.getMinutes()),
            holidayEndDate.getHours()
          );
          const datePickerTime = setHours(
            setMinutes(setSeconds(today, 0), date.getMinutes()),
            date.getHours()
          );
          const selectedDate = newOnlyDate(selected);

          // 選択日が店休日開始後かつ店休日終了前
          const isHoliday =
            isAfter(selectedDate, holidayStartOnlyDate) &&
            isBefore(selectedDate, holidayEndOnlyDate);

          // 選択日が店休日開始日かつ店休開始日時以降
          const isAfterHolidayStartTime =
            isSameDay(selectedDate, holidayStartOnlyDate) &&
            (isSameDateTime(datePickerTime, holidayStartTime) ||
              isAfter(datePickerTime, holidayStartTime));

          // 選択日が店休日終了日かつ店休終了日時以前(終了日時と同一時刻は選択可能とする)
          const isBeforeHolidayEndTime =
            isSameDay(selectedDate, holidayEndOnlyDate) &&
            isBefore(datePickerTime, holidayEndTime);

          const isHolidayTime =
            isHoliday || isSameDay(holidayStartDate, holidayEndDate)
              ? isAfterHolidayStartTime && isBeforeHolidayEndTime
              : isAfterHolidayStartTime || isBeforeHolidayEndTime;

          return isHolidayTime;
        }
      );
    if (isHolidayTime) {
      return false;
    }
    const isTargetBetween = isWithinInterval(date, {
      start: startEnabledAppointTime,
      end: endEnabledAppointTime,
    });

    return isTargetBetween;
  };

  const today = new Date();
  const tomorrow = addDays(today, 1);
  const initialDate = searchInitialDay(tomorrow, nonWorkingDays);
  const maxDate = addDays(today, 90);

  const endEnabledAppointTime = parseStringToDate({
    dateString: props.createStoreVisitAppointmentForm.endEnabledAppointTime,
    formatString: "HH:mm",
    referenceDate: today,
  });

  const startEnabledAppointTime = parseStringToDate({
    dateString: props.createStoreVisitAppointmentForm.startEnabledAppointTime,
    formatString: "HH:mm",
    referenceDate: today,
  });

  const initialTime = parseStringToDate({
    dateString: props.createStoreVisitAppointmentForm.startEnabledAppointTime,
    formatString: "HH:mm",
    referenceDate: today,
  });

  const initialValues: StoreVisitAppointFormValues = {
    selectedDate: initialDate,
    selectedTime: initialTime,
    methodToVisitId: props.createStoreVisitAppointmentForm
      .methodToVisitsList[0] as MethodToVisitID,
    reservedCount: 1,
    phoneNumber: "",
    demand: "",
  };

  const validationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(/^0\d{9,10}$/, "正しい電話番号を入力してください")
      .required("電話番号は必須です"),
    selectedTime: yup.date().test({
      test: (val) => {
        if (!(val instanceof Date)) {
          return false;
        }
        return isEnableTime(val, selectedDate);
      },
      message: "来店可能な日時ではありません",
    }),
  });

  const [forceCloseCalendar, setForceCloseCalendar] = useState(true);
  const [forceCloseTimeCalendar, setForceCloseTimeCalendar] = useState(true);
  const [isOpenDateCalendar, setIsOpenDateCalendar] = useState(false);
  const [isOpenTimeCalendar, setIsOpenTimeCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(
    initialValues.selectedDate
  );

  const methodToVisitText = {
    [MethodToVisit.VISIT_STORE]: "店舗にご来店",
    [MethodToVisit.GENCHI_SYUGO]: "物件現地での待ち合わせ",
    [MethodToVisit.VIDEO]: "ビデオ通話での相談・内見",
    [MethodToVisit.OTHERS]: "その他（ご要望に記載ください）",
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const choices = props.createStoreVisitAppointmentForm.methodToVisitsList
    .reduce((prev: RadioFieldChoice[], methodToVisit: string) => {
      if (isMethodToVisitID(methodToVisit)) {
        return [
          ...prev,
          { id: methodToVisit, name: methodToVisitText[methodToVisit] },
        ];
      }
      return prev;
    }, [])
    .sort((prev, next) => {
      // 並び順を優先度順に変更する
      return (
        MethodToVisitPriority[prev.id as MethodToVisitID] -
        MethodToVisitPriority[next.id as MethodToVisitID]
      );
    });

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        await props.onSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {({ values, setFieldValue, isSubmitting, isValid, errors }) => {
        return (
          <Form>
            <fieldset className={styles.formRow}>
              <span className={styles.heading}>ご希望の日時</span>

              <div className={styles.dateTimeSelect}>
                <label className={styles.datePickerContainer}>
                  <DatePicker
                    readOnly
                    className={styles.datePicker}
                    dateFormat="yyyy/MM/dd"
                    filterDate={isEnableDay}
                    maxDate={maxDate}
                    minDate={initialDate}
                    open={isOpenDateCalendar}
                    popperPlacement="bottom"
                    selected={values.selectedDate}
                    onClickOutside={() => setIsOpenDateCalendar(false)}
                    onChange={(date) => {
                      if (date instanceof Date) {
                        setFieldValue("selectedDate", date);
                        setSelectedDate(date);
                      }
                      setIsOpenDateCalendar(false);
                      setForceCloseCalendar(true);
                    }}
                    onInputClick={() => {
                      // onChangeの後に発火するので`forceCloseCalendar`をみて再度開かないようにする
                      if (!forceCloseCalendar) {
                        setIsOpenDateCalendar(true);
                      }
                      setForceCloseCalendar(false);
                    }}
                  />
                  <div className={styles.formIcon}>
                    <CalendarIcon />
                  </div>
                </label>

                <label className={styles.datePickerContainer}>
                  <DatePicker
                    readOnly
                    showTimeSelect
                    showTimeSelectOnly
                    className={styles.datePicker}
                    dateFormat="HH:mm"
                    open={isOpenTimeCalendar}
                    popperPlacement="bottom"
                    selected={values.selectedTime}
                    timeCaption="時刻"
                    timeIntervals={30}
                    filterTime={(date) =>
                      isEnableTime(date, values.selectedDate)
                    }
                    onClickOutside={() => setIsOpenTimeCalendar(false)}
                    onChange={(date) => {
                      if (date instanceof Date) {
                        setFieldValue("selectedTime", date);
                      }
                      setIsOpenTimeCalendar(false);
                      setForceCloseTimeCalendar(true);
                    }}
                    onInputClick={() => {
                      // onChangeの後に発火するので`forceCloseTimeCalendar`をみて再度開かないようにする
                      if (!forceCloseTimeCalendar) {
                        setIsOpenTimeCalendar(true);
                      }
                      setForceCloseTimeCalendar(false);
                    }}
                  />
                  <div className={styles.formIcon}>
                    <CaretIcon />
                  </div>
                </label>
              </div>
            </fieldset>
            {errors.selectedTime && (
              <p className={styles.selectedTimeError}>
                {String(errors.selectedTime)}
              </p>
            )}

            <fieldset className={styles.formRow}>
              <span className={styles.heading}>ご希望の来店方法</span>
              <FormikRadioGroup choices={choices} fieldName="methodToVisitId" />

              {values.methodToVisitId === MethodToVisit.VISIT_STORE && (
                <div>
                  <p>→ 当日は以下の住所にお越しください</p>
                  <p className={styles.storeAddress}>
                    {props.createStoreVisitAppointmentForm.storeAddress}
                  </p>
                </div>
              )}
              {values.methodToVisitId === MethodToVisit.GENCHI_SYUGO && (
                <div>
                  <p>→ 物件現地や周辺での待ち合わせになります。</p>
                  <p>待合せ場所は、予約確定後にご連絡致します。</p>
                </div>
              )}
              {values.methodToVisitId === MethodToVisit.VIDEO && (
                <div>
                  <p>
                    →
                    スマホやパソコンを使い、来店せずにオンラインでご相談や内見ができます。
                  </p>
                  <p>ビデオ通話方法の詳細は、予約確定後にご連絡致します。</p>
                </div>
              )}
            </fieldset>

            <fieldset className={styles.formRow}>
              <span className={styles.heading}>ご予約人数</span>

              <div className={styles.reservedCountContainer}>
                <Field
                  as="select"
                  className={styles.reservedCount}
                  name="reservedCount"
                >
                  <option value={1}>１人</option>
                  <option value={2}>２人</option>
                  <option value={3}>３人</option>
                  <option value={4}>４人</option>
                  <option value={5}>５人以上</option>
                </Field>
                <div className={styles.formIcon}>
                  <CaretIcon />
                </div>
              </div>
            </fieldset>
            <div className={styles.formRow}>
              <span className={styles.heading}>
                <span>電話番号</span>
                <FormLabel type="require" />
              </span>
              <FormikInput
                fieldName="phoneNumber"
                placeholder="電話番号（例：09012345678）"
                type="tel"
              />
              <p className={styles.error}>
                {!isSubmitting ? errors.phoneNumber : null}
              </p>
            </div>
            <div className={styles.formRow}>
              <h2 className={styles.heading}>店舗からのお知らせ</h2>
              <pre className={styles.noticeContainer}>
                {props.createStoreVisitAppointmentForm.notice}
              </pre>
            </div>

            <fieldset className={styles.formRow}>
              <span className={styles.heading}>
                <span>店舗へのご要望</span>
                <FormLabel type="optional" />
              </span>
              <FormikTextarea
                fieldName="demand"
                placeholder="例：店舗に駐車場はありますか？"
              />
            </fieldset>

            {props.isError && (
              <div className={styles.errorContainer}>
                <p>不明なエラーが発生しました。</p>
                <p>時間をおいて再度お試しください。</p>
              </div>
            )}
            <Button
              color="primary"
              disabled={!isValid}
              isSubmitting={isSubmitting}
              type="submit"
            >
              この内容で予約する
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
