import classNames from "classnames";
import { PageLoading } from "components/loading/PageLoading";
import type { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";
import { forwardRef } from "react";

import styles from "./Button.module.scss";

type RequiredButtonPropsName = "children" | "type";
type HTMLButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
type BaseButtonProps = Omit<HTMLButtonProps, RequiredButtonPropsName> &
  Required<Pick<HTMLButtonProps, RequiredButtonPropsName>>;

type ButtonProps = BaseButtonProps & {
  children: ReactNode;
  color?: "primary" | "danger" | "vendorLine";
  isSubmitting?: boolean;
  left?: ReactNode;
  right?: ReactNode;
  variant?: "filled" | "outline" | "subtle";
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      children,
      variant = "filled",
      color = "primary",
      left,
      right,
      isSubmitting = false,
      disabled,
      className,
      ...otherProps
    } = props;

    const variantStyle = {
      filled: styles.filled,
      outline: styles.outline,
      subtle: styles.subtle,
    }[variant];

    return (
      <button
        ref={ref}
        className={classNames(styles.buttonBase, variantStyle, className)}
        data-color={color}
        disabled={isSubmitting || disabled}
        {...otherProps}
      >
        {isSubmitting ? (
          <PageLoading />
        ) : (
          <>
            {left && <span className={styles.left}>{left}</span>}
            <span className={styles.buttonLabel}>{children}</span>
            {right && <span className={styles.right}>{right}</span>}
          </>
        )}
      </button>
    );
  }
);

Button.displayName = "Button";
