import classNames from "classnames";

import loadingIcon from "../../assets/loading/Loading.svg";
import styles from "./PageLoading.module.scss";

type Props = {
  fullscreen?: boolean;
};

export const PageLoading = (props: Props) => {
  return (
    <div
      className={classNames(
        props.fullscreen ? styles.displayContainer : styles.container
      )}
    >
      <img alt="読み込み中" className={styles.image} src={loadingIcon} />
    </div>
  );
};
