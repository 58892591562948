import type { AddLineFriendPageViewResponse } from "apis/proto/canary_cloud/customer/v1/api_pb";
import { EmptyRequest } from "apis/proto/canary_cloud/customer/v1/api_pb";
import { ApiV1Client } from "apis/proto/canary_cloud/customer/v1/ApiServiceClientPb";
import { Button } from "components/button/Button";
import { Head } from "components/info/Head";
import { PageLoading } from "components/loading/PageLoading";
import { AuthContext } from "context/AuthProvider";
import { Container } from "foundations/Container";
import { ErrorPage } from "pages/Error/Error";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkErrorType, isGrpcError } from "util/error/error";
import { logError } from "util/error/logError";
import type { APIBaseResponseType } from "util/utilTypes";

import { useInterval } from "../../customHooks/useInterval";
import styles from "./AddLineFriend.module.scss";

const getAddLineFriendPageView = async (
  token: string,
  organizationID: string
): Promise<AddLineFriendPageViewResponse.AsObject> => {
  const client = new ApiV1Client(
    process.env["REACT_APP_API_BASE_URL"] as string
  );
  const req = new EmptyRequest();
  const res = await client.addLineFriendPageView(req, {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return res.toObject();
};

type AddLineFriendType = APIBaseResponseType<
  NonNullable<AddLineFriendPageViewResponse.AsObject>
>;

export const AddLineFriend = () => {
  const [addLineFriendPageViewResponse, setAddLineFriendPageViewResponse] =
    useState<AddLineFriendType>({
      state: "initial",
      data: null,
    });
  const [timeLeftSeconds, setTimeLeftSeconds] = useState<number>();
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const handleReload = () => {
    const query = new URLSearchParams(location.search);
    const source = query.get("source");
    if (source === "create-customer") {
      if (context.state === "success") {
        navigate(
          `/${context.data.organizationID}/create-customer?auth_code=${context.data.authCode}`
        );
      }
    } else {
      window.location.reload();
    }
    return;
  };

  const formatTimeLeftSeconds = (s: number) => {
    const minutes = Math.floor(s / 60);
    const seconds = s % 60;
    return `${`00${minutes}`.slice(-2)}:${`00${seconds}`.slice(-2)}`;
  };

  const refreshTimeLeftSeconds = () => {
    if (timeLeftSeconds == 0) {
      return;
    }
    if (addLineFriendPageViewResponse.state != "success") {
      return;
    }

    const expiredAt = new Date(
      addLineFriendPageViewResponse.data.expiredAt * 1000
    );
    const diff = expiredAt.getTime() - Date.now();
    if (diff <= 0) {
      setTimeLeftSeconds(0);
    } else {
      setTimeLeftSeconds(Math.floor(diff / 1000));
    }
  };

  useInterval(refreshTimeLeftSeconds, 1000);

  useEffect(() => {
    if (context.state === "success") {
      (async () => {
        try {
          setAddLineFriendPageViewResponse({
            state: "loading",
            data: null,
          });
          const res = await getAddLineFriendPageView(
            context.data.token,
            context.data.organizationID
          );

          // 認証済み or Email認証の場合はリダイレクト
          if (res.authenticated || res.authType == "email") {
            window.location.href = res.lineAddFriendUrl;
          }

          setAddLineFriendPageViewResponse({
            state: "success",
            data: res,
          });
        } catch (e) {
          logError(e);

          if (isGrpcError(e)) {
            const errorType = checkErrorType(e.code);
            setAddLineFriendPageViewResponse({
              state: "failed",
              data: {
                errorCode: errorType,
                error: new Error(JSON.stringify(e)),
              },
            });
          } else {
            setAddLineFriendPageViewResponse({
              state: "failed",
              data: {
                errorCode: "other",
                error: new Error(JSON.stringify(e)),
              },
            });
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    addLineFriendPageViewResponse.state === "loading" ||
    addLineFriendPageViewResponse.state === "initial" ||
    timeLeftSeconds == undefined
  ) {
    return <PageLoading fullscreen />;
  }

  if (addLineFriendPageViewResponse.state === "failed") {
    return <ErrorPage error={addLineFriendPageViewResponse.data.errorCode} />;
  }

  return (
    <>
      {context.state === "success" && <Head title={context.data.name} />}
      <Container>
        <div className={styles.container}>
          <div className={styles.title}>LINEの友達追加方法</div>
          <div className={styles.subtitle}>
            「LINEで友達追加」を押し、LINEのトーク画面に以下の3桁の認証コードを送信してください
          </div>
          {timeLeftSeconds <= 0 ? (
            <div className={styles.authNumberExpiredContainer}>
              <div className={styles.authNumberName}>認証コード</div>
              <div className={styles.authNumberExpired}>
                有効期限が切れました
              </div>
              <button
                className={styles.authNumberRefresh}
                onClick={handleReload}
              >
                認証コードを再生成する
              </button>
            </div>
          ) : (
            <div className={styles.authNumberContainer}>
              <div className={styles.authNumberName}>認証コード</div>
              <div className={styles.authNumber}>
                {addLineFriendPageViewResponse.data.authNumber}
              </div>
              <div className={styles.authNumberExpire}>
                残り時間 {formatTimeLeftSeconds(timeLeftSeconds)}
              </div>
            </div>
          )}
          <div className={styles.lineButtonContainer}>
            <Button
              color="vendorLine"
              disabled={timeLeftSeconds <= 0}
              type="button"
              onClick={() =>
                open(
                  addLineFriendPageViewResponse.data.lineAddFriendUrl,
                  "_blank"
                )
              }
            >
              LINEで友達追加
            </Button>
          </div>
          <div className={styles.imageContainer}>
            <img
              alt="LINE友達追加"
              className={styles.image}
              src="/images/AddLineFriendPhone.png"
            />
          </div>
          <div className={styles.attention}>
            ※
            認証コードを忘れた場合や有効期限が切れた場合は、お手数ですが最初からやり直してください
          </div>
        </div>
      </Container>
    </>
  );
};
