import { ErrorBoundary } from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { TanStackQueryProvider } from "context/TanStackQueryProvider";
import { ErrorPage } from "pages/Error/Error";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import { router } from "router/Router";
import { logError } from "util/error/logError";

export const App = () => {
  return (
    <TanStackQueryProvider>
      <HelmetProvider>
        <ErrorBoundary
          showDialog
          fallback={<ErrorPage error="unexpected-run-time-error" />}
          onError={(e) => logError(e, "fatal")}
        >
          <RouterProvider router={router} />
        </ErrorBoundary>
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </TanStackQueryProvider>
  );
};
