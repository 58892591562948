import type {
  ConditionCustomerBukkenOptionCategory,
  ConditionLayout,
} from "apis/proto/canary_cloud/customer/v1/condition_pb";
import type {
  City,
  Station,
} from "apis/proto/canary_cloud/customer/v1/mapping_pb";
import classNames from "classnames";
import { Button } from "components/button/Button";
import { FormField } from "components/form/FormField";
import { useFormContext } from "react-hook-form";

import type { ConditionFormSchema } from "../CustomerConditionForm";
import styles from "./ConditionForm.module.scss";
import {
  cityToDisplayText,
  rentToDisplayText,
  squareToDisplayText,
  stationToDisplayText,
} from "./toDisplayText";

type CheckConditionFormProps = {
  allCities: City.AsObject[];
  allStations: Station.AsObject[];
  bukkenShubetsuOptions?: ConditionCustomerBukkenOptionCategory.AsObject;
  isSubmitting: boolean;
  layoutOptions: ConditionLayout.AsObject[];
  onClickDisplayedForm: () => void;
  onClickUpdateCondition: () => Promise<void>;
  otherOptions: ConditionCustomerBukkenOptionCategory.AsObject[];
};

export const CheckConditionForm = (props: CheckConditionFormProps) => {
  const {
    onClickDisplayedForm,
    onClickUpdateCondition,
    isSubmitting,
    bukkenShubetsuOptions,
    otherOptions,
    layoutOptions,
    allStations,
    allCities,
  } = props;

  const { watch, handleSubmit } = useFormContext<ConditionFormSchema>();
  const {
    rentMax,
    rentMin,
    squareMax,
    squareMin,
    moveInTiming,
    walkDuringMax,
    oldMax,
    customerBukkenOptionIdsList,
    other,
    layoutIdsList,
    stationIdAndLineIdsList,
    cityIdsList,
    chomeiIdsList,
    phoneNumber,
  } = watch();

  const isSelectedStation = stationIdAndLineIdsList.length > 0;
  const isSelectedCity = cityIdsList.length > 0 || chomeiIdsList.length > 0;
  const selectedLayoutOptions = layoutOptions.filter((layout) =>
    layoutIdsList.includes(layout.id)
  );
  const selectedBukkenShubetsuOptions =
    bukkenShubetsuOptions?.optionsList.filter((option) =>
      customerBukkenOptionIdsList.includes(option.id)
    );
  const selectedOtherOptions = otherOptions?.map((category) => {
    const options = category.optionsList.filter((option) =>
      customerBukkenOptionIdsList.includes(option.id)
    );
    return { ...category, optionsList: options };
  });

  return (
    <div className={styles.checkFormContainer}>
      <FormField
        isRequired
        classNames={{ container: styles.formFieldContainer }}
        label="入居時期"
      >
        <p className={styles.checkValue}>{moveInTiming}</p>
      </FormField>

      <FormField
        isRequired
        classNames={{ container: styles.formFieldContainer }}
        label="賃料"
      >
        <p className={styles.checkValue}>
          {rentToDisplayText(rentMin, rentMax)}
        </p>
      </FormField>

      <FormField
        isRequired
        classNames={{ container: styles.formFieldContainer }}
        label="エリア"
      >
        <div className={styles.areaContainer}>
          {isSelectedStation && (
            <p className={classNames(styles.checkValue, styles.trancate)}>
              {stationToDisplayText(allStations, stationIdAndLineIdsList)}
            </p>
          )}
          {isSelectedCity && (
            <p className={classNames(styles.checkValue, styles.trancate)}>
              {cityToDisplayText(allCities, cityIdsList, chomeiIdsList)}
            </p>
          )}
        </div>
      </FormField>

      <FormField
        isRequired
        classNames={{ container: styles.formFieldContainer }}
        label="お部屋の面積"
      >
        <p className={styles.checkValue}>
          {squareToDisplayText(squareMin, squareMax)}
        </p>
      </FormField>

      <FormField
        classNames={{ container: styles.formFieldContainer }}
        label="間取り"
      >
        <div>
          {selectedLayoutOptions.length === 0 ? (
            <span className={styles.checkValue}>希望なし</span>
          ) : (
            selectedLayoutOptions.map((layout, index) => (
              <span key={layout.id} className={styles.checkValue}>
                {`${index === 0 ? "" : "、"}${layout.name}`}
              </span>
            ))
          )}
        </div>
      </FormField>

      <FormField
        classNames={{ container: styles.formFieldContainer }}
        label="駅徒歩"
      >
        <p className={styles.checkValue}>
          {walkDuringMax ? `${walkDuringMax}分以内` : "希望なし"}
        </p>
      </FormField>

      <FormField
        classNames={{ container: styles.formFieldContainer }}
        label="築年数"
      >
        <p className={styles.checkValue}>
          {oldMax ? `${oldMax}年以内` : "希望なし"}
        </p>
      </FormField>

      <FormField
        classNames={{ container: styles.formFieldContainer }}
        label="物件種別"
      >
        <div className={styles.categoryContainer}>
          <div className={styles.categoryList}>
            {selectedBukkenShubetsuOptions?.length === 0 ? (
              <span className={styles.checkValue}>希望なし</span>
            ) : (
              selectedBukkenShubetsuOptions?.map((option, index, array) => (
                <span key={option.id} className={styles.checkValue}>
                  {`${option.name}${array.length - 1 === index ? "" : "、"}`}
                </span>
              ))
            )}
          </div>
        </div>
      </FormField>

      <FormField
        classNames={{ container: styles.formFieldContainer }}
        label="こだわり条件"
      >
        {selectedOtherOptions?.map((category) => (
          <div key={category.id} className={styles.categoryContainer}>
            <p className={styles.categoryLabel}>{category.name}</p>
            <div className={styles.categoryList}>
              {category.optionsList.length === 0 ? (
                <span className={styles.checkValue}>希望なし</span>
              ) : (
                category.optionsList.map((option, index, array) => (
                  <span key={option.id} className={styles.checkValue}>
                    {`${option.name}${array.length - 1 === index ? "" : "、"}`}
                  </span>
                ))
              )}
            </div>
          </div>
        ))}
      </FormField>

      <FormField
        classNames={{ container: styles.formFieldContainer }}
        label="その他ご希望やこだわりがあれば教えてください"
      >
        <p className={styles.checkValue}>{other ? other : "希望なし"}</p>
      </FormField>

      <FormField
        classNames={{ container: styles.formFieldContainer }}
        label="電話番号"
      >
        <p className={styles.checkValue}>
          {phoneNumber ? phoneNumber : "未入力"}
        </p>
      </FormField>

      <div className={styles.submitButtonContainer}>
        <Button
          disabled={isSubmitting}
          type="button"
          variant="outline"
          onClick={onClickDisplayedForm}
        >
          内容を修正
        </Button>
        <Button
          isSubmitting={isSubmitting}
          type="submit"
          onClick={handleSubmit(onClickUpdateCondition)}
        >
          この内容で送信する
        </Button>
      </div>
    </div>
  );
};
