import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { checkRevision } from "revision";

/**
 * ページが切り替わるごとにリビジョンを確認し更新する
 */
export const useCheckRevision = (): void => {
  const location = useLocation();

  useEffect(() => {
    checkRevision();
  }, [location]);
};
