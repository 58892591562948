import { ReactComponent as CaretIcon } from "assets/icons/Caret.svg";
import classNames from "classnames";
import type {
  ChangeEvent,
  DetailedHTMLProps,
  OptionHTMLAttributes,
  SelectHTMLAttributes,
} from "react";
import { forwardRef } from "react";
import type { Control, FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

import styles from "./Select.module.scss";

/**
 * 賃料の下限・上限や面積の下限・上限などの選択肢の型
 * 現状では数値のみを扱う
 */
export type SelectChoice = {
  disabled?: boolean;
  label: string;
  value: number;
};

type RequiredSelectPropsName = "id" | "name";
type HTMLSelectProps = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;
type BaseSelectProps = Omit<HTMLSelectProps, RequiredSelectPropsName | "ref"> &
  Required<Pick<HTMLSelectProps, RequiredSelectPropsName>>;

type SelectComponentProps = BaseSelectProps;

const SelectComponent = forwardRef<HTMLSelectElement, SelectComponentProps>(
  (props, ref) => {
    const { className, ...otherProps } = props;

    return (
      <select
        ref={ref}
        className={classNames(styles.select, className)}
        {...otherProps}
      />
    );
  }
);

SelectComponent.displayName = "SelectComponent";

type RequiredOptionPropsName = "value";
type HTMLOptionProps = DetailedHTMLProps<
  OptionHTMLAttributes<HTMLOptionElement>,
  HTMLOptionElement
>;
type BaseOptionProps = Omit<HTMLOptionProps, RequiredOptionPropsName | "ref"> &
  Required<Pick<HTMLOptionProps, RequiredOptionPropsName>>;

type OptionComponentProps = BaseOptionProps;

const OptionComponent = forwardRef<HTMLOptionElement, OptionComponentProps>(
  (props, ref) => {
    const { className, ...otherProps } = props;

    return (
      <option
        ref={ref}
        className={classNames(styles.option, className)}
        {...otherProps}
      />
    );
  }
);

OptionComponent.displayName = "OptionComponent";

type SelectProps = BaseSelectProps & {
  choices: SelectChoice[];
  disabled?: boolean;
  id: string;
  name: string;
  readonly?: boolean;
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const {
      id,
      choices,
      readonly = false,
      disabled = false,
      ...otherProps
    } = props;

    return (
      <div className={styles.container}>
        <SelectComponent
          ref={ref}
          aria-readonly={readonly}
          className={styles.select}
          disabled={disabled || readonly}
          id={id}
          {...otherProps}
        >
          {choices.map((choice) => {
            return (
              <OptionComponent
                key={choice.value}
                disabled={choice.disabled}
                value={choice.value}
              >
                {choice.label}
              </OptionComponent>
            );
          })}
        </SelectComponent>

        <div className={styles.formIcon}>
          <CaretIcon />
        </div>
      </div>
    );
  }
);

Select.displayName = "Select";

type ControlSelectProps<
  T extends FieldValues,
  N extends FieldPath<T>,
> = BaseSelectProps & {
  choices: SelectChoice[];
  control: Control<T>;
  disabled?: boolean;
  id: string;
  name: N;
  readonly?: boolean;
};

export const ControlSelect = <T extends FieldValues, N extends FieldPath<T>>(
  props: ControlSelectProps<T, N>
) => {
  const {
    id,
    choices,
    readonly = false,
    disabled = false,
    control,
    name,
    value,
    onChange,
    ...otherProps
  } = props;
  const { field } = useController({
    name,
    control,
  });

  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    field.onChange(e);
    onChange && onChange(e);
  };

  return (
    <div className={styles.container}>
      <SelectComponent
        ref={field.ref}
        aria-readonly={readonly}
        className={styles.select}
        disabled={disabled || readonly}
        id={id}
        name={field.name}
        value={value}
        onChange={onChangeSelect}
        {...otherProps}
      >
        {choices.map((choice) => {
          return (
            <OptionComponent
              key={choice.value}
              disabled={choice.disabled}
              value={choice.value}
            >
              {choice.label}
            </OptionComponent>
          );
        })}
      </SelectComponent>

      <div className={styles.formIcon}>
        <CaretIcon />
      </div>
    </div>
  );
};
