import type {
  City,
  Station,
} from "apis/proto/canary_cloud/customer/v1/mapping_pb";

import {
  RENT_MAX_UPPER_VALUE,
  RENT_MIN_LOWER_VALUE,
  RENT_UNIT,
  SQUARE_MAX_UPPER_VALUE,
  SQUARE_MIN_LOWER_VALUE,
} from "./constant";

/*
 * 面積の範囲を表示用の文字列に変換する
 */
export const squareToDisplayText = (
  squareMin = SQUARE_MIN_LOWER_VALUE,
  squareMax = SQUARE_MAX_UPPER_VALUE
) => {
  const squareMinStr =
    squareMin === SQUARE_MIN_LOWER_VALUE ? "下限なし" : `${squareMin}㎡ `;
  const squareMaxStr =
    squareMax === SQUARE_MAX_UPPER_VALUE ? "上限なし" : ` ${squareMax}㎡`;
  const squareRangeStr =
    squareMin === SQUARE_MIN_LOWER_VALUE && squareMax === SQUARE_MAX_UPPER_VALUE
      ? "希望なし"
      : `${squareMinStr}~${squareMaxStr}`;
  return squareRangeStr;
};

/*
 * 賃料の範囲を表示用の文字列に変換する
 */
export const rentToDisplayText = (
  rentMin = RENT_MIN_LOWER_VALUE,
  rentMax = RENT_MAX_UPPER_VALUE
) => {
  const rentMinStr =
    rentMin === RENT_MIN_LOWER_VALUE
      ? "下限なし"
      : `${rentMin / RENT_UNIT}万円 `;
  const rentMaxStr =
    rentMax === RENT_MAX_UPPER_VALUE
      ? "上限なし"
      : ` ${rentMax / RENT_UNIT}万円`;
  const rentRangeStr =
    rentMin === RENT_MIN_LOWER_VALUE && rentMax === RENT_MAX_UPPER_VALUE
      ? "希望なし"
      : `${rentMinStr}~${rentMaxStr}`;
  return rentRangeStr;
};

export const stationToDisplayText = (
  allStations: Station.AsObject[],
  stationIdAndLineIdsList: string[] = []
) => {
  const stationNames = allStations.reduce<string[]>((acc, cur) => {
    if (stationIdAndLineIdsList?.includes(cur.id)) {
      return [...acc, cur.name];
    }
    return acc;
  }, []);

  return `路線・駅：${
    stationNames.length === 0 ? "希望なし" : stationNames.join("、")
  }`;
};

export const cityToDisplayText = (
  allCities: City.AsObject[],
  cityIdsList: string[] = [],
  chomeiIdsList: string[] = []
) => {
  const selectedCityNames =
    cityIdsList?.reduce<string[]>((acc, cur) => {
      const city = allCities.find((city) => city.cityId === cur);
      if (city) {
        const chomeis = city.chomeisList.filter((chomei) =>
          chomeiIdsList?.includes(chomei.chomeiId)
        );
        const chomeiNames =
          chomeis.length === 0
            ? [city.name]
            : chomeis.map((chomei) => `${city.name}${chomei.chomei}`);
        return [...acc, ...chomeiNames];
      }
      return acc;
    }, []) ?? [];

  return `市区町村：${
    selectedCityNames.length === 0 ? "希望なし" : selectedCityNames.join("、")
  }`;
};
