import { Button } from "components/button/Button";

import styles from "./CompletedView.module.scss";

type Props = {
  onClickDisplayRestartDelivery: () => void;
};

export const CompletedRejectAutoButsudashiView = ({
  onClickDisplayRestartDelivery,
}: Props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>配信を停止しています</h1>

      <p className={styles.message}>
        メール配信を停止しています。
        <br />
        再開したい場合は以下のボタンから配信を再開できます。
      </p>

      <Button
        className={styles.button}
        type="button"
        onClick={onClickDisplayRestartDelivery}
      >
        配信を再開する
      </Button>
    </div>
  );
};
