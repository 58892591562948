// https://react-query.tanstack.com/guides/query-keys#query-keys-are-hashed-deterministically
// eslint-disable-next-line import/no-anonymous-default-export
export const queryKeyGenerator = (...resource: string[]) => {
  return {
    generateListKey(params?: unknown) {
      if (params) {
        return [...resource, "list", params];
      }
      return [...resource, "list"];
    },
    generateGetKey(id?: string | Record<string, string>, params?: unknown) {
      if (params) {
        return [...resource, "get", id, params];
      }
      return [...resource, "get", id];
    },
    generateMutationKey(id?: string, params?: string | Record<string, string>) {
      if (params) {
        return [...resource, "mutation", id, params];
      }
      return [...resource, "mutation", id];
    },
  };
};
