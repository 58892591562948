import { useContext } from "react";

import { AuthContext } from "../context/AuthProvider";
import styles from "./Header.module.scss";

export const Header = () => {
  const context = useContext(AuthContext);

  if (context.state !== "success") {
    return <header className={styles.header} />;
  }

  return (
    <header className={styles.header}>
      <h1 className={styles.title}>{context.data.name}</h1>
    </header>
  );
};
