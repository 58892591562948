import { useLocation } from "react-router-dom";

type CustomerBukkenID = string;

export const CUSTOMER_BUKKEN_ID_QUERY_PARAM_KEY = "customer_bukken_id";

export const useCustomerBukkenID = (): CustomerBukkenID | null => {
  const search = useLocation().search;

  const searchParams = new URLSearchParams(search);

  return searchParams.get(CUSTOMER_BUKKEN_ID_QUERY_PARAM_KEY);
};
