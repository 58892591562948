import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQueries, useSuspenseQuery } from "@tanstack/react-query";
import type { SearchStationsResponse as APISearchStationsResponse } from "apis/proto/canary_cloud/customer/v1/api_pb";
import { SearchStationsRequest as APISearchStationsRequest } from "apis/proto/canary_cloud/customer/v1/api_pb";
import { ApiV1Client } from "apis/proto/canary_cloud/customer/v1/ApiServiceClientPb";
import { AuthContext, checkSuccessAuthContext } from "context/AuthProvider";
import { useContext } from "react";
import { config } from "util/constants/config";
import { queryKeyGenerator } from "util/queryKeyGenerator";

export const RESOURCE_NAME = "station";
const station = queryKeyGenerator(RESOURCE_NAME);

export type SearchStationsResponse = {
  routesList: {
    id: string;
    linesList: {
      id: string;
      name: string;
      stationIdsInThisLineList: string[];
      stationsList: {
        id: string;
        name: string;
      }[];
    }[];
    name: string;
    stationIdsInThisRouteList: string[];
    stationsListInThisRoute: {
      id: string;
      name: string;
    }[];
  }[];
  stationsListInThisPrefecture: {
    id: string;
    name: string;
  }[];
};

const convertStation = (
  searchStation: APISearchStationsResponse.AsObject
): SearchStationsResponse => {
  const routesList = searchStation.routesList.map((route) => {
    const linesList = route.linesList.map((line) => {
      const stationIdsInThisLineList = line.stationsList.map(
        (stationId) => stationId.id
      );
      return {
        id: line.id,
        name: line.name,
        stationIdsInThisLineList,
        stationsList: line.stationsList,
      };
    });
    const stationIdsInThisRouteList = linesList.flatMap(
      (line) => line.stationIdsInThisLineList
    );
    const stationsListInThisRoute = linesList
      .map((line) => line.stationsList)
      .flat();
    return {
      id: route.id,
      name: route.name,
      stationIdsInThisRouteList,
      linesList,
      stationsListInThisRoute,
    };
  });
  const stationsListInThisPrefecture = routesList
    .flatMap((route) => route.stationsListInThisRoute)
    .flat();
  return {
    routesList,
    stationsListInThisPrefecture,
  };
};

const searchStations = async (
  token: string,
  organizationID: string,
  variables: APISearchStationsRequest.AsObject
): Promise<SearchStationsResponse> => {
  const client = new ApiV1Client(config.API_BASE_URL);
  const req = new APISearchStationsRequest();
  req.setPrefectureId(variables.prefectureId);
  const res = await client.searchStations(req, {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return convertStation(res.toObject());
};

export const useSearchStations = (
  variables: APISearchStationsRequest.AsObject,
  options?: UseSuspenseQueryOptions<
    SearchStationsResponse,
    unknown,
    SearchStationsResponse,
    ReturnType<typeof station.generateGetKey>
  >
) => {
  const context = useContext(AuthContext);
  const enabled = checkSuccessAuthContext(context);

  if (!enabled) {
    throw Error("not success auth context");
  }

  return useSuspenseQuery({
    queryKey: station.generateListKey(variables.prefectureId),
    queryFn: async () => {
      return await searchStations(
        context.data.token,
        context.data.organizationID,
        variables
      );
    },
    staleTime: 1000 * 60 * 60 * 24, // キャッシュの有効期限
    ...options,
  });
};

export const useBulkSearchStations = (
  variables: APISearchStationsRequest.AsObject[],
  options?: UseSuspenseQueryOptions<
    SearchStationsResponse,
    unknown,
    SearchStationsResponse,
    ReturnType<typeof station.generateGetKey>
  >
) => {
  const context = useContext(AuthContext);
  const enabled = checkSuccessAuthContext(context);

  if (!enabled) {
    throw Error("not success auth context");
  }

  return useSuspenseQueries({
    queries: variables.map((variable) => {
      return {
        queryKey: station.generateListKey(variable.prefectureId),
        queryFn: async () => {
          return await searchStations(
            context.data.token,
            context.data.organizationID,
            variable
          );
        },
        staleTime: 1000 * 60 * 60 * 24, // キャッシュの有効期限
        ...options,
      };
    }),
  });
};
