// source: canary_cloud/customer/v1/condition.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var canary_cloud_customer_v1_lib_pb = require('../../../canary_cloud/customer/v1/lib_pb.js');
goog.object.extend(proto, canary_cloud_customer_v1_lib_pb);
var canary_cloud_customer_v1_mapping_pb = require('../../../canary_cloud/customer/v1/mapping_pb.js');
goog.object.extend(proto, canary_cloud_customer_v1_mapping_pb);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.Condition', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption', null, global);
goog.exportSymbol('proto.apis.proto.canary_cloud.customer.v1.ConditionLayout', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.Condition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.Condition.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.Condition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.Condition.displayName = 'proto.apis.proto.canary_cloud.customer.v1.Condition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionLayout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.ConditionLayout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.displayName = 'proto.apis.proto.canary_cloud.customer.v1.ConditionLayout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.repeatedFields_, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.displayName = 'proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.displayName = 'proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.repeatedFields_ = [3,8,9,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.Condition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Condition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    moveInTiming: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stationAndLinesList: jspb.Message.toObjectList(msg.getStationAndLinesList(),
    canary_cloud_customer_v1_mapping_pb.StationAndLine.toObject, includeInstance),
    rentMax: (f = msg.getRentMax()) && canary_cloud_customer_v1_lib_pb.NullInt64.toObject(includeInstance, f),
    rentMin: (f = msg.getRentMin()) && canary_cloud_customer_v1_lib_pb.NullInt64.toObject(includeInstance, f),
    squareMax: (f = msg.getSquareMax()) && canary_cloud_customer_v1_lib_pb.NullDouble.toObject(includeInstance, f),
    squareMin: (f = msg.getSquareMin()) && canary_cloud_customer_v1_lib_pb.NullDouble.toObject(includeInstance, f),
    layoutsList: jspb.Message.toObjectList(msg.getLayoutsList(),
    proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.toObject, includeInstance),
    citiesList: jspb.Message.toObjectList(msg.getCitiesList(),
    canary_cloud_customer_v1_mapping_pb.City.toObject, includeInstance),
    walkDuringMax: (f = msg.getWalkDuringMax()) && canary_cloud_customer_v1_lib_pb.NullInt64.toObject(includeInstance, f),
    oldMax: (f = msg.getOldMax()) && canary_cloud_customer_v1_lib_pb.NullInt64.toObject(includeInstance, f),
    other: jspb.Message.getFieldWithDefault(msg, 12, ""),
    customerBukkenOptionCategoriesList: jspb.Message.toObjectList(msg.getCustomerBukkenOptionCategoriesList(),
    proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.Condition;
  return proto.apis.proto.canary_cloud.customer.v1.Condition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMoveInTiming(value);
      break;
    case 3:
      var value = new canary_cloud_customer_v1_mapping_pb.StationAndLine;
      reader.readMessage(value,canary_cloud_customer_v1_mapping_pb.StationAndLine.deserializeBinaryFromReader);
      msg.addStationAndLines(value);
      break;
    case 4:
      var value = new canary_cloud_customer_v1_lib_pb.NullInt64;
      reader.readMessage(value,canary_cloud_customer_v1_lib_pb.NullInt64.deserializeBinaryFromReader);
      msg.setRentMax(value);
      break;
    case 5:
      var value = new canary_cloud_customer_v1_lib_pb.NullInt64;
      reader.readMessage(value,canary_cloud_customer_v1_lib_pb.NullInt64.deserializeBinaryFromReader);
      msg.setRentMin(value);
      break;
    case 6:
      var value = new canary_cloud_customer_v1_lib_pb.NullDouble;
      reader.readMessage(value,canary_cloud_customer_v1_lib_pb.NullDouble.deserializeBinaryFromReader);
      msg.setSquareMax(value);
      break;
    case 7:
      var value = new canary_cloud_customer_v1_lib_pb.NullDouble;
      reader.readMessage(value,canary_cloud_customer_v1_lib_pb.NullDouble.deserializeBinaryFromReader);
      msg.setSquareMin(value);
      break;
    case 8:
      var value = new proto.apis.proto.canary_cloud.customer.v1.ConditionLayout;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.deserializeBinaryFromReader);
      msg.addLayouts(value);
      break;
    case 9:
      var value = new canary_cloud_customer_v1_mapping_pb.City;
      reader.readMessage(value,canary_cloud_customer_v1_mapping_pb.City.deserializeBinaryFromReader);
      msg.addCities(value);
      break;
    case 10:
      var value = new canary_cloud_customer_v1_lib_pb.NullInt64;
      reader.readMessage(value,canary_cloud_customer_v1_lib_pb.NullInt64.deserializeBinaryFromReader);
      msg.setWalkDuringMax(value);
      break;
    case 11:
      var value = new canary_cloud_customer_v1_lib_pb.NullInt64;
      reader.readMessage(value,canary_cloud_customer_v1_lib_pb.NullInt64.deserializeBinaryFromReader);
      msg.setOldMax(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOther(value);
      break;
    case 13:
      var value = new proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.deserializeBinaryFromReader);
      msg.addCustomerBukkenOptionCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.Condition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMoveInTiming();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStationAndLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      canary_cloud_customer_v1_mapping_pb.StationAndLine.serializeBinaryToWriter
    );
  }
  f = message.getRentMax();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      canary_cloud_customer_v1_lib_pb.NullInt64.serializeBinaryToWriter
    );
  }
  f = message.getRentMin();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      canary_cloud_customer_v1_lib_pb.NullInt64.serializeBinaryToWriter
    );
  }
  f = message.getSquareMax();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      canary_cloud_customer_v1_lib_pb.NullDouble.serializeBinaryToWriter
    );
  }
  f = message.getSquareMin();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      canary_cloud_customer_v1_lib_pb.NullDouble.serializeBinaryToWriter
    );
  }
  f = message.getLayoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.serializeBinaryToWriter
    );
  }
  f = message.getCitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      canary_cloud_customer_v1_mapping_pb.City.serializeBinaryToWriter
    );
  }
  f = message.getWalkDuringMax();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      canary_cloud_customer_v1_lib_pb.NullInt64.serializeBinaryToWriter
    );
  }
  f = message.getOldMax();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      canary_cloud_customer_v1_lib_pb.NullInt64.serializeBinaryToWriter
    );
  }
  f = message.getOther();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCustomerBukkenOptionCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string move_in_timing = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getMoveInTiming = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setMoveInTiming = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated StationAndLine station_and_lines = 3;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.StationAndLine>}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getStationAndLinesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.StationAndLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, canary_cloud_customer_v1_mapping_pb.StationAndLine, 3));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.StationAndLine>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setStationAndLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.StationAndLine}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.addStationAndLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apis.proto.canary_cloud.customer.v1.StationAndLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.clearStationAndLinesList = function() {
  return this.setStationAndLinesList([]);
};


/**
 * optional NullInt64 rent_max = 4;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.NullInt64}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getRentMax = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.NullInt64} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_lib_pb.NullInt64, 4));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.NullInt64|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setRentMax = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.clearRentMax = function() {
  return this.setRentMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.hasRentMax = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullInt64 rent_min = 5;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.NullInt64}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getRentMin = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.NullInt64} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_lib_pb.NullInt64, 5));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.NullInt64|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setRentMin = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.clearRentMin = function() {
  return this.setRentMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.hasRentMin = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullDouble square_max = 6;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.NullDouble}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getSquareMax = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.NullDouble} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_lib_pb.NullDouble, 6));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.NullDouble|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setSquareMax = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.clearSquareMax = function() {
  return this.setSquareMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.hasSquareMax = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullDouble square_min = 7;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.NullDouble}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getSquareMin = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.NullDouble} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_lib_pb.NullDouble, 7));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.NullDouble|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setSquareMin = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.clearSquareMin = function() {
  return this.setSquareMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.hasSquareMin = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated ConditionLayout layouts = 8;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout>}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getLayoutsList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.ConditionLayout, 8));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setLayoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.addLayouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.apis.proto.canary_cloud.customer.v1.ConditionLayout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.clearLayoutsList = function() {
  return this.setLayoutsList([]);
};


/**
 * repeated City cities = 9;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.City>}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getCitiesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.City>} */ (
    jspb.Message.getRepeatedWrapperField(this, canary_cloud_customer_v1_mapping_pb.City, 9));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.City>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setCitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.City=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.City}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.addCities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.apis.proto.canary_cloud.customer.v1.City, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.clearCitiesList = function() {
  return this.setCitiesList([]);
};


/**
 * optional NullInt64 walk_during_max = 10;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.NullInt64}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getWalkDuringMax = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.NullInt64} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_lib_pb.NullInt64, 10));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.NullInt64|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setWalkDuringMax = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.clearWalkDuringMax = function() {
  return this.setWalkDuringMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.hasWalkDuringMax = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullInt64 old_max = 11;
 * @return {?proto.apis.proto.canary_cloud.customer.v1.NullInt64}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getOldMax = function() {
  return /** @type{?proto.apis.proto.canary_cloud.customer.v1.NullInt64} */ (
    jspb.Message.getWrapperField(this, canary_cloud_customer_v1_lib_pb.NullInt64, 11));
};


/**
 * @param {?proto.apis.proto.canary_cloud.customer.v1.NullInt64|undefined} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setOldMax = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.clearOldMax = function() {
  return this.setOldMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.hasOldMax = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string other = 12;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setOther = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated ConditionCustomerBukkenOptionCategory customer_bukken_option_categories = 13;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory>}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.getCustomerBukkenOptionCategoriesList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory, 13));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.setCustomerBukkenOptionCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory}
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.addCustomerBukkenOptionCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.Condition} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.Condition.prototype.clearCustomerBukkenOptionCategoriesList = function() {
  return this.setCustomerBukkenOptionCategoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.ConditionLayout;
  return proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionLayout} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionLayout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory;
  return proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption;
      reader.readMessage(value,proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.prototype.toObject = function(opt_includeInstance) {
  return proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption;
  return proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool selected = 3;
 * @return {boolean}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ConditionCustomerBukkenOption options = 3;
 * @return {!Array<!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption>}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption, 3));
};


/**
 * @param {!Array<!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption>} value
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory} returns this
*/
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption}
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.ConditionCustomerBukkenOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory} returns this
 */
proto.apis.proto.canary_cloud.customer.v1.ConditionCustomerBukkenOptionCategory.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


goog.object.extend(exports, proto.apis.proto.canary_cloud.customer.v1);
