import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQueries, useSuspenseQuery } from "@tanstack/react-query";
import type { SearchCitiesResponse as APISearchCitiesResponse } from "apis/proto/canary_cloud/customer/v1/api_pb";
import { SearchCitiesRequest as APISearchCitiesRequest } from "apis/proto/canary_cloud/customer/v1/api_pb";
import { ApiV1Client } from "apis/proto/canary_cloud/customer/v1/ApiServiceClientPb";
import { AuthContext, checkSuccessAuthContext } from "context/AuthProvider";
import { useContext } from "react";
import { config } from "util/constants/config";
import { queryKeyGenerator } from "util/queryKeyGenerator";

export const RESOURCE_NAME = "city";
const city = queryKeyGenerator(RESOURCE_NAME);

export type SearchCitiesResponse = {
  areasList: {
    chomeiIdsListInThisArea: string[];
    chomeisListInThisArea: {
      chomei: string;
      chomeiId: string;
      cityId: string;
    }[];
    citiesList: {
      chomeiIdsListInThisCity: string[];
      chomeisList: {
        chomei: string;
        chomeiId: string;
        cityId: string;
      }[];
      cityId: string;
      name: string;
    }[];
    cityIdsListInThisArea: string[];
    id: string;
    name: string;
  }[];
  chomeisListInThisPrefecture: {
    chomei: string;
    chomeiId: string;
    cityId: string;
  }[];
  citiesListInThisPrefecture: {
    chomeisList: {
      chomei: string;
      chomeiId: string;
      cityId: string;
    }[];
    cityId: string;
    name: string;
  }[];
};

const convertCities = (
  searchCity: APISearchCitiesResponse.AsObject
): SearchCitiesResponse => {
  const areasList = searchCity.areasList.map((area) => {
    const citiesList = area.citiesList.map((city) => {
      const chomeisList = city.chomeisList.map((chomei) => {
        return {
          chomei: chomei.chomei,
          chomeiId: chomei.chomeiId,
          cityId: chomei.cityId,
        };
      });
      const chomeiIdsListInThisCity = city.chomeisList.map(
        (chomei) => chomei.chomeiId
      );
      return {
        chomeisList,
        cityId: city.cityId,
        name: city.name,
        chomeiIdsListInThisCity,
      };
    });
    const cityIdsListInThisArea = citiesList.map((city) => city.cityId);
    const chomeiIdsListInThisArea = citiesList.flatMap(
      (city) => city.chomeiIdsListInThisCity
    );
    const chomeisListInThisArea = citiesList.flatMap((city) =>
      city.chomeisList.map((chomei) => chomei)
    );
    return {
      citiesList,
      id: area.id,
      name: area.name,
      cityIdsListInThisArea,
      chomeisListInThisArea,
      chomeiIdsListInThisArea,
    };
  });
  const citiesListInThisPrefecture = areasList.flatMap((area) =>
    area.citiesList.map((city) => city)
  );
  const chomeisListInThisPrefecture = areasList.flatMap((area) =>
    area.chomeisListInThisArea.map((chomei) => chomei)
  );
  return {
    areasList,
    citiesListInThisPrefecture,
    chomeisListInThisPrefecture,
  };
};

const searchCities = async (
  token: string,
  organizationID: string,
  variables: APISearchCitiesRequest.AsObject
): Promise<SearchCitiesResponse> => {
  const client = new ApiV1Client(config.API_BASE_URL);
  const req = new APISearchCitiesRequest();
  req.setPrefectureId(variables.prefectureId);
  const res = await client.searchCities(req, {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return convertCities(res.toObject());
};

export const useSearchCities = (
  variables: APISearchCitiesRequest.AsObject,
  options?: UseSuspenseQueryOptions<
    SearchCitiesResponse,
    unknown,
    SearchCitiesResponse,
    ReturnType<typeof city.generateGetKey>
  >
) => {
  const context = useContext(AuthContext);
  const enabled = checkSuccessAuthContext(context);

  if (!enabled) {
    throw Error("not success auth context");
  }

  return useSuspenseQuery({
    queryKey: city.generateListKey(variables.prefectureId),
    queryFn: async () => {
      return await searchCities(
        context.data.token,
        context.data.organizationID,
        variables
      );
    },
    staleTime: 1000 * 60 * 60 * 24, // キャッシュの有効期限
    ...options,
  });
};

export const useBulkSearchCities = (
  variables: APISearchCitiesRequest.AsObject[],
  options?: UseSuspenseQueryOptions<
    SearchCitiesResponse,
    unknown,
    SearchCitiesResponse,
    ReturnType<typeof city.generateGetKey>
  >
) => {
  const context = useContext(AuthContext);
  const enabled = checkSuccessAuthContext(context);

  if (!enabled) {
    throw Error("not success auth context");
  }

  return useSuspenseQueries({
    queries: variables.map((variable) => {
      return {
        queryKey: city.generateListKey(variable.prefectureId),
        queryFn: async () => {
          return await searchCities(
            context.data.token,
            context.data.organizationID,
            variable
          );
        },
        staleTime: 1000 * 60 * 60 * 24, // キャッシュの有効期限
        ...options,
      };
    }),
  });
};
