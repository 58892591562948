import classNames from "classnames";
import type { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { forwardRef, useRef, useState } from "react";

import styles from "./Chip.module.scss";

type RequiredInputPropsName = "id" | "name" | "value";
type HTMLInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
type BaseChipProps = Omit<
  HTMLInputProps,
  RequiredInputPropsName | "ref" | "type"
> &
  Required<Pick<HTMLInputProps, RequiredInputPropsName>>;

type ChipProps = BaseChipProps & {
  label: string;
};

export const Chip = forwardRef<HTMLInputElement, ChipProps>((props, ref) => {
  const {
    id,
    label,
    disabled = false,
    readOnly = false,
    checked,
    ...otherProps
  } = props;

  const labelRef = useRef<HTMLLabelElement>(null);
  const [isChecked, setIsCheckedChecked] = useState(checked);

  const onKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault(); // Spaceキーを押した際にスクロールするのを防ぐ
      if (disabled || readOnly) {
        return;
      }
      labelRef.current?.click();
    }
  };

  const onClickCheckbox = () => {
    setIsCheckedChecked((prev) => !prev);
  };

  return (
    <label
      ref={labelRef}
      aria-checked={isChecked}
      aria-disabled={disabled}
      aria-readonly={readOnly}
      className={classNames([styles.chip])}
      htmlFor={id}
      tabIndex={disabled ? -1 : 0}
      onKeyDown={onKeyDown}
    >
      <span>{label}</span>
      <input
        ref={ref}
        aria-readonly={readOnly}
        disabled={disabled || readOnly}
        id={id}
        style={{ display: "none" }}
        type="checkbox"
        onClick={onClickCheckbox}
        {...otherProps}
      />
    </label>
  );
});

Chip.displayName = "Chip";
