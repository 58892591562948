import { Field } from "formik";

import styles from "./Radio.module.scss";

export type RadioFieldChoice = {
  id: string;
  name: string;
};

type FormikProps = {
  choices: RadioFieldChoice[];
  fieldName: string;
};

/**
 * @deprecated
 * src/components/Radio.tsx > RadioGroup を使用してください。
 */
export const FormikRadioGroup = (props: FormikProps) => {
  return (
    <div className={styles.radioGroup}>
      {props.choices.map((choice) => {
        return (
          <label key={choice.id} className={styles.radioRow}>
            <Field
              className={styles.radio}
              name={props.fieldName}
              type="radio"
              value={choice.id}
            />
            <div className={styles.radioMark} />
            {choice.name}
          </label>
        );
      })}
    </div>
  );
};
