import { ReactComponent as Close } from "assets/icons/Close.svg";
import classNames from "classnames";
import type { ReactNode } from "react";
import { useEffect } from "react";
import { createPortal } from "react-dom";

import styles from "./Modal.module.scss";

type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  size?: "sm" | "md" | "lg" | "xl";
};

const ModalView = (props: ModalProps) => {
  const { isOpen, onClose, children, size = "md" } = props;

  const modalSize = {
    sm: styles.innerSizeSM,
    md: styles.innerSizeMD,
    lg: styles.innerSizeLG,
    xl: styles.innerSizeXL,
  }[size];

  if (!isOpen) {
    return null;
  }

  return (
    <div className={classNames(styles.container, !isOpen && styles.hidden)}>
      <div className={styles.overlay} onClick={onClose} />
      <section className={classNames(styles.containerInner, modalSize)}>
        {children}
      </section>
    </div>
  );
};

type ModalHeaderProps = {
  children: ReactNode;
  onClose?: () => void;
};

const ModalHeader = (props: ModalHeaderProps) => {
  const { children, onClose } = props;

  return (
    <div className={styles.modalHeader}>
      <h2 className={styles.title}>{children}</h2>
      {onClose && (
        <button className={styles.closeButton} type="button" onClick={onClose}>
          <Close />
        </button>
      )}
    </div>
  );
};

type ModalFooterProps = {
  children: ReactNode;
};

const ModalFooter = (props: ModalFooterProps) => {
  const { children } = props;
  return <div className={styles.modalFooter}>{children}</div>;
};

type ModalBodyProps = {
  children: ReactNode;
};

const ModalBody = (props: ModalBodyProps) => {
  const { children } = props;
  return <div className={styles.modalBody}>{children}</div>;
};

type ModalPortalProps = {
  children: ReactNode;
};

const ModalPortal = ({ children }: ModalPortalProps) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return createPortal(children, document.body);
};

export const Modal = (props: ModalProps) => {
  const { children, ...otherProps } = props;

  if (!props.isOpen) {
    return null;
  }

  return (
    <ModalPortal>
      <ModalView {...otherProps}>{children}</ModalView>
    </ModalPortal>
  );
};

Modal.Potal = ModalPortal;
Modal.View = ModalView;
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
